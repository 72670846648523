//Main Menu CSS
.site__sidebar {
  background: $white;
  box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.28);
  // position: relative;
  // justify-self: flex-start;

  &.collapse {
    flex: 0 0 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
    width: 40px !important;
  }

  .anticon-left,
  .anticon-right {
    position: relative;
    right: 0;
    z-index: 999;
    float: right;
    margin: 0 -1.8rem 0 0;
    background-color: $white;
    box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.28);
    border-radius: 0 20px 20px 0;
    width: 1.8rem;
    padding: 0.5rem 0.5rem 0.5rem 0;
    color: $primary_color;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .ant-btn {
    padding: 0 1rem !important;
    margin: 0 !important;
    appearance: none;
    width: 100%;
    height: 43px;
    line-height: 43px;
    text-align: left;
    font-size: 1.2rem !important;
    font-weight: 600;
    font-family: 'VerdanaBold', Verdana, sans-serif;
    border-radius: 0;
    border: 0;
    box-shadow: 0;
    background-color: transparent;
    border-bottom: 1px solid $primary_Light;
    color: $primaryTextDark;
    display: flex;
    align-items: center;
    position: relative;
    background-color: $primarySeleteBG;

    .anticon.anticon-down {
      justify-self: right;
      color: $primary_Dark;
      font-size: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0.8rem;
    }

    img {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 0.6rem;
      display: inline-block;
      position: relative;
      top: -0.3rem;
    }

    &:nth-child(2) {
      font-size: 1.6rem !important;
      font-weight: 600;
      font-family: 'VerdanaBold', Verdana, sans-serif;
      background-color: $primary_Light;
      color: $primaryTextDark;
      height: 45px;
      line-height: 45px;

      a {
        color: $primaryTextDark;
      }

      .anticon.anticon-down {
        justify-self: right;
        background-color: $primary_Dark;
        color: $white;
        font-size: 1.2rem;
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        padding: 0.3rem;
      }

      img {
        width: 2.4rem;
        height: 2.4rem;
        top: 0;
      }
    }

    &:hover,
    &:focus {
      background-color: $primary_Light;
      color: $primaryTextDark;
    }

    @media screen and (max-width: 1400px) {
      height: 30px;
      line-height: 30px;
      margin: 0 !important;
      padding: 0 0.8rem !important;
      font-size: 1.2rem;

      &:first-child {
        font-size: 1.6rem;
        height: 3.5rem;
        line-height: 3.5rem;
      }
    }
  }

  ul.ant-menu {
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 172px) !important;

    li.ant-menu-item {
      background-color: transparent;
      border-bottom: 1px solid $primary_Light;
      height: 50px;
      line-height: 50px;
      color: $primaryTextLight;
      font-size: 1.2rem;
      width: 100%;
      padding: 0 1rem !important;
      margin: auto;
      align-items: center;
      display: flex;

      .ant-menu-title-content {
        align-items: center;
        display: flex;
      }

      span.ant-menu-title-content {
        span {
          opacity: 1;
          transition: all 0.5s ease-in-out;
        }
      }

      img {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.6rem;
        display: inline-block;
        position: relative;
        top: -0.3rem;
      }

      &.bottom_menu {
        // display: flex;
        // align-items: center;
        height: 5rem;
        line-height: 5rem;
        padding: 5px 0 0 !important;

        a {
          display: block;
          float: left;
          background-color: $primary_Light;
          width: 33.33333%;
          margin: 0 0 0 0;
          text-align: center;
          border-right: 3px solid #fff;

          img {
            width: 2.2rem;
            height: 2.2rem;
          }

          &:last-child {
            border-right: none;
          }

          &:hover,
          &:focus,
          &:active {
            background-color: rgba($primary_color, 0.5);
          }

          &::before {
            display: none;
          }
        }

        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }
      }

      a {
        color: $primaryTextLight;
      }

      &.ant-menu-item-selected,
      &:hover,
      &:focus {
        background-color: $primary_Light;
        color: $primaryTextDark;
      }

      .ant-switch {
        // float: right;
        margin: auto 0.5rem;
        padding: 1.1rem !important;
        width: 36px;
        min-width: 36px;
        height: 14px;
        line-height: 14px;
        background-color: $primaryTextDark;

        &.ant-switch-checked {
          background-color: $primary_Dark;
        }

        .ant-switch-handle {
          width: 15px;
          height: 15px;
          top: 3px;

          &:before {
            box-shadow: none;
          }
        }
      }

      .count {
        font-size: 1.2rem;
        font-weight: 600;
        font-family: 'VerdanaBold', Verdana, sans-serif;
        color: $primaryTextDark;
        float: right;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        position: absolute;
        right: 10px;
        z-index: 999;

        img {
          margin: 5px 10px 0 0;
        }

        .anticon {
          margin: auto 0 auto 0.5rem;
          color: $primaryTextDark;
          align-self: center;
          display: inline-flex;
        }
      }
    }

    &.ant-menu-inline-collapsed {
      li.ant-menu-item {
        span.ant-menu-title-content {
          span {
            opacity: 0;
            transition: all 0.5s ease-in-out;
          }
        }
        span.count{
          display: none;
        }
      }
    }

    @media screen and (max-width: 1400px) {
      height: calc(100vh - 146px) !important;

      li {
        height: 30px;
        line-height: 30px;
        margin: 0 !important;
        padding: 0 0.8rem !important;
        font-size: 1.2rem;
      }
    }
  }

  &.collapse {
    flex: 0 0 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
    width: 40px !important;

    ul.ant-menu {
      li.ant-menu-item {
        .count {
          display: none;
        }

        &.bottom_menu {
          padding: 5px 10px !important;

          span.anticon.anticon-info-circle {
            display: block;
          }
        }
      }
    }
  }
}

.bottom-menu-popover {
  .ant-popover-inner-content {
    padding: 0;
    background-color: $Offwhite;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid $primary_Light;
    border-radius: 10px;

    .popover-menu {
      list-style: none;
      margin: 0;
      padding: 0.5rem;
      min-width: auto;
      display: flex;

      li {
        list-style: none;
        margin: 0;
        padding: 0.5rem 1.5rem;
        background-color: transparent;
        border-bottom: 1px solid $primary_Light;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 3rem;

        img.menuicon {
          width: 1.8rem;
          height: 1.8rem;
          margin-right: 0.6rem;
          display: inline-block;
          position: relative;
          top: -0.3rem;
        }

        a {
          color: $primaryTextLight;
          display: block;
        }

        &:hover,
        &:focus {
          background-color: $primaryComment;
          color: $primaryTextDark;

          a {
            color: $primaryTextDark;
          }
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}

.main-menu-popover {
  border-radius: 10px;
  .ant-popover-inner {
    border-radius: 10px;
  }
  .ant-popover-inner-content {
    padding: 0;
    background-color: $Offwhite;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid $primary_Light;
    border-radius: 10px;

    .popover-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      min-width: 220px;

      &.reportList {
        &.favListing {
          max-height: 200px;
          overflow: hidden;
          overflow-y: auto;
        }

        &.favStaticLI {
          cursor: default;
          border-top: 1px solid var(--lite) !important;
          border-bottom: 1px solid var(--lite) !important;
        }
      }

      li {
        list-style: none;
        margin: 0;
        padding: 0.2rem 0.8rem;
        background-color: transparent;
        border-bottom: 1px solid $primary_Light;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 3rem;

        img.menuicon {
          width: 1.8rem;
          height: 1.8rem;
          margin-right: 0.6rem;
          display: inline-block;
          position: relative;
          top: -0.3rem;
        }

        a {
          color: $primaryTextLight;
          display: block;
        }

        &:hover,
        &:focus {
          background-color: $primaryComment;
          color: $primaryTextDark;

          a {
            color: $primaryTextDark;
          }
        }

        &:last-child {
          border: none;
        }
      }

      &.reportList li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
      }
    }
  }
}

.inner-menu-popover {
  .ant-popover-inner-content {
    padding: 0;
    background-color: $Offwhite;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid $primary_Light;

    .popover-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      min-width: 80px;

      li {
        list-style: none;
        margin: 0;
        padding: 0.2rem 0.8rem;
        background-color: transparent;
        border-bottom: 1px solid $primary_Light;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 3rem;

        img.menuicon {
          width: 1.8rem;
          height: 1.8rem;
          margin-right: 0.6rem;
          display: inline-block;
          position: relative;
          top: -0.3rem;
        }

        a {
          color: $primaryTextLight;
          display: block;
        }

        &:hover,
        leadStatusOptins &:focus {
          background-color: $primary_Light;
          color: $primary_Dark;

          a {
            color: $primaryTextDark;
          }
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}

.profileMenu,
.timeMenu,
.lockContentMenu {
  border-radius: 10px;

  .ant-popover-inner,
  &.ant-dropdown {
    border-radius: 10px;

    .ant-popover-inner-content {
      padding: 0;
      background-color: $Offwhite;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
      border: 1px solid $primary_Light;
      border-radius: 10px;
      max-height: 250px;
      height: auto;
      overflow-y: auto;

      .popover-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        min-width: 40px;
        width: 180px;

        li {
          list-style: none;
          margin: 0;
          padding: 0.2rem 0.8rem;
          background-color: transparent;
          border-bottom: 1px solid $primary_Light;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 2.5rem;
          cursor: pointer;

          a {
            color: $primaryTextDark;
            cursor: pointer;
          }

          &:hover,
          &:focus,
          &.active {
            background-color: $primary_Dark;
            color: $white;

            a {
              color: $white;
            }
          }

          &:last-child {
            border: none;
          }
          &.sequenceListGroup {
            background-color: #fff;
            padding: 0;
            margin: 0;
            color: $primaryTextDark;
            strong {
              font-weight: 800;
              background-color: $primary_Light;
              color: $primaryTextDark;
              display: block;
              padding: 0.2rem 1rem;
            }
            &:hover,
            &:focus,
            &.active {
              background-color: #fff;
              color: $primary_Dark;
            }
            ul {
              margin: 0;
              padding: 0;
              list-style: none;
              li {
                padding: 0.2rem 0.8rem 0.2rem 2rem;
                border-bottom: 1px solid $primary_Light;
              }
            }
          }
        }

        &.autoWidth {
          width: auto;
        }
      }

      &.dealdeskCreateTicket {
        overflow: hidden;

        ul.mainList {
          max-height: 222px;

          li {
            ul {
              max-height: 190px;
              overflow-y: auto;
            }
          }
        }
      }
    }
  }

  &.leadStatusFixHeight {
    .ant-popover-inner-content {
      max-height: 430px;
      .leadStatusOptins {
        background-color: $primary_Light;
        .headerTitle {
          cursor: pointer;
          width: calc(100% - 6px);
          padding: 6px;
          font-family: 'VerdanaBold', Verdana, sans-serif;
          font-weight: 600;
          font-size: 1.2rem;
          text-align: center;
          background-color: $primary_Dark;
          margin: 3px 3px 5px;
          color: #fff;
          font-weight: 800;
          &:hover,
          &:focus {
            background-color: $primaryTextDark;
            color: $white;
          }
        }
        ul {
          li {
            background-color: $primarySeleteBG;
            span.title {
              background-color: $primary_Dark;
              color: #fff;
              padding: 6px 8px 2px;
              display: block;
            }
            ul {
              max-height: 390px;
              overflow-y: auto;
              li {
                .colorStrip {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  gap: 10px;
                  .colorBox {
                    display: inline-flex;
                    width: 20px;
                    height: 20px;
                    margin: 0;
                    border-radius: 4px;
                    border: 1px solid $primaryTextDark;
                    &.x000000 {
                      background-color: #000000;
                    }

                    &.x7f7f7f {
                      background-color: #7f7f7f;
                    }

                    &.x880015 {
                      background-color: #880015;
                    }

                    &.xed1c24 {
                      background-color: #ed1c24;
                    }

                    &.xfc7f27 {
                      background-color: #fc7f27;
                    }

                    &.xfff20a {
                      background-color: #fff20a;
                    }

                    &.x22b04c {
                      background-color: #22b04c;
                    }

                    &.x15a2e8 {
                      background-color: #15a2e8;
                    }

                    &.x3f48cc {
                      background-color: #3f48cc;
                    }

                    &.xa349a4 {
                      background-color: #a349a4;
                    }

                    &.xffffff {
                      background-color: #ffffff;
                    }

                    &.xc3c3c3 {
                      background-color: #c3c3c3;
                    }

                    &.xb97a57 {
                      background-color: #b97a57;
                    }

                    &.xfdaec9 {
                      background-color: #fdaec9;
                    }

                    &.xfec90e {
                      background-color: #fec90e;
                    }

                    &.xefe4b0 {
                      background-color: #efe4b0;
                    }

                    &.xb5e61d {
                      background-color: #b5e61d;
                    }

                    &.x99d9ea {
                      background-color: #99d9ea;
                    }

                    &.x7092be {
                      background-color: #7092be;
                    }

                    &.xc8bfe7 {
                      background-color: #c8bfe7;
                    }
                  }
                }
                &:hover,
                &:focus {
                  background-color: $primary_Dark;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }

  &.autoHeight {
    .ant-popover-inner-content {
      height: auto;
    }
  }
}

.lockContentMenu {
  .lockUserList {
    vertical-align: top;
    overflow: hidden;
    border-radius: 0.5rem;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 350px;
      display: flex;
      align-items: stretch;
      justify-content: space-around;

      li {
        background-color: var(--lite);
        color: var(--textDark);
        font-size: 1.1rem;
        font-weight: 600;
        font-family: 'VerdanaBold', Verdana, sans-serif;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid var(--lite);
        text-align: left;
        cursor: pointer;
        display: inline-block;
        width: 50%;

        ul {
          width: calc(100% - 10px);
          height: 222px;
          overflow-y: auto;
          box-sizing: border-box;
          margin: 5px 5px 3px;
          padding: 0;
          background-color: var(--seleteBG);
          display: block;

          &.dealDeskTagSelection {
            height: 185px;
          }

          li {
            flex: auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            width: 98%;
            border-bottom: 1px solid var(--lite);
            background-color: var(--seleteBG);
            color: var(--textDark);
            font-size: 1.1rem;
            font-weight: 400;
            font-family: 'VerdanaWF', Verdana, sans-serif;
            padding: 3px 6px;

            &:hover,
            &:focus,
            &:active,
            &.active {
              background-color: $primary_Dark;
              color: $white;
            }
          }

          &.dealDeskCopySelection {
            height: 97px;
          }
        }
      }
    }

    &.createNotetoTicket {
      ul {
        width: 200px;

        li {
          width: 100%;
          display: block;

          ul {
            width: calc(100% - 10px);
          }
        }
      }
    }
  }
}

/*start:Dealdesk - right section - Multiple Tag selection*/
.lockContentMenu.ant-dropdown .ant-popover-inner-content.multipleTag {
  height: auto;
}

.multipleTag .multipleTagBtn {
  text-align: center;
  padding: 5px 0;
}

.multipleTag .multipleTagBtn button {
  margin: 0 4px !important;
  padding: 0.5rem 1.5rem 0.4rem !important;
  font-size: 1rem !important;
}

/*end : Dealdesk - right section - Multiple Tag selection*/

.profileMenu {
  border-radius: 10px;

  .ant-popover-arrow {
    border-color: $Offwhite !important;
    //border-top-color: var(--lite) !important;
  }

  .ant-popover-inner {
    border-radius: 10px;

    .ant-popover-inner-content {
      padding: 0;
      background-color: $Offwhite;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
      border: 1px solid $Offwhite;
      border-radius: 10px;
      overflow: hidden;
      overflow-y: auto;

      .popover-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        min-width: 220px;
        max-width: 230px;

        &.uploadFile {
          width: 450px;
          max-width: 450px;
          padding: 10px;
          font-size: 1.2rem;
        }

        li {
          list-style: none;
          margin: 0;
          padding: 0.2rem 0.8rem;
          background-color: transparent;
          border-bottom: 1px solid $primary_Light;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 2.5rem;
          word-break: break-all;

          img.menuicon {
            width: 1.8rem;
            height: 1.8rem;
            margin-right: 0.6rem;
            display: inline-block;
            position: relative;
            top: -0.3rem;
          }

          a {
            color: $primaryTextDark;
          }

          &:hover,
          &:focus {
            background-color: $primary_Dark;
            color: $white;

            a {
              color: $white;
            }

            ul.bullet li {
              color: $white;

              &::marker {
                color: $white;
              }
            }
          }

          &:last-child {
            border: none;
          }

          &.theme {
            display: flex;
            align-items: center;

            span {
              display: inline-block;
              width: 2.4rem;
              height: 2.4rem;
              border: 2px solid;
              margin: auto 0.3rem;

              &.blue {
                background-color: #97a6bf;
                border-color: #97a6bf;
              }

              &.pink {
                background-color: #bb9baf;
                border-color: #bb9baf;
              }

              &.gray {
                background-color: #a5a5a5;
                border-color: #a5a5a5;
              }

              &.yellow {
                background-color: #ddb95c;
                border-color: #ddb95c;
              }

              &.green {
                background-color: #a0c793;
                border-color: #a0c793;
              }

              &:hover,
              &:focus,
              &.active {
                border: 2px solid $black;
              }
            }
          }

          &.size {
            display: flex;
            align-items: center;

            span {
              display: inline-block;
              padding: 0 10px;
              font-weight: 900;
              font-family: 'VerdanaBold', Verdana, sans-serif;
              border: 2px solid transparent;
              line-height: 30px;

              &.small {
                font-size: 80%;
              }

              &.medium {
                font-size: 110%;
              }

              &.large {
                font-size: 130%;
              }

              &:hover,
              &:focus,
              &.active {
                background-color: $primary_Dark;
                color: $white;
                border: 2px solid $black;
              }
            }
          }
        }

        // &.nocheckbox {
        //    .ant-checkbox {
        //     display: none !important;
        //   }
        // }
        &.fixedHeight {
          max-height: 90vh;
          overflow-y: auto;
        }
      }

      .contactList {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          min-width: 100px;
          border-radius: 10px;

          li {
            text-align: left;
            font-size: 1.2rem;
            list-style: none;
            margin: 0;
            padding: 0.2rem 0.8rem;
            background-color: transparent;
            border-bottom: 1px solid $primary_Light;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 3rem;

            a {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0 10px;
              margin: -1px 0 0;
              color: $primaryTextDark;

              img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }
            }

            &:hover,
            &:focus {
              background-color: $primary_Dark;
              color: $white;

              a {
                color: $white;
              }
            }

            &:last-child {
              border: none;
            }
          }
        }
      }
    }
  }
}

.dropDownMenuTop {
  ul {
    width: 21rem;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: $white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid $primary_Light;
    border-radius: 10px;

    li {
      list-style: none;
      margin: 0;
      padding: 0.2rem 0.8rem;
      background-color: transparent;
      border-bottom: 1px solid $primary_Light;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 3rem;

      img.menuicon {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.6rem;
        display: inline-block;
        position: relative;
        top: -0.3rem;
      }

      &:hover,
      &:focus {
        background-color: $primary_Light;
        color: $primaryTextDark;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.profileMenu .ant-popover-inner .ant-popover-inner-content .popover-menu li {
  a {
    display: block;
    width: 100%;
  }

  ul.bullet {
    margin: 0 0 10px;
    padding: 0;

    li {
      margin: 0 0 0 30px;
      padding: 0;
      border-bottom: 0;
      list-style: disc;
      line-height: 26px;
      color: $primaryTextLight;

      &::marker {
        color: $primaryTextLight;
        width: 10px;
        font-size: 15px;
      }
    }
  }
}

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: $black !important;
  opacity: 0.3 !important;
  border-bottom: 1px solid $primary_Dark !important;
}

.ant-menu-inline-collapsed-tooltip {
  .ant-tooltip-inner {
    font-size: 13px;

    .menuicon {
      margin-right: 10px;
      width: 18px;
      height: 18px;
    }

    .count {
      margin-left: 10px;

      .anticon.anticon-plus-circle {
        display: none;
      }

      &::before {
        content: '(';
      }

      &::after {
        content: ')';
      }
    }
  }
}

.site__sidebar.collapse ul.ant-menu.ant-menu-inline-collapsed li.ant-menu-item {
  width: 40px !important;
  display: flex;

  .ant-menu-title-content,
  span {
    width: 40px;
    display: flex;
    justify-content: flex-start;
    opacity: 1;
    position: relative;
    left: 0;
    transition: all 0.1s ease;

    span {
      display: none;
      width: 0;
      opacity: 0;
    }
    &.count{
      opacity: 0;
      right: -30px;
      transition: all 0.1s ease;
    }
    &.anticon-info-circle{
      opacity: 1;
      left: 0;
    }
  }
}

.ant-dropdown {
  &.multiColDropdown {
    .leadStatusOptins {
      background-color: $primary_Light;
      max-height: auto;
      border-radius: 10px;
      margin: -4px 0 0;
      box-shadow: 0 -8px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
      .headerTitle,
      .footer {
        cursor: pointer;
        width: 100%;
        padding: 6px;
        font-family: 'VerdanaBold', Verdana, sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
        text-align: center;
        background-color: $primarySeleteBG;
        margin: 5px 0px !important;
        color: $primaryTextDark;
        font-weight: 800;
        border-radius: 10px 10px 0 0;
        &:hover,
        &:focus,
        &.active {
          background-color: $primary_Dark;
          color: $white;
        }
      }
      .footer {
        border-radius: 0 0 10px 10px;
        margin: -5px 0 0 !important;
      }
      ul {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        gap: 1%;
        margin: 0;
        margin-bottom: 5px;
        padding: 0 1%;
        list-style: none;
        width: 100%;
        flex: 0 0 100%;
        font-family: 'Verdana', Verdana, sans-serif;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5;
        li {
          background-color: $primarySeleteBG;
          width: 100%;
          margin-bottom: 5px;
          // &:first-child {
          //   border-radius: 0 0 0 10px;
          // }

          &.active {
            background-color: $primary_Dark;
            color: $white;
          }
          // &:last-child {
          //   border-radius: 0 0 10px 0;
          // }
          span.title {
            background-color: $primary_Dark;
            color: #fff;
            padding: 6px 8px 2px;
            display: block;
          }
          ul {
            max-height: 390px;
            overflow-y: auto;
            display: block;
            margin: 2px 0;
            padding: 0;

            li {
              display: block;
              padding: 3px 8px;
              margin: 2px;
              border-radius: 0 !important;
              width: calc(100% - 4px);
              cursor: pointer;
              .colorStrip {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                .colorBox {
                  display: inline-flex;
                  width: 20px;
                  height: 20px;
                  margin: 0;
                  border-radius: 4px;
                  border: 1px solid $primaryTextDark;
                  &.x000000 {
                    background-color: #000000;
                  }

                  &.x7f7f7f {
                    background-color: #7f7f7f;
                  }

                  &.x880015 {
                    background-color: #880015;
                  }

                  &.xed1c24 {
                    background-color: #ed1c24;
                  }

                  &.xfc7f27 {
                    background-color: #fc7f27;
                  }

                  &.xfff20a {
                    background-color: #fff20a;
                  }

                  &.x22b04c {
                    background-color: #22b04c;
                  }

                  &.x15a2e8 {
                    background-color: #15a2e8;
                  }

                  &.x3f48cc {
                    background-color: #3f48cc;
                  }

                  &.xa349a4 {
                    background-color: #a349a4;
                  }

                  &.xffffff {
                    background-color: #ffffff;
                  }

                  &.xc3c3c3 {
                    background-color: #c3c3c3;
                  }

                  &.xb97a57 {
                    background-color: #b97a57;
                  }

                  &.xfdaec9 {
                    background-color: #fdaec9;
                  }

                  &.xfec90e {
                    background-color: #fec90e;
                  }

                  &.xefe4b0 {
                    background-color: #efe4b0;
                  }

                  &.xb5e61d {
                    background-color: #b5e61d;
                  }

                  &.x99d9ea {
                    background-color: #99d9ea;
                  }

                  &.x7092be {
                    background-color: #7092be;
                  }

                  &.xc8bfe7 {
                    background-color: #c8bfe7;
                  }
                }
              }
              &:hover,
              &:focus {
                background-color: $primary_Dark;
                color: $white;
              }
              &.active {
                background-color: $primary_Dark;
                color: $white;
              }
              &.disabled {
                background-color: $primary_Light;
                color: $primary_Dark;
                cursor: not-allowed;
                pointer-events: all;
                .colorStrip {
                  .colorBox {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
