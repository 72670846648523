.ag-theme-alpine .ag-root-wrapper {
  border-color: $black_300;
}

.ag-theme-alpine {
  font-family: 'VerdanaWF', Verdana, sans-serif;

  .ag-header {
    background-color: $white;

    .ag-header-cell-text {
      color: $primary_color;
      font-size: 1.2rem;
      color: $primaryTextDark;
      letter-spacing: 0.5px;
      font-weight: 600;
    }

    .ag-header-cell-resize:after {
      background-color: $primary_Dark !important;
      background-color: var(--ag-header-column-resize-handle-color,
          $primary_Dark ) !important;
    }

    .ag-header-row-floating-filter {
      background-color: $primarySeleteBG !important;
    }
  }

  .ag-header-row-floating-filter {
    background-color: $black_200;
  }

  .ag-ltr .ag-cell,
  .ag-header-cell {
    border-right: 1px solid $primary_Light !important;
    font-size: 1.2rem;
    color: $primaryTextLight;
  }

  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border-right: 1px solid $primary_Light !important;
  }

  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    border-left: 0;
  }

  .ag-pinned-right-cols-container,
  .ag-pinned-right-header {
    border-left: 1px solid $primary_Light !important;
  }

  .ag-pinned-left-cols-container,
  .ag-pinned-left-header {
    border-right: 1px solid $primary_Light !important;
  }

  .ag-pinned-right-header {
    border-left: 0;
  }

  .ag-header-row:not(:first-child) .ag-header-cell {
    border-top: 1px solid $primary_Light !important;
  }

  .ag-header {
    border-bottom: 1px solid $primary_Light !important;
  }

  .ag-row {
    border-color: var(--ag-row-border-color,
        var(--ag-secondary-border-color, $primary_Light)) !important;
    border-color: $primary_Light !important;
  }

  .ag-floating-filter-body input[type='text'] {
    height: 26px;
    border: 1px solid $primary_Dark;

    &:focus {
      box-shadow: none;
      border-color: $primary_color;
    }
  }

  .ag-floating-filter-button-button {
    width: 30px;
    height: 30px;
    padding: 0 !important;

    .ag-icon.ag-icon-filter {
      font-size: 18px;
      color: $primaryTextDark;
    }
  }

  .ag-labeled.ag-text-field.ag-input-field input {
    padding-right: 26px !important;
  }

  .ag-ltr .ag-floating-filter-button {
    margin-left: 0;
    position: absolute;
    right: 20px;
  }

  .ag-tab-selected {
    border-bottom-color: $primary_color;
  }

  .ag-row {
    transition: all 0.5s ease;
  }

  .ag-row-odd {
    background-color: $primarySeleteBG;
  }

  .ag-row-hover {
    background-color: $primaryConversation;
  }

  .ag-row-odd.ag-row-selected,
  .ag-row-even.ag-row-selected {
    background-color: $primary_Light;
    border-bottom: 1px solid $black_100;

    .ag-cell {
      border-right-color: $black_100;
    }
  }




  .ag-pinned-right-cols-container,
  .ag-pinned-right-header {
    border-left: 1px solid $black_300;
    margin-left: -1px;
  }

  .custom__cell {
    line-height: 40px;
  }

  .ant-switch {
    margin-top: -6px;
  }

  input[class^='ag-'][type='number']:focus {
    box-shadow: none;
    border-color: $primary_color;
  }

  .ag-picker-field-wrapper:focus {
    box-shadow: none;
  }



}

.ag-theme-alpine .ag-ltr .ag-pinned-right-header .ag-header-row:after,
.ag-theme-alpine .ag-rtl .ag-pinned-left-header .ag-header-row:before {
  background-color: transparent;
}

.ag-theme-alpine {

  .ag-header-cell-menu-button:hover,
  .ag-side-button-button:hover,
  .ag-tab:hover,
  .ag-panel-title-bar-button:hover,
  .ag-header-expand-icon:hover,
  .ag-column-group-icons:hover,
  .ag-group-expanded .ag-icon:hover,
  .ag-group-contracted .ag-icon:hover,
  .ag-chart-settings-prev:hover,
  .ag-chart-settings-next:hover,
  .ag-group-title-bar-icon:hover,
  .ag-column-select-header-icon:hover,
  .ag-floating-filter-button-button:hover,
  .ag-filter-toolpanel-expand:hover,
  .ag-chart-menu-icon:hover,
  .ag-tab-selected {
    color: $primary_color;
  }

  // .ag-menu-option-active {
  //   @include themeColorOpacity($primary_color, 0.1);
  // }
  i.icon:before {
    font-size: 18px !important;
    color: $primary_color !important;

    .filename {
      font-size: 1.2rem !important;
    }
  }
}

.action-column {
  img.menuicon {
    width: 1.8rem;
    height: 1.8rem;
  }

  .ant-btn-text {
    border: none;
  }

  .ant-btn,
  button,
  html [type='button'],
  [type='reset'],
  [type='submit'],
  .ant-btn-sm {
    img {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  .ant-btn-round {
    border-radius: 50% !important;
  }

  .ant-switch {
    width: 30px;
    min-width: 30px;
    height: 16px;

    .ant-switch-handle {
      width: 12px;
      height: 12px;
    }

    &.ant-switch-checked .ant-switch-handle {
      left: calc(100% - 13px - 2px);
    }
  }
}

.DeleteIcon {
  span {
    border: 1px solid $primaryTextLight;
    border-radius: 5rem;
    padding: 1rem;
    width: 6rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    color: $primaryTextDark;

    svg {
      font-size: 4rem;
    }
  }
}

.DeleteText {
  font-size: 1.6rem;
  font-weight: 400;
  color: $primaryTextDark;
}

.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  background: $primary_Dark;
  border-color: $primary_Dark;

  &:hover,
  &:focus,
  &:active {
    background: $primaryTextDark;
    border-color: $primary_Dark;
  }
}

.ag-row-group {
  color: $primaryTextDark;
  background: $primary_Light;
}

.fileFolder {
  .ag-row-group {
    color: $primaryTextDark;
    background: $primarySeleteBG;
  }
}

.ag-header-row.ag-header-row-column {
  color: $primaryTextDark;
  background: $primary_Light;
  border-bottom: 1px solid $primary_Dark;
}

input.ag-input-field-input,
input.ag-input-field-input:disabled {
  padding: 3px 6px !important;
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.ag-theme-alpine {
  .ag-cell {
    line-height: 30px !important;

    &.curPointer {
      cursor: pointer;
    }

    &.actionColumn {
      padding: 0 3px !important;

      .action-column {
        display: flex !important;
        align-items: center !important;
        height: 28px !important;

        .ant-btn.ant-btn-text {
          padding: 0px 8px !important;
        }
      }
    }
  }
}

.ag-cell.cellClass.StatusCell {
  justify-content: flex-start;
  position: relative;
  padding: 0;
  margin: 0;

  .changeStatus {
    padding: 0 5px;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
  }

  .StatusRenderer {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100% !important;

    &.text-left {
      position: relative;
      width: 90%;
    }
  }
}

.cellClass.StatusCellPromotion {
  // justify-content: flex-start;
  // position: relative;
  padding: 0 0 0 5px;
  margin: 0;
}

.textEclipses {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reportPreviewData {
  // .ag-root-wrapper-body.ag-layout-normal {
  //   height: auto;
  //   min-height: unset;
  // }

  .ag-floating-bottom .ag-cell {
    color: $primaryTextDark !important;
    background-color: $primary_Light;
    font-weight: 900;
  }
}

.ag-theme-alpine {
  .ag-checkbox-input-wrapper.ag-checked::after {
    color: $primary_Dark !important;
  }

  &.paginationAtTop {
    position: relative;
    overflow: visible;

    .ag-root-wrapper {
      overflow: visible;
      border: none;

      .ag-paging-panel {
        position: absolute;
        top: -23px;
        left: 0;
        border: none;
        height: auto;
        width: auto;

        .ag-paging-row-summary-panel {
          padding-left: 0;
          margin-left: 0;
        }
      }
    }
  }
}

// //-----------START:Bottom Pinned Action Column Icon Hide ----//
// .ag-pinned-right-floating-bottom img.menuicon {
//   display: none;
// }
// //-----------END: Bottom Pinned Action Column Icon Hide ----//

.ag-theme-alpine {
  .ag-row {
    &.row-other {
      background-color: #fff !important;
      border-color: var(--ag-row-border-color,
          var(--ag-secondary-border-color, $primary_Dark)) !important;
      border-color: $primary_Dark !important;

      .ag-cell {
        color: #454545 !important;
        border-right: 1px solid $primary_Dark !important;
      }

      .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell),
      .ag-pinned-right-cols-container {
        border-left: 1px solid $primary_Dark !important;
      }

      &.row-critical {
        background-color: $primaryComment !important;

        .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell),
        .ag-pinned-left-cols-container,
        .ag-cell {
          color: $primaryTextDark !important;
        }
      }

      &.row-urgent {
        background-color: $primaryConversation !important;

        .ag-cell {
          color: $primaryTextDark !important;
        }
      }

      &.row-important {
        background-color: $primary_Light !important;

        .ag-cell {
          color: $primaryTextDark !important;
        }
      }

      &.row-timed {
        background-color: $primarySeleteBG !important;
        filter: brightness(98.5%);

        .ag-cell {
          color: $primaryTextDark !important;
        }
      }
    }
  }
}

.ag-theme-alpine .ag-cell {
  &.deadlineRender {
    // position: relative;
    padding: 0 20px 0 25px;

    .icon {
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      z-index: 5;
      width: 14px;
      height: 14px;
      display: inline-block;
      // cursor: pointer;
    }
  }

  &.priorityRender {
    padding: 0;
    position: relative;


    .priority {
      position: relative;
      top: 0;
      left: 0;
      z-index: 0;
      padding: 0 0 0 30px;

      &::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: transparent;
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 5;
      }

      &.Critical {
        &::after {
          background-color: #ff2828;
        }
      }

      &.Urgent {
        &::after {
          background-color: #FFBF00;
        }
      }

      &.Important {
        &::after {
          background-color: #3776ea;
        }
      }
    }


  }
}

.ag-cell {
  &.reportColumn {
    // background-color: #c00;
    // color: #fff;
    display: inline-flex;
    overflow: hidden;
    span{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      sortbyleadstatus {
        display: inline-block;
        overflow: hidden;
        width: 50px;
        position: absolute;
        left: -50px;
        z-index: -10;
      }
    }
  }

  a{
    color: $primaryTextLight;
  }
}