//Core SCSS
* {
  box-sizing: border-box;
  scrollbar-color: $scroll_dark_color $scroll_light_color;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    appearance: none;
    // -webkit-appearance: none;
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: $scroll_light_color;
  }

  ::-webkit-scrollbar-thumb {
    background: $scroll_dark_color;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: $scroll_hover_color;
    }
  }
}

html {
  font-size: var(--fontSize);
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  background-color: $body_bg;
  font-family: 'VerdanaWF', Verdana, sans-serif;
}

b,
strong,
.ant-btn,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'VerdanaBold', Verdana, sans-serif;
  font-weight: 600;
}

.site__sidebar {
  left: 0;
  top: 0;
  position: fixed;
  //height: 100vh;
  //position: fixed;
  z-index: 10;
  width: $sidebar_width;

  &.site__main__sidebar {
    bottom: 0;
  }

  &.second__site__sidebar {
    top: 146px !important;
    bottom: 0px;

    @media screen and (min-width: 1400px) {
      top: 172px !important;
    }
  }

  ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
    //height: $sidebar_height;
    overflow-y: auto;
  }

  &+section.ant-layout.site__layout {
    padding-left: $sidebar_width;
    transition: all 0.3s;
  }

  .logo {
    height: 64px;
    max-width: 100%;
    padding: 10px $gutter;
  }
}

.ant-layout-sider-collapsed {
  &+section.ant-layout.site__layout {
    padding-left: 80px;
  }

  .ant-menu-item>span,
  .ant-menu-submenu-title>span {
    display: none;
  }
}

.site__layout__wrapper {
  min-height: 100vh;
}

.site__header {
  background: white;
  display: flex;
  justify-content: space-between;
}

.site__layout__content {
  background-color: white;
  margin: $gutter;
  padding: $gutter;
  min-height: 300px;
  height: 100%;
}

.fullscreen__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: $loader_bg;
}

.grid_wrapper,
.ant-page-header.ant-page-header-ghost {
  height: 100%;
  min-height: 250px;
  padding: 0;
  background-color: transparent !important;
}

.ant-page-header-content {
  height: calc(100% - 40px);
}

.ag-paging-panel.ag-unselectable {
  display: flex;
  justify-content: center;
}

.ag-row-even {
  background-color: $primary_Light;
}

.ant-page-header {
  .ant-btn.ant-btn-round {
    border-radius: 4rem !important;
    padding: 1rem 2rem !important;
    font-weight: 400;
    font-family: 'VerdanaWF', Verdana, sans-serif;

    img {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 5px;
    }
  }


}

.ant-drawer-content {
  overflow: visible !important;

  //overflow-y: auto !important;
  .ant-drawer-header {
    position: relative;

    .ant-drawer-close {
      position: absolute;
      left: -30px;
      top: 1rem;
      background-color: $white;
      border-radius: 40px 0 0 40px;
      width: 30px;
      padding: 0.5rem;

      .anticon {
        font-size: 1.5rem;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: $primary_Dark;
        color: $white;
      }
    }
  }
}

.ant-drawer-body {
  padding: 1.5rem 2rem;
}

.ant-drawer-header,
.ant-modal-header {
  border-bottom: 1px solid $primary_Light;
  padding: 1.5rem 2rem;
}

.ant-drawer-footer,
.ant-modal-footer {
  border-top: 1px solid $primary_Light;

  .ant-btn {
    padding: 1rem 2rem !important;
    font-weight: 400;
    font-family: 'VerdanaWF', Verdana, sans-serif;
    font-size: 1.2rem !important;

    &.ant-btn-primary {
      background-color: $primary_color;
      border-color: $primary_color;
      color: $white;

      &.markAsReadTag {
        background-color: green;
        border-color: green;
        color: #ffffff;
      }

      &.markAsCompletedTag {
        background-color: red;
        border-color: red;
        color: #ffffff;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $primary_Dark;
      color: $white;
    }
  }
}

.ant-modal-content {
  border-radius: 1.5rem;

  .ant-modal-header {
    border-radius: 1.5rem 1.5rem 0 0;
  }

  .ant-modal-close {
    top: -0.3rem;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1rem !important;
    height: 1rem;

    .ant-modal-close-x {
      font-size: 2rem;
    }
  }
}

.ant-checkbox-wrapper {

  div,
  strong,
  i {
    line-height: 1.6rem;
    font-size: 1.2rem;
    display: block;
    margin: 0.3rem 0;
    word-break: break-all;
    white-space: normal;
  }
}

.popover-menu li {
  .ant-checkbox-wrapper {
    .ant-checkbox {
      top: 0.5rem;
    }
  }
}

.ant-modal-content {
  border-radius: 1.5rem;

  .ant-modal-header {
    border-radius: 1.5rem 1.5rem 0 0;
  }

  .ant-modal-close {
    top: -0.3rem;
    padding: 0 !important;
    margin: 0 !important;
    line-height: 1rem !important;
    height: 1rem;

    .ant-modal-close-x {
      font-size: 2rem;
    }
  }
}

form.innerForm .ant-btn.ant-btn-round.ant-btn-sm {
  font-family: 'VerdanaWF', Verdana, sans-serif;
  font-size: 1.2rem !important;
  font-weight: 400;
  height: 30px;
  padding: 0 2rem !important;
}

.fa,
.far,
.fas {
  color: $black !important;
  font-size: 1.2rem !important;
  line-height: 1.8rem !important;

  &:before {
    color: $primaryTextLight !important;
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
  }

  span {
    color: $primaryTextDark;
    font-size: 1.2rem;
    line-height: 1.8rem !important;
    font-family: 'VerdanaWF';
    margin: 0 0 0 0.5rem;
  }
}

.fileFolderList {
  .ag-header {
    display: none;
  }
}

.cursor {
  cursor: pointer;
}

.viewPeople {
  font-size: 1.2rem;

  * {
    word-break: break-word;
  }

  table {
    padding: 0;
    margin: -1rem 0 3rem;
    width: 100%;
    text-align: left;

    th,
    td {
      padding: 1rem 1rem;
      border: 1px solid $primary_Light;
      vertical-align: top;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $primaryTextLight;
    }

    th {
      background-color: $Offwhite;
      font-weight: 600;
      font-family: 'VerdanaBold', Verdana, sans-serif;
      color: $primaryTextDark;
    }

    td {
      background-color: $white;

      &.childTable {
        padding: 0;
        border: 0;

        table {
          margin: 0 !important;
          border: 0 none !important;
          width: 100%;
        }
      }

      .ant-form-item {
        margin: 0;
      }

      .ant-picker {
        input {
          width: calc(100% - 18px);
        }

        .ant-picker-suffix>* {
          vertical-align: middle;
        }

        .ant-picker-clear {
          right: 30px;
        }
      }

      ol,
      ul {
        li {
          list-style-type: inherit;
        }
      }

      img {
        max-width: 100%;
      }

      li {
        list-style-type: inherit;
      }

      table {
        max-width: 100%;
      }

      div {
        white-space: break-spaces;
        display: block;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: block;
        margin: 10px 0;
        padding: 0;
        font-weight: bold;
        line-height: 110% !important;
      }

      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: 25px;
      }

      h3 {
        font-size: 22px;
      }

      h4 {
        font-size: 20px;
      }

      h5 {
        font-size: 18px;
      }

      h6 {
        font-size: 16px;
      }

      pre {
        width: 100%;
        height: auto;
        overflow: visible;
        font-size: 16px;
        line-height: 18px;
        white-space: pre-wrap;
        margin: 10px 0;
        padding: 0;
      }
    }
  }
}

form.innerForm.headerButton,
.headerButton {
  .ant-form-item {
    margin: 0;
  }

  .ant-btn {
    margin: 0;
  }

  .ant-picker {
    border-radius: 4rem !important;
    border-color: $primary_Dark !important;
  }

  .ant-select {
    border-radius: 4rem !important;
    border-color: $primary_Dark !important;

    .ant-select-selector {
      width: 100%;
      border-radius: 4rem !important;
      border-color: $primary_Dark;
      padding: 0.3rem 1rem !important;
    }
  }

  &.narrowButtons {
    .ant-btn {
      margin: 0;
      padding: 0.8rem 1.5rem !important;
    }

    @media screen and (min-width: 1400px) {
      .ant-btn {
        margin: 0;
        padding: 0.8rem 2rem !important;
      }
    }
  }

  .narrowRecordPerPage {
    min-width: 100px !important;
  }
}

.goBackBtn.ant-btn.ant-btn-round {
  height: auto !important;
  line-height: 1.5rem !important;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem !important;
}

.cursor {
  cursor: pointer;

  input {
    cursor: pointer;
  }
}

.cursorArrow {
  cursor: default;
}

.cursorPointer {
  cursor: pointer;
}

.ag-theme-alpine .ag-header-cell {
  border: none !important;
}

.halfcut__content {
  transition: all 0.2s;
}

.site__middle__content.collapse .halfcut__content {
  margin-left: -222px !important;
}

.tox {
  &.tox-tinymce {
    width: 100%;
  }

  .tox-toolbar,
  .tox-toolbar__overflow,
  .tox-toolbar__primary {
    background: none !important;
  }

  .tox-statusbar a,
  .tox-statusbar__path .tox-statusbar__path-item,
  .tox-statusbar__wordcount,
  button {
    padding: 0 !important;
    margin: 0 !important;
    list-style: 1 !important;
  }

  .tox-statusbar__branding {
    display: none;
  }

  .tox-dialog__footer-end {
    .tox-button {
      background-color: $primary_color;
      border-color: $primary_color;
      color: $white;
      border: 1px solid $primary_Dark !important;
      border-radius: 30px !important;
      box-sizing: border-box;
      padding: 0.5rem 2rem !important;
      margin-right: 5px !important;
      font-size: 1.5rem;
      line-height: 1.3;
      height: auto;

      &:hover,
      &:focus {
        color: #fff;
        background-color: $primary_Dark !important;
      }
    }

    .tox-button.tox-button--secondary {
      background: #ffffff;
      color: $primaryTextDark;

      &:hover,
      &:focus {
        color: $primaryTextDark !important;
        background: $primary_Light !important;
      }
    }
  }

  .tox-form {
    label {
      margin: 0;
      padding: 0;
      font-family: 'VerdanaWF', Verdana, sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      color: $primaryTextDark;
    }

    .tox-listboxfield .tox-listbox--select,
    .tox-textarea,
    .tox-textfield,
    .tox-toolbar-textfield {
      width: 100%;
      height: auto;
      padding: 1rem 1rem !important;
      margin: 10px 0 0 !important;
      font-size: 1.2rem !important;
      line-height: 1.5rem !important;
      color: $primaryTextDark !important;
      border: 1px solid $primaryInputBorder !important;
      border-radius: 5px !important;
      font-family: 'VerdanaWF', Verdana, sans-serif !important;
    }
  }
}

.marqueeBox {
  text-align: right;
  background-color: $primarySeleteBG;
  border-bottom: 1px solid rgba($color: $primary_rgb, $alpha: 0.5);
  font-size: 1.2rem;
  line-height: 1;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  display: flex;

  .marquee-container {
    width: calc(100% - 40px);

    .overlay {
      display: none;
    }
  }

  a {
    display: inline-block;
    margin-right: 15px;
    color: $primaryTextDark;

    .icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      margin-right: 5px;
    }

    &:after {
      content: '|';
      display: inline-block;
      margin-left: 15px;
    }

    &:hover,
    &:focus {
      color: $primaryTextDark;
    }
  }

  .controlIcon {
    width: 40px;
    height: 40px;
    padding: 12px;
    margin: 0;
    background-color: $primary_Light;
    border: 1px solid $primary_Dark;
    cursor: pointer;

    &.dark {
      background-color: $primary_Light;
    }

    &:hover,
    &:focus {
      background-color: $Offwhite;
    }
  }
}

.SortableTable {
  .ant-table-small {
    .ant-table-container {
      border-left-color: $primary_Light !important;

      table {
        border-color: $primary_Light !important;

        .ant-table-thead {
          tr {

            th,
            .ant-table-cell {
              background-color: $primarySeleteBG;
              color: $primaryTextDark;
              border-color: $primary_Light !important;
              border-right: 1px solid $primary_Light !important;
            }

            td {
              border-color: $primary_Light !important;
            }
          }
        }

        .ant-table-tbody {
          tr {
            th {
              background-color: $primarySeleteBG;
              color: $primaryTextDark;
              border-color: $primary_Light !important;
            }

            td {
              border-color: $primary_Light !important;

              .anticon.anticon-menu {
                color: $primaryTextDark !important;
              }
            }
          }
        }
      }
    }
  }
}

.menuicon.circle {
  border-radius: 40px;
}

.resopnsiveImg,
.resopnsiveImg img {
  max-width: 100%;
}

.ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinInside {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.full_screen_wrapper,
.ant-page-header.ant-page-header-ghost.full_screen_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 111;
  padding: 10px !important;
  background-color: #ffffff !important;
}

.ant-spin {
  color: $primary_color;
}

.ant-spin-dot-spin {
  margin: auto 8px;

  .ant-spin-dot-item {
    background-color: $primary_color;
  }
}

.colorInput {
  text-indent: -2000px;
  overflow: hidden;
  width: 35px;
  height: 35px;
  cursor: pointer;
  border: 1px solid $primaryInputBorder;
}

.action-column {
  span {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .ant-picker {
      padding: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }
  }

  img {
    cursor: pointer;
  }
}

.globalGridFilterList {
  .ant-select-item.ant-select-item-option {
    &:first-child {
      background-color: $primary_Light;
      color: $primaryTextDark;
    }
  }
}

.selectedFilesList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  //z-index: 10;
  background-color: #fff;
  // border-top: 1px solid $primaryInputBorder;
  border-bottom: 1px solid $primaryInputBorder;
  height: 50px;

  .itemBox {
    margin: 5px;
    border: 1px solid $primaryInputBorder;
    padding: 0;
    border-radius: 5px;
    width: 40%;
    max-width: 40%;
    background-color: $primarySeleteBG;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    line-height: 2rem;
    overflow: hidden;

    span.fileName {
      padding-left: 10px;
      width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .deleteIcon {
      padding: 5px;
      background-color: red;
      margin: 0;
      width: 30px;

      svg {
        width: 15px;
        height: 15px;
        fill: #fff;
      }
    }
  }

  &.expand {
    .itemBox {
      width: 18%;
      max-width: 18%;
    }
  }
}

.attachmentFileList {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: 300px;

  .itemBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    line-height: 1.2;
    padding: 6px 0;
    border-bottom: 1px solid $primaryInputBorder;

    span.fileName {
      padding-left: 10px;
      width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .deleteIcon {
      svg {
        width: 1.5em;
        height: 1.5rem;
      }
    }

    &:last-child {
      border: none;
    }
  }
}

.converbillLink {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

.noteMsg {
  font-size: 1.2rem;
  line-height: 1.5;
  display: block;
}

.labelWithCheckbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span.title {
    display: inline-block;
    margin: 0;
    padding: 0;
    padding-right: 8px;
    padding-top: 3px;
  }

  .ant-row {
    margin: 0 !important;
  }
}

.inlineRadioBtn {
  flex-direction: row !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .ant-col {
    width: auto;
    flex: 0 0 auto;
  }
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 0px;
}

.ant-popover-arrow {
  display: none !important;
}
.ant-picker-content .ant-picker-time-panel-cell-inner {
  text-transform: lowercase !important;
}