.ant-steps {
  padding: 0 0 5px;
  margin: 0 0 10px;
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  .ant-steps-item {
    width: auto;
    white-space: nowrap;
    position: relative;
    display: inline-flex;
    flex: unset;
    overflow: hidden;
    vertical-align: top;
    padding-right: 20px;

    .ant-steps-item-container {
      margin: 0;

      .ant-steps-item-icon {
        padding: 0;
        margin: 0;
        margin-right: 6px;
        width: 18px;
        height: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-color: $primary_Dark;
        background-color: $primary_Dark;
        opacity: 1;

        .ant-steps-icon {
          color: #fff;
          font-size: 1.1rem;
          line-height: 1.8;
          opacity: 1;
          font-weight: 600;
        }
      }

      .ant-steps-item-content {
        .ant-steps-item-title {
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 1.3;
          color: $primaryTextDark;
        }

        .ant-steps-item-description {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1;
          color: $primaryTextLight;
          padding-top: 4px;
        }
      }

      &:hover,
      &:focus,
      &:focus-within {
        opacity: 1;

        .ant-steps-item-content .ant-steps-item-title,
        .ant-steps-item-content .ant-steps-item-description {
          color: $primary_color;
          opacity: 1;
        }

        .ant-steps-item-icon {
          border-color: $primary_color;

          .ant-steps-icon {
            color: #fff;
          }
        }
      }
    }

    &:last-child {
      padding-right: 0;
    }

    &::before {
      background-color: $primary_Dark;
    }

    &::after {
      top: 10px;
      left: auto;
      right: 5px;
      margin: 0;
      width: 8px;
      height: 8px;
    }
  }
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: $primary_color;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: $primary_Dark;
  color: #fff;

  .ant-steps-icon {
    color: #fff;
  }
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}