.kanban-overview {
  border: 1px solid $primary_Light;
  padding: 5px;
  height: calc(100vh - 160px) !important;
  overflow: hidden;
}
.KanbanIcons {
  .ant-space-item {
    text-align: center;
    width: 35px;
    height: 40px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.e-kanban-header > div {
  width: 100% !important;
  padding: 0 !important;
}
.e-card-header {
  padding: 6px 6px 0 !important;
  .e-card-header-title.e-tooltip-text .icon {
    display: block;
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-btn.ant-btn-text {
      padding: 0 !important;
    }
  }
}
.e-kanban .e-kanban-table .e-header-cells {
  background-color: $primary_Light;
  border-radius: 0;
  padding: 0px 5px 0px 30px;
  font-size: 14px;
  text-align: center;
  width: 200px;
  .e-header-title .header-text {
    color: $primaryTextDark !important;
  }
}
.e-kanban .e-kanban-table .e-header-cells:before {
  content: '';
  display: block;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  position: absolute;
  top: 1px;
  left: -13px;
}
.e-kanban .e-kanban-table .e-header-cells:first-child:before {
  display: none;
}
.e-kanban .e-kanban-table .e-header-cells:first-child {
  border-radius: 40px 0 0 40px;
}
.e-kanban .e-kanban-table .e-header-cells:last-child {
  border-radius: 0 40px 40px 0;
}

.kanban-overview.e-kanban .header-template-wrap {
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  color: $primaryTextDark;
}

.kanban-overview.e-kanban .header-template-wrap .header-icon {
  font-family: 'Kanban priority icons';
  margin-top: 3px;
  width: 10%;
  display: none;
}

.e-kanban .e-kanban-table {
  border-spacing: 0;
  .e-header-cells .e-column-expand,
  .e-header-cells .e-column-collapse {
    color: $primaryTextLight;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    &::before {
      font-size: 1.1rem;
    }
    &:hover,
    &:focus {
      background-color: $primary_Dark;
      border: 1px solid $primary_Dark;
      border-radius: 30px;
      color: $white;
    }
  }
  .e-content-row {
    .e-content-cells {
      width: 200px;
    }
  }
}

.e-kanban .e-kanban-header .e-header-cells.e-collapsed {
  padding: 0 15px;
  background-color: $primary_Light;
  width: 2rem;
  height: 2rem;
  .e-column-expand,
  .e-column-collapse {
    width: 2rem;
    height: 2rem;
    &::before {
      font-size: 1.1rem;
    }
  }
  :before {
    left: -16px;
  }
}

.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells {
  border-radius: 0;
  border-left: 1px solid $primary_Light;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells:first-child {
  border: none;
}
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container {
  padding: 3px;
}
.e-kanban .e-kanban-header .e-header-cells .e-header-wrap .e-header-title div {
  padding: 0;
  margin: 0;
  height: auto;
  position: relative;
  width: 100%;
  text-align: center;
}
.e-kanban .e-kanban-header .e-header-cells {
  height: 30px;
}
.e-kanban .e-kanban-header .e-header-cells .e-header-wrap .e-header-title {
  align-items: center;
}
.e-kanban .e-kanban-content {
  background-color: #fff;
  height: calc(100vh - 138px);
  overflow-x: auto;
}
.e-kanban .e-kanban-table.e-content-table .e-content-row:not(.e-swimlane-row) td {
  background-color: transparent;
  height: calc(100vh - 175px);
  overflow-y: auto;
  .e-card {
    border: 1px solid $primary_Light;
    .e-card-header {
      .e-card-header-title {
        font-size: 1.3rem;
        line-height: 1.3;
        color: $primaryTextDark;
      }
    }
    .e-card-content {
      padding: 3px 6px 6px;
      .e-text {
        color: $primaryTextLight;
        opacity: 0.8;
        font-size: 1.2rem;
        line-height: 1.3;
      }
    }
  }
  .e-card.e-selection {
    background-color: $primarySeleteBG;
  }
}

.KanbanView {
  position: relative;
  .KanbanViewBtn {
    position: absolute;
    right: -15px;
    bottom: -3px;
    z-index: 99;
    padding: 1rem 1.5rem !important;
    border: 2px solid $primary_Dark;
    border-radius: 30px;
    padding: 0 !important;
    margin: 0 !important;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary_Light;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    img {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .TopSelection {
    margin: 0 0 8px;
    padding: 5px;
    background-color: $white;
    border: 1px solid $primary_Light;
    .ant-space {
      width: 100%;
      .ant-space-item {
        width: 19%;
        margin: 0 0.2% !important;
        padding: 0 !important;
        .ant-form-item {
          padding: 0;
          margin: 0;
          .ant-select {
            width: 100% !important;
          }
        }
      }
    }
  }
}
