span.ant-upload-picture-card-wrapper {
  width: 100%;
  height: 100%;
  padding: 5px;
  .ant-upload-list-picture-card {
    width: 100%;
    height: 100%;
    .ant-upload-select-picture-card {
      width: 100%;
      height: 100%;
    }
    .ant-upload-list-picture-card-container {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      .ant-upload-list-item {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
      }
    }
    div:nth-child(2) {
      display: none;
    }
  }
}

.ant-upload-list-item-card-actions-btn {
  background: transparent;
  border: navajowhite;
}
.ant-modal-content {
  border-radius: 1.5rem;
  .ant-modal-close {
    top: -0.8rem;
    .ant-modal-close-x {
      font-size: 2rem;
    }
  }
  .ant-modal-header {
    border-radius: 1.5rem 1.5rem 0 0;
    border-bottom: 1px solid $primary_Light;
    .ant-modal-title {
      font-size: 1.6rem;
      width: 95%;
    }
  }
}
.border-top {
  border-top: 1px solid $primary_Light;
}
.border-bottom {
  border-bottom: 1px solid $primary_Light;
}
.border-right {
  border-right: 1px solid $primary_Light;
}
.border-left {
  border-left: 1px solid $primary_Light;
}
.min-heigth {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: $Offwhite;
}
.topAlignment {
  background-color: $Offwhite;
  // padding-top: 10px;
}
.topAlignment,
.min-heigth {
  .labelOnly {
    label {
      font-family: 'VerdanaBold', Verdana, sans-serif;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
}

form.innerForm.profileForm .ant-form-item {
  margin: 0;
  padding: 1.03rem 1rem;
}

.ant-switch-checked {
  background-color: $primaryTextDark;
}
.ant-modal.myProfile {
  //height: 630px;
  .ant-modal-content {
    //height: 630px;
    .ant-modal-body {
      //max-height: 560px;
      padding: 5px 20px;
      //overflow-y: auto;
    }
  }
}

form.innerForm .ant-input-number {
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: $primaryTextDark;
  border: 1px solid $primaryInputBorder;
  border-radius: 5px;
  font-family: 'VerdanaWF', Verdana, sans-serif;
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input {
    width: 100% !important;
    border: none;
  }
}
.ag-theme-alpine .ag-cell.statusColumn {
  padding: 0px 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0 0;
}

.hideeyeicon .anticon.anticon-eye {
  display: none;
}

/*****START - ANTD TRANSFER CSS***/
.ant-transfer {
  width: 100%;
  flex: 0 0 100%;
  .ant-transfer-list {
    width: 48%;
    height: auto;
    // max-height: 300px;
    border-color: $primary_Light;
    .ant-transfer-list-header {
      border-bottom-color: $primary_Light;
      font-size: 11px;
      font-weight: bold;
      .ant-checkbox-wrapper {
        margin-right: 10px;
        order: 1;
      }
      .ant-transfer-list-header-selected {
        order: 2;
        color: $primaryTextDark;
      }
      .ant-dropdown-trigger {
        order: 3;
        svg {
          path {
            fill: $primaryTextDark;
          }
        }
      }
      .ant-transfer-list-header-title {
        order: 4;
      }
    }
    .ant-transfer-list-body {
      .ant-transfer-list-body-search-wrapper {
        .ant-input-prefix {
          margin-right: 0;
          padding: 0;
        }
        .ant-input {
          border: none;
          font-size: 10px;
        }
      }
      .ant-transfer-list-content {
        margin: 0;
        padding: 0;
        list-style: none;
        .ant-transfer-list-content-item {
          width: 33.33%;
          padding: 6px 3px;
          display: inline-flex;
          .ant-checkbox-wrapper {
            margin: 0 5px;
          }
          .ant-transfer-list-content-item-text {
            font-size: 10px;
          }
        }
      }
    }
  }
  .ant-transfer-operation {
    width: 4%;
    align-items: center;
    justify-content: center;
    .ant-btn {
      .anticon {
        svg {
          width: 10px;
          height: auto;
          path {
            fill: $primaryTextDark;
          }
        }
      }
      &.ant-btn-primary {
        width: 80%;
        height: auto;
        min-height: unset;
        padding: 8px !important;
        .anticon {
          svg {
            path {
              fill: #fff;
            }
          }
        }
        &[disabled] {
          .anticon {
            svg {
              path {
                fill: $primaryTextDark;
              }
            }
          }
        }
      }
    }
  }
}

.ant-dropdown.ant-dropdown-placement-bottomLeft {
  border-radius: 6px;
  .ant-dropdown-menu.ant-dropdown-menu-vertical {
    padding: 0;
    margin: 0;
    list-style: none;
    border-radius: 6px;
    li.ant-dropdown-menu-item {
      padding: 8px 15px;
      margin: 0;
      list-style: none;
      .ant-dropdown-menu-title-content {
        font-size: 1.2rem;
      }
      &:hover,
      &:focus {
        background-color: $primarySeleteBG;
        color: $primaryTextDark;
      }
    }
  }
}

/*****END - ANTD TRANSFER CSS***/
