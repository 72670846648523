.e-grid {
  font-weight: 400;
  font-family: 'VerdanaWF', Verdana, sans-serif;
  color: $primaryTextDark !important;
  font-size: 1.2rem;
}

#overview_file {
  height: 60vh !important;
  &.e-filemanager {
    border-color: $primarySeleteBG;
  }
}

.e-toolbar {
  font-weight: 400;
  font-family: 'VerdanaWF', Verdana, sans-serif;
  font-size: 1.2rem;
  background-color: $primarySeleteBG;
  filter: brightness(103%);
  padding: 0 !important;
  .e-toolbar-items {
    background-color: $primarySeleteBG;

    padding: 4px !important;
    .e-toolbar-item {
      filter: brightness(95%);
      .e-tbar-btn {
        &.e-btn {
          padding: 0 !important;
          width: auto;
          height: auto !important;
          background-color: $primary_Light;
          border: 1px solid $primary_Light !important;
          font-family: 'VerdanaBold', Verdana, sans-serif !important;
          font-weight: 600;
          .e-icons {
            color: $primaryTextDark !important;
          }
        }
      }
      .e-tbar-btn-text {
        color: $primaryTextDark;
        font-size: 1.2rem;
        font-family: 'VerdanaBold', Verdana, sans-serif !important;
        font-weight: 600;
      }
      &:first-child {
        margin: 0 !important;
      }
      &:hover,
      &:focus {
        .e-tbar-btn {
          &.e-btn {
            background-color: $primary_Dark !important;
            .e-icons {
              color: #fff !important;
            }
          }
        }
        .e-tbar-btn-text {
          color: #fff !important;
        }
      }
    }
  }
}
.e-treeview {
  font-weight: 400;
  font-family: 'VerdanaWF', Verdana, sans-serif;
  font-size: 1.2rem;
  color: $primaryTextDark !important;
  .e-list-item div.e-icons::before {
    color: $primaryTextDark !important ;
  }
  .e-list-text {
    color: $primaryTextDark !important  ;
    font-weight: 400;
    font-family: 'VerdanaWF', Verdana, sans-serif;
    font-size: 1.2rem;
  }
  &:hover,
  &:focus {
    .e-list-text {
      color: #fff !important;
    }
    .e-list-item div.e-icons::before {
      color: #fff !important;
    }
  }
  .e-list-item.e-active > .e-fullrow {
    background-color: $primary_Light;
    border: 1px solid $primary_Light !important;
    &:hover,
    &:focus {
      background-color: $primary_Dark;
      border: 1px solid $primary_Dark !important;
    }
  }
}

.e-filemanager .e-grid {
  .e-headercontent {
    background-color: $primarySeleteBG !important;
  }
  .e-gridheader .e-headercell {
    background-color: $primarySeleteBG !important;
    tr:first-child th {
      background-color: transparent;
    }
    .e-headertext {
      color: $primaryTextDark;
      font-weight: 400;
      font-family: 'VerdanaWF', Verdana, sans-serif;
      font-size: 1.2rem;
    }
  }
  td.e-active {
    background-color: $primarySeleteBG;
    color: $primaryTextDark !important;
  }
  // .e-headercell:not(.e-fe-grid-icon):hover,
  // .e-row.e-focused td {
  //   box-shadow: inset 0 1px 0 0 $black_500, inset 0 -1px 0 0 $black_500;
  // }
  .e-row.e-focused td:first-child {
    box-shadow: inset 1px 1px 0 0 $black_500, inset 1px -1px 0 0 $black_500;
  }
  .e-row.e-focused td:last-child {
    box-shadow: inset -1px 1px 0 0 $black_500, inset -1px -1px 0 0 $black_500;
  }
}

.e-address {
  .e-addressbar-ul {
    .e-address-list-item {
      font-weight: 600;
      .e-list-text {
        font-weight: 600;
        font-size: 1.2rem;
        letter-spacing: 0.04em;
      }
    }
  }
}

.e-grid .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-detailrowcollapse:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-detailrowexpand:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-gridheader .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd),
.e-grid .e-emptyrow:not(.e-editedbatchcell):not(.e-updatedtd) {
  color: $primaryTextDark;
  font-weight: 400;
  font-family: 'VerdanaWF', Verdana, sans-serif;
  font-size: 1.2rem;
}
.e-grid.e-gridhover
  tr[role='row']:not(.e-editedrow):hover
  .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-grid.e-gridhover
  tr[role='row']:hover
  .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-grid.e-gridhover
  tr[role='row']:hover
  .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-grid.e-rtl
  .e-gridhover
  tr[role='row']:hover
  .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-grid.e-gridhover
  tr[role='row']:hover
  .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell),
.e-filemanager .e-grid .e-gridheader .e-headercell:not(.e-fe-grid-icon):hover {
  background-color: $primarySeleteBG;
  color: $primaryTextDark;
}

.e-filemanager .e-search-wrap {
  display: none;
}
