.leadViewSelector {
  width: 65px !important;

  .ant-select-selector {
    height: 3.5rem;

    //padding: 1rem 2rem !important;
    img.menuicon {
      width: 1.8rem !important;
      height: 1.8rem !important;
    }
  }
}

.ant-select-item-option-content {
  img.menuicon {
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
}

.CellLink {
  color: $primaryTextLight;
}

.profileMenu .ant-popover-inner-content,
.timeMenu.leadStatusFixHeight .ant-popover-inner-content,
.lockContentMenu.leadStatusFixHeight .ant-popover-inner-content {
  .leadStatusOptins {
    vertical-align: top;
    overflow: hidden;
    border-radius: 0.5rem;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      width: 655px;
      display: flex;
      align-items: stretch;
      justify-content: space-around;

      li {
        // background-color: $primary_Light;
        background-color: $primarySeleteBG;
        color: $primaryTextDark;
        font-size: 1rem;
        font-weight: 600;
        font-family: 'VerdanaBold', Verdana, sans-serif;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid $primary_Light;
        text-align: left;
        cursor: pointer;
        display: inline-block;
        width: 33.334%;

        span {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }

        &:last-child {
          border-bottom: none;
        }

        ul {
          width: calc(100% - 10px);
          box-sizing: border-box;
          margin: 5px 5px 3px;
          padding: 0;
          background-color: $primarySeleteBG;
          display: block;

          li {
            width: 100%;
            border-bottom: 1px solid $primary_Light;
            background-color: $primarySeleteBG;
            color: $primaryTextDark;
            font-size: 1rem;
            font-weight: 400;
            font-family: 'VerdanaWF', Verdana, sans-serif;
            padding: 3px 6px;

            &:hover,
            &:focus,
            &:active,
            &.active {
              background-color: $primary_Dark;
              color: $white;
            }
          }
        }
      }
    }

    &.leadStatus {
      max-height: 50vh;
      overflow-y: auto;
      background-color: $primarySeleteBG;

      ul {
        flex-direction: column;
        width: 170px;
        background-color: transparent;

        li {
          width: 100%;
          background-color: transparent;
          padding: 5px 10px;
          font-weight: normal;
          font-family: 'VerdanaWF', Verdana, sans-serif;

          &.active,
          &:hover {
            background-color: $primary_Light;
            color: $primaryTextDark;
          }
        }
      }
    }
  }
}

.ant-popover-inner-content {
  .leadStatusOptins.leadStatus {
    height: auto;
    overflow: unset;
  }
}

.ticketsPopoverIcons.username {
  ul {
    width: 180px;
    // height: 200px;
    min-height: 150px;
    overflow-y: auto;

    li {
      text-align: left;

      &:hover,
      &:focus,
      &.active {
        background-color: $primaryTextDark;
        color: $white;
      }
    }
  }
}

.ag-theme-alpine .ag-cell.cellClassDropDown {
  padding: 0 !important;
  margin: 0 !important;

  .ant-select-selector {
    padding: 0 5px !important;
    margin: 0 !important;
    //height: 38px;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    outline: none;

    .ant-select-item-option-content {
      padding: 0;
      margin: 0;
      font-size: 1rem;
    }

    a {
      color: $primaryTextLight;

      img {
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}

.ant-popover {
  z-index: 999 !important;

  &.main-menu-popover {
    z-index: 1020 !important;
  }
}

.selectedConnectionBox {
  .connectionLisitng {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }
}

li.connectionLisitngLI {
  margin: 0 0 20px;
  width: calc(33% - 20px);
  margin-right: 20px;
  opacity: 1;
  z-index: 9999;

  &:last-child {
    margin-right: 0;
  }

  .ant-card {
    border-radius: 8px;
    overflow: hidden;

    &.ant-card-bordered {
      border: 1px solid $primaryInputBorder;

      .ant-card-head {
        padding: 0px 8px;
        background-color: $primarySeleteBG;

        .headContent {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1.4rem;
          font-weight: bold;
          color: $primaryTextDark;

          .dragger {
            margin-right: 10px;
            display: inline-block;

            span.anticon {
              svg {
                cursor: move;
              }
            }
          }

          svg {
            path {
              fill: $primaryTextDark;
            }
          }

          img.actionIcons {
            width: 1.8rem;
            height: 1.8rem;
          }
        }
      }

      .ant-card-body {
        padding: 10px;

        .bodyContent {
          font-size: 1.4rem;
          font-weight: normal;

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              width: 100%;
              margin: 0 0 10px;
              font-size: 1.2rem;
              font-weight: normal;
              display: block;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;

              b {
                display: inline-block;
                width: auto;
                min-width: 60px;
                color: $primaryTextDark;
              }

              .textData {
                display: inline-block;
                width: calc(98% - 60px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .connectionRoleDropdown {
                position: relative;
                z-index: 0;
                padding: 0;
                margin: 0;
                width: 100%;
                display: block;

                .ant-row.ant-form-item {
                  width: 100%;
                  margin: 0;
                }

                input {
                  width: 100%;
                }

                .ant-select {
                  cursor: pointer !important;

                  &.ant-select-disabled {
                    cursor: pointer !important;

                    .ant-select-selector {
                      background-color: #fff !important;
                      color: #333 !important;
                      cursor: pointer !important;
                    }

                    .ant-select-selection-placeholder {
                      color: #333 !important;
                      padding-right: 18px;
                      cursor: pointer !important;
                    }
                  }
                }

                .anticon {
                  &.anticon-down {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    z-index: 5;
                  }
                }

                .box {
                  cursor: pointer;

                  &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 8;
                    top: 0;
                    left: 0;
                  }
                }
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}