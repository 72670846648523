//Main Layout CSS
.site__header {
  height: 40px;
  padding: 0rem;
  align-items: center;
  background-color: $primary_Light;
}

// .ant-layout.ant-layout-has-sider {
//   flex-direction: row;
//   justify-content: flex-end;
// }
.site__layout__wrapper {
  height: calc(100vh - 40px) !important;
  justify-content: center;
  overflow: hidden;

  // .site__sidebar {
  //   flex: 0 0 262px !important;
  //   max-width: 262px !important;
  //   min-width: 262px !important;
  //   width: 262px !important;
  // }
  .right__panel {
    flex: 0 0 262px !important;
    max-width: 262px !important;
    min-width: 262px !important;
    width: 262px !important;
    background-color: $white;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.28);
    padding: 0;
    margin: 0;
    overflow-y: auto;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  :not(.second__site__sidebar) {
    &+.site__middle__content.collapse {
      padding-left: 40px;
    }
  }

  .site__middle__content {
    flex: 1;
    display: block;
    padding: 0rem;
    padding-left: 262px;
    background-color: $white;
    transition: all 0.2s;
    width: calc(100% - 262px);

    @media screen and (max-width: 1600px) {
      width: calc(100vw - 262px) !important;
    }

    &.collapse {
      flex: 1;
    }

    .middle__content__header {
      background-color: $white;

      .header_content {
        height: 8.5rem;
      }
    }

    .ant-layout-content {
      flex: auto;
      min-height: 0;
      //height: calc(100vh - 40px);
      //height: 100vh;
      padding: 0.8rem;
      background-color: #ffffff;

      //overflow-y: auto;
      section.main-content {
        background-color: #ffffff;
        overflow-y: auto;
        height: calc(100vh - 90px);
        overflow-x: hidden;
      }
    }
  }
}

.connectionDataBox {
  width: 100%;
  max-height: calc(100vh - 220px);
  overflow-y: auto;
}

.ant-spin-nested-loading>div>.ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;

  &.fullHeight {
    max-height: 100vh;
    position: fixed;

    &.w1000 {
      width: 1000px; // Need to change as per Drawer width
      right: 0;
      left: auto;
    }
  }

}

.ant-popover {
  &.drawerPopover {
    z-index: 2000 !important;
  }
}
