//Common Tag
h1.page-title,
h3.ant-typography,
.ant-typography h3,
.ant-page-header-heading-title {
  font-family: 'VerdanaBold', Verdana, sans-serif;
  font-weight: 600;
  font-size: 1.8rem !important;
  line-height: 24px;
  color: $primaryTextDark;
}
h3.page-title {
  font-size: 1.4rem !important;
  line-height: 20px;
  color: $primaryTextDark;
}
section.main-content {
  padding: 1.2rem;
  border: 1px solid $primary_Light;
}

.ant-divider {
  border-top-color: $primary_Light;
}
