// Core var
:root {
  --primary: #a5a5a5;
  --dark: #b4b4b4;
  --lite: #e5e5e5;
  --textDark: #484848;
  --textLite: #777777;
  --inputBorder: #dddddd;
  --seleteBG: #f8f8f8;
  --fontSize: 62.5%;
  --primaryComment: #cecece;
  --primaryConversation: #dddddd;
  --transparent: transparent;
}

$primary_color: var(--primary);
$primary_rgb: var(--primary_rgb);
$primary_Dark: var(--dark);
$primary_Light: var(--lite);
$primaryTextDark: var(--textDark);
$primaryTextLight: var(--textLite);
$primaryInputBorder: var(--inputBorder);
$primarySeleteBG: var(--seleteBG);
$primaryComment: var(--primaryComment);
$primaryConversation: var(--primaryConversation);
$primaryTransparent: var(--transparent);

// $primary_color: $pink;
// $primary_Dark: $pinkDark;
// $primary_Light: $pinkLight;
// $primaryTextDark: $pinkTxtDark;
// $primaryTextLight: $pinkTxtLight;
// $primaryInputBorder: $pinkBorder;
// $primarySeleteBG: $pinkSeleteBG;

// $primary_color: $blue;
// $primary_Dark: $blueDark;
// $primary_Light: $blueLight;
// $primaryTextDark: $blueTxtDark;
// $primaryTextLight: $blueTxtLight;
// $primaryInputBorder: $blueBorder;
// $primarySeleteBG: $blueSeleteBG;

$danger_color: $red;
$success_color: $green;
$success_dark: darken($success_color, 10%);
$danger_dark: darken($danger_color, 10%);
$scroll_light_color: #e9e9e9;
$scroll_dark_color: #b5b8bb;
$scroll_hover_color: #93999e;
$ag_alter_color: #f8f8f8;
$loader_bg: white;
$body_bg: $Offwhite;
$white: #ffffff;

$gutter: 24px;

$sidebar_width: 262px;
$sidebar_height: calc(100vh - 64px);
$logo_height: 64px;
