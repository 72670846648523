.ant-drawer {
  &.headerWith_MultiOptions {
    .ant-drawer-header {
      background-color: transparent;
      z-index: 0;

      .reportFilterTitle {
        padding: 0.5rem 0.8rem !important;
        height: auto;
        display: inline-flex;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        display: block;
        width: 95%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .extraRightOptions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;

        .groupBtn {
          font-family: 'VerdanaWF', Verdana, sans-serif;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 2rem !important;
          border-radius: 0 !important;
          padding: 0.5rem 1rem !important;
          margin: 0;
          height: auto !important;
          border: 1px solid #d9d9d9;
          border-left: 0;
          overflow: hidden;

          &:first-child {
            border-left: 1px solid #d9d9d9;
            border-radius: 5px 0 0 5px !important;
          }

          &:last-child {
            border-radius: 0 5px 5px 0 !important;
          }

          &.noPadding {
            padding: 0 !important;

            .ant-row {
              margin: 0;
            }
          }

          &.noInnerBorder {
            overflow: hidden;

            .ant-select-selector {
              border: 0;
              height: auto;
              width: 110px;
            }
          }
        }
      }
    }

    form.innerForm {
      .noMargin-InnerRow {

        .ant-row,
        .ant-form-item {
          margin: 0 !important;
        }

        input,
        .ant-input,
        textarea {
          padding: 0.8rem !important;
          height: auto;

        }

        .ant-picker {
          height: auto;

          .ant-picker-input input {
            padding: 0.8rem !important;
          }
        }

        input,
        .ant-input,
        textarea {
          padding: 0.8rem !important;
          height: auto;

        }
      }

      .mediumSelect {
        .ant-select {
          width: 100%;
          height: auto;
          font-size: 1.2rem;
          line-height: 1.3;

          .ant-select-selector {
            padding: 0 !important;
            height: auto;

            .ant-select-selection-search {
              left: 0;
            }

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
              padding: 0 0.8rem !important;
            }
          }
        }
      }
    }

    .ant-drawer-body {

      .grid_wrapper {
        &.customPaginationUI {
          .reportsTotalCountTop {
            display: inline-block;
            position: relative;
            top: -13px;

            &::after {
              content: "|";
              position: relative;
              right: -8px;
              color: #999;
            }
          }

          .ag-root-wrapper {
            overflow: visible;

            .ag-paging-panel {
              position: absolute;
              top: -30px;
              left: 180px;
              z-index: 5;
              border: none;
              width: auto;
              height: auto;
              justify-content: flex-start;

              .ag-paging-row-summary-panel {
                margin: 0;
              }
            }
          }

          &.removeLeft {
            .reportsTotalCountTop {
              &::after {
                display: none;
              }
            }

            .ag-root-wrapper {
              .ag-paging-panel {
                left: 0;
              }
            }
          }
        }
      }
    }
  }
}