// helper for margin
$margin: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

@each $m in $margin {
  .ml-#{$m} {
    margin-left: $m + px;
  }

  .mr-#{$m} {
    margin-right: $m + px;
  }

  .mt-#{$m} {
    margin-top: $m + px !important;
  }

  .mb-#{$m} {
    margin-bottom: $m + px;
  }

  .m-#{$m} {
    margin: $m + px !important;
  }
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

// helper for padding
$padding: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

@each $p in $padding {
  .pl-#{$p} {
    padding-left: $p + px !important;
  }

  .pr-#{$p} {
    padding-right: $p + px !important;
  }

  .pt-#{$p} {
    padding-top: $p + px !important;
  }

  .pb-#{$p} {
    padding-bottom: $p + px !important;
  }

  .p-#{$p} {
    padding: $p + px !important;
  }
}

// helper for width
$widths: 50, 80, 100, 150, 200, 250, 300, 350, 400, 500, 600, 700, 800, 900;

@each $width in $widths {
  .w#{$width} {
    width: $width + px;
    max-width: 100%;
  }
}

$widths: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

@each $width in $widths {
  .w-#{$width} {
    width: #{$width + '%'};
    max-width: 100%;
  }
}

$widths: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;

@each $width in $widths {
  .gap-#{$width} {
    gap: #{$width + 'px'};
  }
}

// helper for display property
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: flex-start;
}

.align-items-bottom {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

// alignment helper
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

// text-colors
.text-danger {
  color: $danger_color !important;
}

.text-success {
  color: $success_color !important;
}

.border-top {
  border-top: 1px solid $primary_Light;
}

.border-bottom {
  border-bottom: 1px solid $primary_Light;
}

.border-right {
  border-right: 1px solid $primary_Light;
}

.border-left {
  border-left: 1px solid $primary_Light;
}

.text-decoration-underline {
  text-decoration: underline;
}

.notification_wrapper {
  border-radius: 10px;
}