section.main-content {
  padding: 1.2rem;
  border: 1px solid #e5e5e5;
  background-color: #fff;
}
.dashboard {
  .clear {
    clear: both;
  }
  .box {
    border: 1px solid $primary_Light;
    display: inline-block;
    //float: left;
    width: 24%;
    min-height: 9rem;
    padding: 0.5rem;
    margin: 0.5%;
    position: relative;
    color: $primaryTextLight;
    &.hidden {
      display: none;
    }
    &.show {
      display: block;
    }
    .close {
      font-size: 1rem;
      position: absolute;
      right: 0.5rem;
      top: 0.3rem;
      display: block;
      cursor: pointer;
    }
    &:hover,
    &:focus,
    &:active {
      .close {
        color: #f00;
      }
    }
    .topWidget {
      border: 0;
      width: 100%;
      td {
        font-family: 'VerdanaWF', Verdana, sans-serif;
        font-size: 1rem;
        line-height: 1.8rem;
        padding: 0.3rem 0.1rem;
        color: $primaryTextLight;
        &.iconBox {
          width: 3rem;
          .icon {
            width: 2.5rem;
            height: 2.5rem;
          }
        }
        strong {
          font-weight: 400;
          font-size: 1.8rem;
          font-family: 'VerdanaWF', Verdana, sans-serif;
          color: $primaryTextLight;
        }
        b {
          font-weight: 400;
          font-family: 'VerdanaWF', Verdana, sans-serif;
          font-size: 1.6rem;
          line-height: 2rem;
          color: $primaryTextLight;
          padding-top: 0.5rem;
          display: block;
        }
      }
    }
    .ant-btn.ant-btn-text {
      padding: 0.5rem !important;
      font-size: 0.9rem !important;
      font-family: 'VerdanaWF', Verdana, sans-serif;
      font-weight: 400;
      color: $primaryTextLight;
      &.active {
        color: $primaryTextDark;
      }
    }
  }

  @media only screen and (min-width: 1600px) {
    .box {
      .close {
        font-size: 1.2rem;
        right: 1rem;
      }
      .topWidget {
        td {
          font-size: 1.4rem;
          line-height: 2rem;
          padding: 0.5rem;
          &.iconBox {
            width: 6rem;
            .icon {
              width: 5rem;
              height: 5rem;
            }
          }
          strong {
            font-size: 3rem;
          }
          b {
            font-size: 1.8rem;
            line-height: 2.4rem;
          }
        }
      }
      .ant-btn.ant-btn-text {
        padding: 1rem !important;
        font-size: 1.2rem !important;
      }
    }
  }
}
