.LogDetails {
  border: 1px solid $primary_Dark;
  border-radius: 0.8rem;

  .title {
    font-size: 1.4rem;
    border-bottom: 1px solid $primary_Dark;
    padding: 1.5rem;
  }

  .content {
    font-size: 1.4rem;
    line-height: 4rem;
    padding: 1.5rem;

    ol {
      padding: 0 0 0 2rem;

      li {
        span.w25 {
          width: 25px;
          display: inline-block;
        }

        .red {
          background-color: rgba(204, 0, 0, 0.2);
          color: rgb(204, 0, 0);
          word-break: break-all;
        }

        .green {
          background-color: rgba(25, 134, 0, 0.2);
          color: rgb(25, 134, 0);
          word-break: break-all;
        }
      }
    }
  }

  &.pageFillterView {
    .content {
      ol {
        padding: 0;
        margin: 0;

        li {
          display: flex;
          width: 100%;
          background-color: rgba(204, 0, 0, 0.15);
          color: rgb(204, 0, 0);
          font-size: 1.3rem;
          line-height: 2rem;
          margin: 2px 0;
          padding: 0;

          .itemName {
            padding: 5px 10px;
            font-weight: 600;
            width: 25%;
            background-color: rgba(202, 0, 0, 0.2);
          }

          .itemDetail {
            padding: 5px 10px;
            width: 75%;
            border-left: 2px solid #fff;
          }

          &:nth-child(even) {
            background-color: rgba(25, 134, 0, 0.15);
            color: rgb(25, 134, 0);

            .itemName {
              background-color: rgba(25, 134, 0, 0.2);
            }
          }
        }
      }
    }
  }
}

.viewAuditLog {
  font-size: 1.2rem;

  table {
    padding: 0;
    margin: -1rem 0 3rem;
    width: 100%;
    text-align: left;

    th,
    td {
      padding: 1rem 1rem;
      border: 1px solid $primary_Light;
      vertical-align: top;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $primaryTextLight;
    }

    th {
      background-color: $Offwhite;
      font-weight: 600;
      font-family: 'VerdanaBold', Verdana, sans-serif;
      color: $primaryTextDark;
    }

    td {
      background-color: $white;

      .ant-form-item {
        margin: 0 !important;
      }

      .ant-input-borderless {
        padding: 0 !important;
      }
    }
  }
}

form.innerForm.headerButton .ant-select {
  width: calc(100% - 1rem);
  outline: none;
  border-radius: 4rem;
}

.report-count {
  color: #9e9e9e;
  font-size: 1.2rem;
  margin: 5px 0 10px;
}
