//Main SCSS
@import '../include/fonts/style';
@import './colors';
@import './variables';
@import './form';
@import './core';
@import './helper';
@import './layout';
@import './main_menu';
@import './tag_common';
@import './ag-grid';
@import './ant-steps';
@import './drawer';

// INCLUDE SCSS FOR INDIVIDUAL PAGES
@import './page/algorithm';
@import './page/login';
@import './page/audit_log';
@import './rightsideModule';
@import './page/usergroup';
@import './page/users';
@import './page/peopleOrganisation';
@import './page/dashboard';
@import './page/knowledge';
@import './page/tickertape';
@import './page/tickets';
@import './page/leadlisting';
@import './page/kanbanView';
@import './page/contract';
@import './page/dealdesk';
@import './page/promotion';
@import './page/_formulize';
@import './page/dealBuilder';
@import './page/search';
@import './page/_fileManager';
@import './page/notes';
@import './page/_reminder.scss';
@import './page/sequencer';

.ant-popover-placement-topRight .ant-popover-content .ant-popover-arrow,
.ant-popover{
  z-index: 10;
}