.login__page__wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $body_bg;
  margin-top: -50px;
  .LoginLogo {
    max-width: 340px;
    height: auto;
    display: block;
    margin: 0 auto 15px;
    @media only screen and (max-width: 1400px) {
      width: 450px;
    }
  }
  .login__page__form {
    width: 370px;
    max-width: 90%;
    padding: 15px 20px 10px;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    input,
    input.ant-input {
      font-size: 1.5rem !important;
      padding: 10px 12px;
    }
    .title {
      font-size: 2.1rem;
      line-height: 1.5;
      margin: 0 0 1.5rem;
    }
    .ant-btn.login {
      padding: 1rem !important;
      font-size: 1.3rem !important;
      width: 45%;
      margin: auto;
    }
    .link {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: $black;
      &:hover,
      &:focus {
        color: $black_800;
      }
    }
    .copyRight {
      font-size: 1.1rem;
    }
    @media only screen and (max-width: 1400px) {
      width: 440px;
      .title {
        font-size: 2.2rem;
      }
    }
  }
}

.text_wrapper {
  font-size: 1.5rem;
}
