.ticketsTopSelection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;

  .ant-row.ant-form-item {
    width: 20%;
    padding: 2px;

    .ant-select {
      width: 100%;

      &.RecordPerPage {
        border-radius: 40px;

        .ant-select-selector {
          border-radius: 40px;
        }
      }
    }
  }

  .ant-select {
    width: 20%;
    padding: 2px;
  }

  &.sixPart {
    .ant-row.ant-form-item {
      width: 16.555%;
      padding: 2px;

      .ant-select {
        width: 100%;

        &.RecordPerPage {
          border-radius: 40px;

          .ant-select-selector {
            border-radius: 40px;
          }
        }
      }
    }
  }
}

.viewPeople {
  font-size: 1.2rem;

  * {
    word-break: break-word;
  }

  table {
    padding: 0;
    margin: -1rem 0 3rem;
    width: 100%;
    text-align: left;

    th,
    td {
      padding: 1rem 1rem;
      border: 1px solid $primary_Light;
      vertical-align: top;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $primaryTextLight;

      ol,
      ul {
        li {
          list-style-type: inherit;
        }
      }

      img {
        max-width: 100%;
      }

      li {
        list-style-type: inherit;
      }

      table {
        max-width: 100%;
      }

      div {
        white-space: break-spaces;
        word-break: break-all;
        display: block;

        &.tox.tox-tinymce {
          display: flex;
          white-space: unset;
        }

        .ant-picker-input {
          display: inline-flex;

          .ant-picker-suffix {
            margin-right: 0;
          }
        }
      }

      &.messageDisplay {
        div {
          display: block;
          white-space: normal;
          word-break: break-all;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: block;
        margin: 10px 0;
        padding: 0;
        font-weight: bold;
        line-height: 110% !important;
        white-space: break-spaces;
        word-break: break-all;
      }

      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: 25px;
      }

      h3 {
        font-size: 22px;
      }

      h4 {
        font-size: 20px;
      }

      h5 {
        font-size: 18px;
      }

      h6 {
        font-size: 16px;
      }

      pre {
        width: 100%;
        height: auto;
        overflow: visible;
        font-size: 16px;
        line-height: 18px;
        white-space: pre-wrap;
        margin: 10px 0;
        padding: 0;
      }

      a {
        color: #777;
        white-space: break-spaces;
        word-break: break-all;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }

    th {
      background-color: $primarySeleteBG;
      font-weight: 600;
      font-family: 'VerdanaBold', Verdana, sans-serif;
      color: $primaryTextDark;
    }

    td {
      background-color: $white;
      position: relative;
      .shortfallEdit{
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin: 0;
        padding: 0;
        cursor: pointer;
      }
    }

    ul.contactListing {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        width: 33%;
        padding: 5px 10px;
      }
    }

    ul.emailListing {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        width: 50%;
        padding: 5px 10px;
      }
    }
  }
}

.ant-popover-arrow {
  border-left-color: $primary_Light !important;
  border-top-color: $primary_Light !important;
}

.ticketsPopoverIcons {
  padding: 0 0;
  background-color: $primarySeleteBG;
  border-radius: 5px;
  overflow: hidden;

  .ant-space {
    padding: 5px 10px;

    .menuicon {
      display: inline-block;
      margin: 0;
      padding: 0;
      width: 1.8rem;
      height: 1.8rem;
      cursor: pointer;
    }

    span {
      display: inline-block;
      font-size: 1.2rem;
      font-family: 'VerdanaBold', Verdana, sans-serif;
      font-weight: 600;
      letter-spacing: 0rem;
    }

    .Calendar {
      position: relative;
      cursor: pointer;

      .ant-picker {
        position: absolute;
        z-index: 5;
        opacity: 0;
        padding: 0;
        margin: -0.3rem;
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
        overflow: hidden;

        .ant-picker-clear {
          display: none;
        }
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 80px;

    li {
      padding: 4px 8px;
      font-size: 1.2rem;
      border-bottom: 1px solid $primary_Light;
      text-align: center;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: $primaryTextDark;
        color: $white;
      }
    }
  }
}

.ck-editor__editable_inline {
  min-height: 90px;
}

.editorInput .tox.tox-tinymce {
  width: 100%;
}

.ant-btn.TagTicketReset {
  padding: 0.4rem 2rem !important;
  margin: 0.5rem 0 !important;
}

.ant-tooltip {
  width: auto !important;
  max-width: 850px !important;
}