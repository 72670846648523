/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'VerdanaWF';
  font-style: normal;
  font-weight: normal;
  src: local('Verdana'), url('verdana.ttf') format('truetype');
}
@font-face {
  font-family: 'VerdanaBold';
  font-style: normal;
  font-weight: 600;
  src: local('Verdana Bold'), url('VerdanaBold.ttf') format('truetype');
}

@font-face {
  font-family: 'MontaguSlab';
  font-style: normal;
  font-weight: 400;
  src: local('Montagu Slab'), url('MontaguSlab_24pt-Regular.ttf') format('truetype'),
    url(https://fonts.gstatic.com/s/montaguslab/v2/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDbEkP6HM8.woff2)
      format('woff2');
}
