.notes {
  .headerButton  {
     .ant-select .ant-select-selector {
      width: 100%;
      border-radius: 4rem;
      border-color: #e5e5e5;
      margin: 0;
     }
     .ant-form-item {
       margin-bottom: 10px;
     }
  }
}

.scroll_wrapper {
  color: #fff;
  &:hover {
    color: #fff;
  }
}