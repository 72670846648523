.sequencerTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $primaryInputBorder;

  thead {
    background-color: $primary_color;

    th {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 700;
      padding: 1rem 1.2rem;
      text-align: left;
    }
  }

  tbody {
    tr {
      background-color: #fff;
      border-bottom: 1px solid $primaryInputBorder;

      &:nth-child(even) {
        background-color: $primarySeleteBG;
      }

      td {
        color: $primaryTextLight;
        font-size: 1.2rem;
        font-weight: 400;
        padding: 1rem 1.2rem;
        text-align: left;
        vertical-align: top;

        .fileDownload {
          display: inline-block;
          margin: 5px 10px;
          cursor: pointer;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin-bottom: 6px;

            label {
              color: $primaryTextDark;
            }
          }
        }
      }
    }
  }
}