.ant-tabs.tabs {
  width: 100%;
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        display: none;
      }
      .ant-tabs-tab {
        background-color: $primary_Light;
        padding: 1.2rem;
        margin: 0;
        .ant-tabs-tab-btn {
          color: $primaryTextDark;
          font-weight: 600;
          font-family: 'VerdanaBold', Verdana, sans-serif;
          font-size: 1.4rem;
          line-height: 1.4rem;
        }

        &.ant-tabs-tab-active {
          background-color: $primary_Dark;
          color: $Offwhite;
          .ant-tabs-tab-btn {
            background-color: $primary_Dark;
            color: $Offwhite;
          }
        }
      }
    }
  }
  .ant-tabs-content-holder {
    background-color: $white;
    border: 1px solid $primary_Light;
    padding: 10px;
  }
}
.ant-radio-group.peopleOrgTabs {
  label.ant-radio-button-wrapper {
    background-color: #fff;
    padding: 10px 15px !important;
    margin: 0;
    border-radius: 0 !important;
    color: $primaryTextDark;
    font-weight: 600;
    font-family: 'VerdanaBold', Verdana, sans-serif;
    font-size: 1.4rem !important;
    line-height: 1.3 !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    &.ant-radio-button-wrapper-disabled {
      background-color: $primary_Light;
      color: $primaryTextDark;
    }
    &:first-child,
    &:last-child {
      border-radius: 0 !important;
    }
  }
}
.ant-page-header.ant-page-header-ghost.peopleOrg {
  background-color: $white;
  border: 1px solid $primary_Light;
  padding: 5px 10px;
}
form.innerForm .ant-btn {
  &.addDocumentBtn {
    padding: 0.9rem 2rem !important;
    margin: 0 !important;
    font-family: 'VerdanaWF', Verdana, sans-serif;
    font-weight: normal;
    font-size: 1.1rem !important;
    width: 90%;
  }
}

span.uploadContract {
  width: 480px;
  //border: 1px dashed $primary_color;
  display: block;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  .ant-upload {
    font-size: 2.2rem;
  }
  .ant-upload-list-item {
    margin: 0;
  }
  &.square {
    height: 173px;
  }
  &.twoRow {
    height: 105px;
  }
}
.removeAddressSection {
  margin: 0 !important;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.addIcon {
  color: $primaryTextLight;
}
.ant-radio-group {
  label.ant-radio-button-wrapper {
    padding: 0.5rem 0.8rem !important;
    margin: 0;
    font-size: 1.2rem;
    height: auto !important;
    line-height: 2rem !important;

    &:first-child {
      border-radius: 5px 0 0 5px !important;
    }
    &:last-child {
      border-radius: 0 5px 5px 0 !important;
    }
    &:hover,
    &:focus,
    &:active {
      color: $primary_Dark;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $primaryTextDark !important;
    background: $primary_Light !important;
    border-color: $black-400 !important;
    &:before {
      background: $primary_Light !important;
    }
  }
}

.viewPeople {
  font-size: 1.2rem;
  * {
    word-break: break-word;
  }
  table {
    padding: 0;
    margin: -1rem 0 3rem;
    width: 100%;
    text-align: left;
    th,
    td {
      padding: 1rem 1rem;
      border: 1px solid $primary_Light;
      vertical-align: top;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $primaryTextLight;
    }
    th {
      background-color: $Offwhite;
      font-weight: 600;
      font-family: 'VerdanaBold', Verdana, sans-serif;
      color: $primaryTextDark;
    }
    td {
      background-color: $white;
      ol,
      ul {
        li {
          list-style-type: inherit;
        }
      }
      img {
        max-width: 100%;
      }
      li {
        list-style-type: inherit;
      }
      table {
        max-width: 100%;
      }
      div {
        white-space: break-spaces;
        display: block;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: block;
        margin: 10px 0;
        padding: 0;
        font-weight: bold;
        line-height: 110% !important;
      }
      h1 {
        font-size: 30px;
      }
      h2 {
        font-size: 25px;
      }
      h3 {
        font-size: 22px;
      }
      h4 {
        font-size: 20px;
      }
      h5 {
        font-size: 18px;
      }
      h6 {
        font-size: 16px;
      }
      pre {
        width: 100%;
        height: auto;
        overflow: visible;
        font-size: 16px;
        line-height: 18px;
        white-space: pre-wrap;
        margin: 10px 0;
        padding: 0;
      }
    }
    td.logo {
      vertical-align: middle !important;
      text-align: center;
      font-size: 40px !important;
    }
  }
}

form.innerForm.headerButton {
  .ant-form-item {
    margin: 0;
  }
  .ant-btn {
    margin: 0;
  }
  .ant-select .ant-select-selector {
    width: 100%;
    border-radius: 4rem;
    border-color: #e5e5e5;
    padding: 0.3rem 1rem !important;
  }
}
.goBackBtn.ant-btn.ant-btn-round {
  height: auto !important;
  line-height: 1.5rem !important;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem !important;
}

.relatedGrid.ant-page-header.ant-page-header-ghost {
  min-height: auto;
  height: auto;
  .ant-page-header-content {
    height: 250px;
    min-height: 250px;
    //overflow-y: auto;
  }
  .ag-body-viewport {
    overflow-y: auto;
    min-height: 130px !important;
    height: 130px;
  }
}

.bullet {
  margin: 0;
  padding: 0 0 0 18px;
  li {
    list-style: disc;
    margin: 0;
  }
}

.ant-select.ant-select-multiple.ant-select-show-search {
  // overflow-y: auto;
  .ant-select-selection-overflow-item.ant-select-selection-overflow-item-suffix {
    .ant-select-selection-search {
      width: 100% !important;
    }
  }
}
