.knowledgeView {
  font-size: 1.2rem;
  table {
    padding: 0;
    margin: -1rem 0 3rem;
    width: 100%;
    text-align: left;
    th,
    td {
      padding: 1rem 1rem;
      border: 1px solid $primary_Light;
      vertical-align: top;
      font-size: 1.2rem;
      line-height: 2rem;
      color: $primaryTextLight;
    }
    th {
      background-color: $Offwhite;
      font-weight: 600;
      font-family: 'VerdanaBold', Verdana, sans-serif;
      color: $primaryTextDark;
    }
    td {
      background-color: $white;
      img {
        max-width: 100%;
      }
      ol,
      ul {
        li {
          list-style-type: inherit;
        }
      }
      li {
        list-style-type: inherit;
      }
      table {
        max-width: 100%;
      }
      div {
        white-space: break-spaces;
        display: block;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: block;
        margin: 10px 0;
        padding: 0;
        font-weight: bold;
        line-height: 110% !important;
      }
      h1 {
        font-size: 30px;
      }
      h2 {
        font-size: 25px;
      }
      h3 {
        font-size: 22px;
      }
      h4 {
        font-size: 20px;
      }
      h5 {
        font-size: 18px;
      }
      h6 {
        font-size: 16px;
      }
      pre {
        width: 100%;
        height: auto;
        overflow: visible;
        font-size: 16px;
        line-height: 18px;
        white-space: pre-wrap;
        margin: 10px 0;
        padding: 0;
      }
    }
  }
}

.ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 485px;
  .ck-editor__editable_inline {
    min-height: 250px;
  }
}
.KnowledgeInfo {
  font-size: 1.8rem;
  color: $primaryTextLight;
}
