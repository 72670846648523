body li {
  list-style: none;
}
.colorPiker .ant-popover-content,
.colorPiker .ant-popover-content .ant-popover-inner-content {
  padding: 0;
  margin: 0;
}
.colorPiker .ant-popover-content .ant-popover-inner-content .colorBox {
  width: 260px !important;
  border: none !important;
}

/* .quick_notes div {
    padding: 5px;  
} */
