.TopNotificationBar {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $primarySeleteBG;
  padding: 0;
  .border-right {
    border-right: 1px solid $primary_Dark;
  }
  img.bar_icon {
    display: block;
    margin: 0;
    padding: 0;
    width: 2rem;
    height: 2rem;
    color: $primary_color;
  }

  strong.nos {
    font-size: 20px;
    line-height: 20px;
    font-weight: 900;
    width: 43px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    @media screen and (min-width: 1400px) {
      width: 46px;
    }
    &.gray {
      color: #414141;
    }
    &.blue {
      color: #3776ea;
    }
    &.red {
      color: #ff2828;
    }
  }
}
.SnapNoteModule {
  .ant-select-multiple .ant-select-selection-search {
    width: 100% !important;
    opacity: 1 !important;
    .ant-select-selection-search-input {
      width: 100% !important;
      opacity: 1 !important;
    }
  }
}
.personName {
  .ant-select-selector {
    display: block !important;
    overflow: hidden;
    width: 90% !important;
  }

  .ant-select-selection-overflow {
    display: block !important;
    height: 30px !important;
    .ant-select-selection-overflow-item {
      display: none;
      &.ant-select-selection-overflow-item-suffix {
        display: block;
        width: 100% !important;
        opacity: 1 !important;
        text-align: left;
        .ant-select-selection-search {
          width: 100% !important;
          opacity: 1 !important;
          input,
          .ant-select-selection-search-input {
            width: 100% !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }
}

form.innerForm {
  &.topSearchForm {
    position: relative;
    .Search_Icon {
      position: absolute;
      left: 10px;
      top: 13px;
      z-index: 1;
    }
    .ant-select {
      padding: 10px 10px 10px 60px !important;
      .ant-select-selector {
        padding: 0 !important;
        height: auto !important;
        overflow: hidden;
        width: 90% !important;
        .ant-select-selection-search {
          left: 0 !important;
          .ant-select-selection-search-input {
            padding: 0 !important;
            font-size: 1.6rem;
          }
        }
      }
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none !important;
      border: 0 !important;
      overflow: hidden;
      width: 90% !important;
    }
    .sectorSelector {
      margin: 25px 0 0;
      .ant-radio-wrapper {
        width: 32%;
        font-size: 1.2rem;
        margin: 0 0 10px;
        &:first-child {
            display: block; // to set all in full width seprate from other options
          }
      }
    }
  }
}

.ant-select-multiple .ant-select-selection-item {
  background-color: transparent;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $primarySeleteBG;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: $primaryTextDark;
}
.right__panel {
  .ant-card-small {
    //border: 2px solid $primary_Light;
    box-shadow: 0px 0px 5px rgba(152, 152, 152, 0.7);
    border-radius: 0.8rem;
    margin: 6% 5px 0;
    @media screen and (max-height: 930px) {
      margin: 3% 5px 0;
    }
    @media screen and (max-height: 768px) {
      margin: 1.5% 5px 0;
    }
    .ant-card-head {
      background-color: $primarySeleteBG;
      border-radius: 0.5rem 0.5rem 0 0;
      min-height: auto;
      .ant-card-head-title {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 400;
        color: $primaryTextDark;
        .titleIcon {
          width: 1.6rem;
          height: 1.6rem;
          display: block;
          margin: -0.5rem 0.5rem 0 0;
          color: $primary_color;
        }
        .TitleValue {
          background-color: $primary_color;
          color: $white;
          border-radius: 3rem;
          padding: 0.2rem 1rem;
          margin: 0 0.5rem;
          font-family: 'VerdanaBold', Verdana, sans-serif;
          font-weight: 600;
          font-size: 1rem;
        }
        .TitleValueBlink {
          background-color: $primary_color;
          color: $white;
          animation: blinkredblack 0.8s infinite alternate;
          border-radius: 3rem;
          padding: 0.2rem 1rem;
          margin: 0 0.5rem;
          font-family: 'VerdanaBold', Verdana, sans-serif;
          font-weight: 600;
          font-size: 1rem;
        }
      }
      .ant-card-head-title,
      .ant-card-extra {
        padding: 0.8rem 0;
      }
      @media screen and (max-width: 1400px) {
        .ant-card-head-title,
        .ant-card-extra {
          padding: 0.4rem 0;
        }
      }
    }

    .ant-card-body {
      padding: 0.5rem;
    }
  }
}

.SnapNoteModal {
  .ant-modal-content {
    .ant-modal-close {
      top: 0px !important;
      right: 15px;
      .ant-modal-close-x {
        width: 26px;
        height: 26px;
        img {
          width: 26px;
          height: 26px;
        }
      }
    }
    .headerTitle {
      display: flex;
      align-items: center;
      line-height: 1;
      img {
        height: 24px;
        width: 24px;
        margin-right: 5px;
      }
    }

    .innerForm.SnapNoteForm textarea {
      height: 60vh !important;
    }
    .ant-checkbox-group {
      padding: 0;
      background-color: #f4f4f7;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
      border: 1px solid var(--lite);
      border-radius: 10px;
      height: calc(60vh + 40px);
      overflow-y: auto;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
          list-style: none;
          margin: 0;
          padding: 0.2rem 0.8rem;
          background-color: transparent;
          border-bottom: 1px solid var(--lite);
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 3rem;
        }
      }
    }
    .footerBtn {
      width: 100%;
      .ant-space-item {
        &:last-child {
          flex: 1 0;
        }
        .iconList {
          .ant-checkbox {
            display: none;
          }
          .ant-checkbox + span {
            padding: 5px 6px !important;
            border: 1px solid transparent !important;
            border-radius: 5px !important;
          }
          .ant-checkbox-checked + span {
            background-color: $primarySeleteBG !important;
            border: 1px solid $primary_Dark !important;
            border-radius: 5px !important;
          }
        }
      }
    }
    .innerForm.SnapNoteForm button.SendNote {
      margin: 3px 0 !important;
    }
  }

  .ant-modal-body {
    padding: 1rem;

    .ant-card-small {
      border: 2px solid $primary_Light;
      border-radius: 0.8rem;
      margin: 0.3rem;

      .ant-card-head {
        background-color: $primary_Light;

        .ant-card-head-title {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          font-weight: 400;
          color: $primaryTextDark;

          .titleIcon {
            width: 1.8rem;
            height: 1.8rem;
            display: block;
            margin: -0.5rem 0.5rem 0 0;
            color: $primary_color;
          }

          .TitleValue {
            background-color: $primary_color;
            color: $white;
            border-radius: 3rem;
            padding: 0.2rem 1rem;
            margin: 0 0.5rem;
            font-family: 'VerdanaBold', Verdana, sans-serif;
            font-weight: 600;
            font-size: 1.2rem;
          }
        }
      }

      .ant-card-body {
        padding: 0.5rem;

        textarea.fixContent {
          height: 20rem !important;
        }
      }
    }
  }
}

.ant-select-item-option-content {
  font-size: 1.2rem !important;
}
.ant-select-selection-item {
  margin: 0;
  padding: 0;
}

.innerForm.SnapNoteForm {
  .ant-form-item {
    margin: 0;
  }
  button.SendNote {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 3rem;
    border: 1px solid $primary_Dark;
    background-color: $primary_Light;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    img {
      width: 2rem;
      height: 2rem;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
      background-color: $primaryTextLight;
    }
  }

  .ant-select {
    width: 100%;
    .ant-select-selector {
      overflow: hidden;
      width: 90%;
      padding: 0 8px !important;
      font-size: 1.2rem !important;
      height: 3rem !important;
      border: none;
      background-color: transparent;
      .ant-select-selection-search-input {
        padding: 0 !important;
      }
    }
  }

  textarea {
    width: 100%;
    margin: 0 0 1rem;
    border-radius: 0.3rem;
    border: 1px solid $primary_Light !important;
    &.userLive {
      color: $primaryTextDark;
      animation: blink 0.5s 10 alternate;
    }
    &.fullHeight {
      height: calc(100vh - 570px) !important;
      min-height: 171px;
      max-height: 250px;
    }
  }
  @keyframes blink {
    from {
      color: red;
    }
    to {
      color: #fff;
    }
  }

  @keyframes blinkredblack {
    0% {
      background-color: red;
      color: #fff;
    }
    60% {
      background-color: red;
      color: #fff;
    }
    90% {
      background-color: gray;
      color: #fff;
    }
    100% {
      background-color: gray;
      color: #fff;
    }
  }

  .iconList {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto 0.5rem;
    padding: 0;
    list-style: none;
    text-align: center;
    &.hourglassCheckbox {
      .ant-checkbox {
        display: none;
      }
      .ant-checkbox + span {
        padding: 5px 6px !important;
        border: 1px solid transparent !important;
        border-radius: 5px !important;
      }
      .ant-checkbox-checked + span {
        background-color: $primarySeleteBG !important;
        border: 1px solid $primary_Dark !important;
        border-radius: 5px !important;
      }
    }

    &.ant-checkbox-wrapper {
      &.ant-checkbox-wrapper-disabled {
        background-color: transparent;
        opacity: 0.4;
      }
      span {
        padding: 0 0 0 3px !important;
        margin: 0 !important;
        img {
          width: 20px;
          height: 20px;
          padding: 0;
        }
      }
    }
    .ant-radio-button-wrapper {
      display: inline-block;
      text-align: center;
      padding: 5px 6px !important;
      margin: 0 2px;
      border: 1px solid transparent;
      &.ant-radio-button-wrapper-disabled {
        background-color: transparent;
        opacity: 0.4;
      }
      img {
        width: 20px;
        height: 20px;
        padding: 0;
      }
      &::before {
        display: none;
      }
      &:first-child,
      &:last-child {
        border-radius: 0 !important;
      }
      &:hover,
      &:focus,
      &.ant-radio-button-wrapper-checked {
        background-color: $primarySeleteBG !important;
        border: 1px solid $primary_Dark !important;
        border-radius: 5px !important;
        &:focus-within {
          box-shadow: none !important;
        }
      }
    }
  }
}

textarea.fixContent {
  resize: none;
  height: 60px !important;
  @media screen and (max-width: 1400px) {
    height: 30px !important;
  }
}

.RigthBarBtn {
  margin: 5px 4px;
  padding: 5px 15px !important;
  border-radius: 4rem;
  width: auto;
  outline: none;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    outline: none;
    background-color: $primary_Dark;
    color: $white;
  }
  .icon {
    width: 16px;
    height: 16px;
  }
  @media screen and (max-width: 1400px) {
    padding: 5px 15px !important;
    width: auto;
  }
}

.RigthBar_Table {
  .ant-table-row {
    .ant-table-cell {
      padding: 0 !important;
      margin: 0;
      border-bottom: 1px solid $primary_Light !important;
      color: $primaryTextLight;
      font-size: 1.2rem;
      line-height: 2rem;
      a {
        color: $primaryTextLight;
        text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover,
        &:focus,
        &:active {
          color: $black;
        }
      }

      .ant-checkbox-wrapper {
        padding: 0;
      }

      &:nth-child(2) {
        color: $primaryTextDark;
        font-size: 1.2rem;
      }
    }

    &.ant-table-row-selected {
      .ant-table-cell {
        background-color: $primary_Light;
      }
    }
  }

  .ant-table-small .ant-table-selection-column {
    width: auto;
    min-width: auto;
  }
}
.ant-list-sm.right-list .ant-list-item {
  padding: 0.5rem 0.5rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  border-bottom: 1px solid $primary_Light !important;
  img {
    width: 1.8rem;
    height: 1.8rem;
    display: inline-block;
    margin: -0.3rem 0.5rem 0 0;
  }
  a {
    color: $primaryTextDark;
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }
}

.right__panel .ant-card-small.nopadding {
  .ant-card-body {
    padding: 0.5rem 0;
    overflow: hidden;
    border-radius: 0 0 0.5rem 0.5rem;
    @media screen and (max-width: 1400px) {
      padding: 0.5rem 0 0;
    }
  }
}

.ag-theme-alpine {
  &.fileFolderEdit {
    .ag-cell {
      &.cellClassDropDown {
        padding: 0 10px !important;
      }
      &.actionColumn {
        padding: 0 10px !important;
        img {
          width: 18px;
          height: 18px;
          margin: auto 10px;
        }
        .ant-switch {
          padding: 0.2rem !important;
          height: 19px;
          max-width: 30px !important;
          .ant-switch-handle {
            width: 14px;
            height: 14px;
          }
          &.ant-switch-checked {
            .ant-switch-handle {
              left: calc(100% - 15px - 2px);
            }
          }
        }
      }
    }
  }
}
.rightSectionFixHeight,
.ant-empty-normal {
  height: 55px;
  padding: 0;
  margin: 0;
  .ant-empty-image {
    margin: 0;
    svg {
      height: 80%;
      margin: 0 auto;
    }
  }
  .ant-empty-description {
    font-size: 70%;
  }
}

.topRightNotification {
  border-radius: 10px;
  .ant-popover-arrow {
    border-color: $Offwhite !important;
    //border-top-color: var(--lite) !important;
  }
  .ant-popover-inner {
    border-radius: 10px;
    .ant-popover-inner-content {
      padding: 0;
      background-color: $Offwhite;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
      border: 1px solid $Offwhite;
      border-radius: 10px;
      overflow: hidden;
      .ant-tabs {
        .ant-tabs-nav {
          margin: 0;
          padding: 0;
          background-color: $primary_Light;
          .ant-tabs-tab {
            background-color: $primary_Light;
            color: $primaryTextDark;
            padding: 5px 10px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            letter-spacing: -0.02em;
            img {
              display: inline-block;
              margin-right: 10px;
            }
            &.ant-tabs-tab-active {
              background-color: $primarySeleteBG;
              color: #fff;
              .ant-tabs-tab-btn {
                color: $primaryTextDark;
              }
            }
          }
          .ant-tabs-ink-bar {
            background-color: $primaryTextDark;
          }
        }
      }
      .popover-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        min-width: 350px;
        max-width: 350px;
        &.uploadFile {
          width: 450px;
          max-width: 450px;
          padding: 10px;
          font-size: 1.2rem;
        }

        li {
          list-style: none;
          margin: 0;
          padding: 0.2rem 0.8rem;
          background-color: transparent;
          border-bottom: 1px solid $primary_Light;
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 3rem;
          &.loadMore {
            padding: 0;
            margin: 0;
            span {
              text-align: center;
              padding: 0;
              margin: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $primary_color;
              color: #fff;
              cursor: pointer;
              &:hover,
              &:focus {
                background-color: $primaryTextDark;
              }
            }
          }

          img.menuicon {
            width: 1.8rem;
            height: 1.8rem;
            margin-right: 0.6rem;
            display: inline-block;
            position: relative;
            top: -0.3rem;
          }
          a {
            color: $primaryTextDark;
          }
          &:hover,
          &:focus {
            background-color: $primary_Dark;
            color: $white;
            a {
              color: $white;
            }
            ul.bullet li {
              color: $white;
              &::marker {
                color: $white;
              }
            }
          }
          &:last-child {
            border: none;
          }

          &.theme {
            display: flex;
            align-items: center;
            span {
              display: inline-block;
              width: 2.4rem;
              height: 2.4rem;
              border: 2px solid;
              margin: auto 0.3rem;
              &.blue {
                background-color: #97a6bf;
                border-color: #97a6bf;
              }
              &.pink {
                background-color: #bb9baf;
                border-color: #bb9baf;
              }
              &.gray {
                background-color: #a5a5a5;
                border-color: #a5a5a5;
              }
              &.yellow {
                background-color: #ddb95c;
                border-color: #ddb95c;
              }
              &.green {
                background-color: #a0c793;
                border-color: #a0c793;
              }
              &:hover,
              &:focus,
              &.active {
                border: 2px solid $black;
              }
            }
          }

          &.size {
            display: flex;
            align-items: center;
            span {
              display: inline-block;
              padding: 0 10px;
              font-weight: 900;
              font-family: 'VerdanaBold', Verdana, sans-serif;
              border: 2px solid transparent;
              line-height: 30px;
              &.small {
                font-size: 80%;
              }
              &.medium {
                font-size: 110%;
              }
              &.large {
                font-size: 130%;
              }
              &:hover,
              &:focus,
              &.active {
                background-color: $primary_Dark;
                color: $white;
                border: 2px solid $black;
              }
            }
          }
        }
        // &.nocheckbox {
        //    .ant-checkbox {
        //     display: none !important;
        //   }
        // }
        &.fixedHeight {
          max-height: 70vh;
          overflow-y: auto;
        }
      }
      .contactList {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          min-width: 100px;
          border-radius: 10px;
          li {
            text-align: left;
            font-size: 1.2rem;
            list-style: none;
            margin: 0;
            padding: 0.2rem 0.8rem;
            background-color: transparent;
            border-bottom: 1px solid $primary_Light;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 3rem;
            a {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0 10px;
              margin: -1px 0 0;
              color: $primaryTextDark;
              img {
                width: 16px;
                height: 16px;
                margin-right: 5px;
              }
            }
            &:hover,
            &:focus {
              background-color: $primary_Dark;
              color: $white;
              a {
                color: $white;
              }
            }
            &:last-child {
              border: none;
            }
          }
        }
      }
    }
  }
}

.profileMenu {
  &.snapNoteNoti {
    .ant-popover-inner .ant-popover-inner-content {
      max-height: 70vh;
      overflow-y: auto;

      li {
        &.loadMore {
          padding: 0;
          margin: 0;
          span {
            text-align: center;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary_color;
            color: #fff;
            cursor: pointer;
            &:hover,
            &:focus {
              background-color: $primaryTextDark;
            }
          }
        }
      }
    }
  }
}
