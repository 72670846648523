span.uploadContract {
  width: 100%;
  border: 1px dashed $primary_color;
  display: block;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-upload {
    font-size: 2.2rem;
  }
  .ant-upload-list-item {
    margin: 0;
  }
  button.ant-btn {
    padding: 0 !important;
    margin: auto;
  }
}
