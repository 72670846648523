.ant-form-item-control-input {
  min-height: auto;
}

input,
input.ant-input,
input[type='text'],
input[type='password'],
input[type='number'],
textarea,
button,
.ant-btn {
  background: #ffffff;
  border: 1px solid $primary_Dark;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 1.2rem !important;
  font-size: 1.5rem;
  line-height: 1.3;
  height: auto;
  color: $primaryTextDark;
  resize: none;
}

.noAppearance {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  &:focus,
  &:focus-within {
    outline: none;
    box-shadow: none;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: $primaryTextDark;
  background: $primary_Light;
  border: 1px solid $primary_Dark;
}

.ant-input-affix-wrapper {
  padding: 0 !important;
}

.ant-btn-primary {
  background-color: $primary_color;
  border-color: $primary_color;
  color: $white;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: $grayDark;
  border-color: $grayDark;
}

.ant-input-suffix,
.ant-input-prefix {
  margin: 0 16px;
  position: relative;
  z-index: 5;

  .anticon {
    font-size: 1.8rem;
  }
}

.ant-btn,
button,
html [type='button'],
[type='reset'],
[type='submit'],
.ant-btn-sm {
  padding: 0.8rem !important;
  font-size: 1.2rem !important;
  line-height: 1.5rem !important;
}

.ant-btn {
  font-size: 1.8rem;
}

.ant-picker-now-btn {
  color: $primaryTextDark;
}

form.innerForm {
  font-family: 'VerdanaWF', Verdana, sans-serif;

  input,
  .ant-input,
  textarea {
    //width: calc(100% - 2.5rem);
    width: 100%;
    height: auto;
    padding: 1rem 1rem !important;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $primaryTextDark;
    border: 1px solid $primaryInputBorder;
    border-radius: 5px;
    font-family: 'VerdanaWF', Verdana, sans-serif;
  }

  .ant-select {
    //width: calc(100% - 2.5rem);
    width: 100%;
    height: auto;
    padding: 0 !important;
    font-size: 1.2rem;
    line-height: 1.5rem;
    border: 1px solid $primaryInputBorder !important;
    border-radius: 5px;
    // background-color: $primarySeleteBG;
    overflow: hidden;

    .prefixIconDropdown {
      form.innerForm .ant-select .ant-select-selector {
        padding: 0.2rem 1.1rem !important;
        height: 3.5rem;
        border: none;
        border-radius: 5px;
        background-color: transparent;
        overflow: hidden;
        width: 100%;
        margin-left: 15px;
      }
    }

    .ant-select-selector {
      padding: 0.2rem 1.1rem !important;
      height: 3.5rem;
      border: none;
      border-radius: 5px;
      background-color: transparent;
      overflow: hidden;
      width: 100%;

      input {
        border: none;
        border-radius: 5px;
        background-color: transparent;
      }
    }

    &.autoHeight {
      height: auto;
      overflow: unset;

      .ant-select-selector {
        height: auto;

        .ant-select-selection-overflow {
          height: auto !important;
        }
      }
    }

    .ant-select-arrow {
      color: $primary_color;
    }
  }

  .ant-picker {
    //width: calc(100% - 2.5rem);
    width: 100%;
    height: 35px;
    padding: 0 !important;
    border: 1px solid $primaryInputBorder !important;
    border-radius: 5px;
    // background-color: $primarySeleteBG;

    .ant-picker-input {
      padding: 0 0.8rem 0 0 !important;

      input {
        padding: 1rem 1rem !important;
        font-size: 1.2rem;
        line-height: 1.5rem;
        border: none;
        border-radius: 5px;
        background-color: transparent;
      }

      .ant-picker-suffix {
        color: $primaryTextDark;
      }
    }

    .ant-picker-range-separator {
      padding: 0 1px;
    }
  }

  .ant-select-item {
    font-size: 0.8rem;
  }

  .ant-form-item {
    margin: 0 0 12px;
  }

  .ant-form-item.labelOnly {
    margin: 0;
  }

  .ant-form-item-label {
    margin: 0 0 3px;
    padding: 0;
    font-size: 1.2rem;
    line-height: 1;
    font-family: 'VerdanaWF', Verdana, sans-serif;
  }

  .ant-form-item-control-input-content .anticon-info-circle {
    margin: 0 0 0 0.5rem;
    font-size: 1.8rem;
    color: $primaryTextLight;
  }

  .ant-input-borderless {
    border: 0 none !important;
    padding: 1rem 0 !important;
  }

  .sortablelist {
    display: block;
    width: 100%;
    margin: 0 0 2rem 0;
    padding: 0;
    list-style: none;
    border: 1px solid $primary_Light;

    li {
      padding: 0.8rem 1rem;
      width: 100%;
      list-style: none !important;
    }
  }

  .ant-btn {
    padding: 1.2rem 2rem !important;
    margin: auto 0.8rem;

    &.ant-btn-sm {
      padding: 0.9rem 1.2rem !important;
    }

    &.noLable {
      margin-top: 18px;
    }

    &.ticketDeadline {
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      padding: 6px 10px !important;
      margin-left: 10px !important;

      .icon {
        width: 1.2rem;
        height: auto;
      }

    }
  }

  .ant-btn-round {
    border-radius: 32px !important;
  }

  .ant-form-item-extra {
    font-size: 1.2rem;
    line-height: 1.5;
    display: block;
    padding: 3px 0;
    color: #ff4d4f;
  }
}

form.innerForm.prefixIconDropdown {
  .ant-select .ant-select-arrow {
    color: var(--primary);
    right: 10px;
    left: 10px;
    top: 15px;
    bottom: 0px;
  }

  .ant-select .ant-select-selector {
    padding: 0.2rem 1.1rem 0.2rem 2.5rem !important;

    height: 3.5rem;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    overflow: hidden;
    width: 100%;
  }
}

.individual_info {
  margin: 0 0 0 10px;
  font-size: 18px;
  position: relative;
  top: 5px;
  color: $primaryTextLight;
}

.AddIcon-0 {
  display: inline-block !important;
  font-size: 18px;
  color: $primaryTextLight;
}

.tooltip-0 {
  display: inline-block !important;
  margin: 0 0 0 10px;
  font-size: 18px;
  position: relative;
  top: 5px;
  color: $primaryTextLight;
}

.RemoveIcon-0 {
  display: none !important;
}

.extraFromIcon {
  font-size: 18px;
  color: $primaryTextLight;
  margin: 0 0 13px;
}

.ant-btn.ant-btn-text {
  border: none !important;
  background-color: transparent;
}

.ant-btn.ant-btn-round {
  border-radius: 4rem !important;
}

.ant-checkbox-wrapper {
  padding: 0.5rem 0 0;

  .ant-checkbox {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $primary_Dark;
        border-color: $primary_Dark;
        accent-color: $primary_Dark;

        // width: 1.8rem;
        // height: 1.8rem;
        &:after {
          border: 1px solid $white;
          border-top: 0;
          border-left: 0;
        }
      }

      &:after {
        border-color: $primaryTextDark;
      }
    }

    &:hover,
    &:focus {
      .ant-checkbox-inner {
        border-color: $primaryTextDark;
      }
    }
  }
}

form.innerForm.searchForm {
  padding: 1rem 1rem 0;

  .ant-input {
    width: 100% !important;
    border: 1px solid $primary_Dark;
    border-right: none;
  }

  button {
    width: 100% !important;
    border: 1px solid $primary_Dark;
    border-radius: 0;
    background-color: $primary_Light;

    img {
      width: 1.9rem;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $primaryTextLight;
    }
  }
}

form.innerForm.headerButton,
.headerButton {
  .ant-form-item {
    margin: 0;
  }

  .ant-btn {
    padding: 0.8rem 2rem !important;
    margin: 0;
    font-family: 'VerdanaWF', Verdana, sans-serif;
    font-weight: 400 !important;
  }

  ::placeholder,
  .ant-select-selection-placeholder {
    opacity: 1;
    color: $primaryTextDark;
  }

  .ant-picker {
    width: 100%;
    border-radius: 4rem;
    border-color: $primary_Light;
    outline: none;

    &:hover,
    &:focus,
    &:active,
    &.ant-picker-focused {
      outline: none;
      box-shadow: none;
    }
  }

  .ant-select {
    width: calc(100% - 1rem);
    outline: none;

    .ant-select-selector {
      width: 100%;
      border-radius: 4rem;
      border-color: $primary_Light;
      padding: 0.3rem 1rem !important;
    }

    &:hover,
    &:focus,
    &:active,
    &.ant-select-focused {
      outline: none;
      box-shadow: none;
    }
  }
}

form.innerForm .ant-btn.ant-btn-round.ant-btn-sm {
  font-family: 'VerdanaWF', Verdana, sans-serif;
  font-size: 1.2rem !important;
  font-weight: 400;
  height: 30px;
  padding: 0 2rem !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin: 0 2px 0 0;
  color: #ff1010;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-btn.login {
  padding: 2rem !important;
  font-size: 1.8rem !important;
  width: 60%;
  margin: auto;
}

.ant-form-item-label {
  label {
    margin: 0;
    padding: 0;
    font-family: 'VerdanaWF', Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: $primaryTextDark;
  }
}

.addIcon {
  color: $primaryTextLight;
}

.ant-radio-group {
  label.ant-radio-button-wrapper {
    padding: 0.5rem 0.8rem !important;
    margin: 0;
    font-size: 1.2rem;
    height: auto !important;
    line-height: 2rem !important;

    &:first-child {
      border-radius: 5px 0 0 5px !important;
    }

    &:last-child {
      border-radius: 0 5px 5px 0 !important;
    }

    &:hover,
    &:focus,
    &:active {
      color: $primary_Dark;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: $primary_Dark !important;
    border-color: $primary_Dark !important;

    &:before {
      background: $primary_Dark !important;
    }
  }
}

.ant-form-item-has-error {
  input {
    border-color: #f00 !important;
  }

  .ant-form-item-explain-error {
    font-size: 1.2rem;
  }
}

.ant-form-item-label>label::after {
  display: none;
}

.inputNote {
  font-size: 11px;
  line-height: 14px;
  display: block;
  padding: 5px 5px 0;
}

form.innerForm .ant-input-number {
  width: 100%;
  font-size: 1.3rem;
  line-height: 1.5rem;
  color: $primaryTextDark;
  border: 1px solid $primaryInputBorder;
  border-radius: 5px;
  font-family: 'VerdanaWF', Verdana, sans-serif;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input {
    width: 100% !important;
    border: none;
  }
}

.ant-select.ant-select-multiple {
  overflow: hidden;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
  align-items: center;
  display: flex;
}

.formIcon {
  width: 22px;
  height: 22px;
}

// .ant-select-multiple .ant-select-selection-search-input,
// .ant-select-selection-search .ant-select-selection-search-input {
//   width: auto !important;
//   min-width: auto !important;
// }
.ant-form-item-control-input-content {
  align-items: center;
  display: flex;
}

.suffixIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ant-row.ant-form-item {
    flex: 1;
    margin-right: 10px;
    width: 80%;
  }
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: $primarySeleteBG;
  border: 1px solid #ff4d4f !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
  background: $primarySeleteBG !important;
  border-color: #d9d9d9 !important;
  text-shadow: none;
  box-shadow: none;
}

.ant-select-selection-overflow {
  height: 30px !important;
}

.titleCheckbox {
  margin: -2px 0 0 !important;
}

.ant-radio-checked::after,
.ant-radio-checked .ant-radio-inner {
  border: 1px solid $primary_color;

  &:after {
    background-color: $primary_color;
  }
}

.ant-radio-checked .ant-radio-inner::after {
  background-color: $primary_color;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: none;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none;
}

.hideErrorMessage {
  .ant-form-item-explain.ant-form-item-explain-error {
    display: none;
  }
}

.addEditReport {
  &.ant-form {
    .ant-checkbox-wrapper {
      margin: 0;
      padding: 0;
      font-family: 'VerdanaWF', Verdana, sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6rem;
      color: var(--textDark);
    }

    .ant-divider {
      .ant-divider-inner-text {
        font-size: 1.2rem;
        font-weight: 600;
        color: #999;
      }

      &.ant-divider-horizontal.ant-divider-with-text {
        border-top-color: $primaryInputBorder;
        border-top-width: 1px;
      }
    }
  }
}

.ant-select-dropdown {
  .innerForm {
    &.addOption {
      width: 95%;
      margin: auto;

      div {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
      }

      .ant-btn {
        &.ant-btn-primary {
          padding: 0.9rem !important;
          margin: 0 0 0 10px;
        }
      }
    }
  }
}

.address_list {
  position: relative;

  .addMore {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    z-index: 10;

    span {
      cursor: pointer;

      svg {
        cursor: pointer;

        path {
          fill: $primary_color;
          filter: brightness(80%);
        }
      }
    }
  }
}

form.innerForm .ant-select {
  &.addEditInputEle {
    width: 180px;

    &.editInputEle {
      width: 150px;
    }
  }
}

.autoHeight {
  height: auto !important;
  min-height: unset !important;
}

.slider-box {
  display: flex;
  align-items: center;
  justify-content: space-around;

  label {
    margin: 0;
    padding: 0;
    font-family: 'VerdanaWF', Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: $primaryTextDark;

    .labelInput {
      padding: 5px;
      padding-right: 0px;

      &:focus,
      &:focus-within {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.ant-picker-suffix {
  margin-right: 10px;
}

.ant-select-multiple .ant-select-selection-item-content {
  width: auto;
}

.ant-checkbox-group {
  &.relatedItemReport {
    label {
      margin-right: 10px !important;
    }
  }
}

.ant-form legend {
  display: block;
  width: 100%;
  margin: 5px 0 5px;
  padding: 0;
  color: $primaryTextDark;
  font-size: 1.4rem;
  line-height: inherit;
  border: none !important;

  strong {
    font-weight: normal;
    font-size: 1.2rem;
    font-family: Verdana, sans-serif;
    letter-spacing: 0.06em;
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $primary_Dark;
}

.fixedIcon {
  position: absolute;
  top: 4px;
  right: 8px;

  .ant-form-item {
    margin: 0;

    .anticon {
      margin: 0;
      padding: 0;

      &.ascDescIcon {
        margin: 0;
        padding: 0;

        svg {
          width: 1.5rem;
          height: 1.5rem;

          path {
            fill: $primary_Dark;

            &:nth-child(2) {
              fill: $primary_Dark;
            }

            &:nth-child(3) {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}

.ant-transfer-list-content-item.ant-transfer-list-content-item-disabled {
  .ant-transfer-list-content-item-remove {
    display: none !important;
  }
}

.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5 !important;
  cursor: pointer;
}

.inlineRadioBtn {
  flex-wrap: nowrap;
}

.three_col_transfer {
  .ant-transfer-list-content-item {
    flex: 0 0 50%;
    display: inline-block;
    width: 50%;
  }
}


.ant-form {
  &.innerForm {
    .ant-input-group {
      border: none;
      display: flex;
      gap: 10px;

      .ant-input-affix-wrapper {
        border: none;

        .ant-input {
          border-radius: 5px 0 0 5px;
        }

        .ant-input-suffix {
          height: auto;
          padding: 1rem 1rem;
          margin: 0;
          font-size: 1.2rem;
          line-height: 1.5rem;
          color: var(--textDark);
          border: 1px solid $primaryInputBorder;
          background-color: $primaryInputBorder;
          border-left: none;
          border-radius: 0 5px 5px 0;
          font-family: 'VerdanaWF', Verdana, sans-serif;
        }
      }
    }
  }
}

.NotesDataTime * {
  cursor: pointer;
}