.SearchSector {
  height: calc(100vh - 55px);
  background-color: $white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.28);
  margin-right: 10px;

  .title {
    background-color: $primary_Light;
    padding: 10px 15px;
    font-size: 15px;
    line-height: 18px;
    color: $primaryTextDark;
  }

  .list {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    height: calc(100vh - 9.5rem);
    overflow-y: auto;

    li {

      a,
      span.currentPage {
        margin: 13px 0;
        padding: 5px 15px;
        align-items: center;
        font-size: 1.2rem;
        line-height: 1;
        display: flex;
        justify-content: space-between;
        color: $primaryTextDark;
        display: flex;
        justify-content: space-between;

        &.active {
          font-family: 'VerdanaBold', Verdana, sans-serif;
          font-weight: 600;
        }
      }

      &.noLink {
        a.noLink {
          // cursor: default;
          color: $primaryTextDark;
          opacity: 0.4;
          cursor: not-allowed;
          font-family: 'VerdanaBold', Verdana, sans-serif;
          font-weight: 600;
        }
      }

      .disablesearch {
        margin: 13px 0;
        padding: 5px 15px;
        align-items: center;
        font-size: 1.2rem;
        line-height: 1;
        display: flex;
        justify-content: space-between;

        &.active {
          font-family: 'VerdanaBold', Verdana, sans-serif;
          font-weight: 600;
        }

        color: $primaryTextDark;
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}

.SearchResult {
  box-sizing: border-box;
  height: calc(100vh - 55px);
  background-color: $white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.28);
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 500px;

  .ant-page-header {
    &.SearchResult-PageHeader {
      .ant-page-header-heading {
        margin-top: -10px;

        .ant-page-header-heading-left {
          margin: 0;

          .ant-page-header-heading-title {
            margin-top: -15px;
          }

          .searchView {
            color: #9e9e9e;
            font-size: 1.2rem;
            margin: 0 !important;
          }
        }

        .ant-page-header-heading-extra {
          margin: -5px 0 0 0;
        }
      }
    }
  }

  .ant-page-header-heading-title {
    .commonHeader {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 10px;
    }
  }

  .ant-page-header-content {
    height: calc(100% - 45px);
  }


  .ant-typography {
    margin: 0;
  }

  .searchView {
    color: $black_500;
    font-size: 1.2rem;
    margin: 13px 0 10px;

    .sep {
      display: inline-block;
      padding: 0 10px;
    }

    a {
      color: $black_500;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1510px) {

    .ant-page-header.SearchResult-PageHeader .ant-page-header-heading {
      .ant-page-header-heading-left {
        width: 50%;

        .ant-page-header-heading-title {
          margin-top: -5px;

          .commonHeader {
            flex-wrap: wrap;
            margin: 0;

            .ant-typography {
              width: 100%;
              flex-basis: 100%;
            }

            .searchView {
              width: 100%;
              flex-basis: 100%;
              margin-top: -5px !important;

              .sep {
                &:first-child {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}