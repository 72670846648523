.HeaderComponent {
  background-color: $white;
  margin: -8px 0 0;
  padding: 2px 0;
  width: calc(100vw - 534px);

  //Highlights Component
  .Highlights {
    width: 100%;
    border: 0 none;
    font-size: 1.2rem;
    font-weight: 400;

    td {
      padding: 0 0;
      height: 24px;

      .ant-badge {
        font-size: 1.2rem;
        font-weight: 600;
        font-family: "VerdanaBold", Verdana, sans-serif;

        .ant-badge-count {
          color: $primaryTextDark;
          background-color: $primarySeleteBG;
          box-shadow: none;
          height: 22px;
          display: flex;
          align-items: center;
          border: 1px solid $primary_Light !important;
        }
      }
    }

    @media screen and (min-width: 1400px) {
      td {
        padding: 4px 0;
        height: 28px;
      }
    }
  }

  //Dropdown Component
  .DropdownComponent {
    .ant-form-item {
      margin: 1px 0;

      .ant-select {
        width: 100% !important;
        height: 31px;
        margin: 2px 0 3px !important;
        border-radius: 3px;
        border: none !important;
        font-size: 1.2rem;
        border: 1px solid $primary_Light !important;
      }
    }

    @media screen and (min-width: 1400px) {
      .ant-form-item {
        margin: 2px 0 0;

        .ant-select {
          height: 40px;
        }
      }
    }
  }

  //orgDetails
  .orgDetails {
    background-color: $primarySeleteBG;
    border: 1px solid $primary_Light;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 2px 0 0;

    #triangle-left {
      width: 0;
      height: 0;
      margin: auto;
      border-top: 20px solid transparent;
      border-right: 25px solid $primary_Light;
      border-bottom: 20px solid transparent;
      cursor: pointer;
      display: inline-block;

      &:hover,
      &:focus {
        border-right-color: $primary_Dark;
      }
    }

    #triangle-right {
      width: 0;
      height: 0;
      margin: auto;
      border-top: 20px solid transparent;
      border-left: 25px solid $primary_Light;
      border-bottom: 20px solid transparent;
      cursor: pointer;
      display: inline-block;

      &:hover,
      &:focus {
        border-left-color: $primary_Dark;
      }
    }

    .sep {
      width: 3px;
      height: 50px;
      background-color: $primary_Light;
      border-radius: 2px;
      margin: 10px 0 0;
      display: inline-block;
    }

    .orgBrife {
      ul {
        margin: 0;
        padding: 3px 0;
        list-style: none;
        width: 100%;

        li {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 1px 0 2px;
          color: $primaryTextDark;
          height: 21px;
          width: 100%;

          &.ticket-description {
            height: auto;
            align-items: flex-start;
            justify-content: flex-start;
            max-height: 80px;
            overflow: hidden;
            text-overflow: unset;
            white-space: unset;
          }

          span.title {
            display: inline-block;
            width: 108px;
            color: $primaryTextLight;
            font-style: normal;
            margin-right: 4px;

            &::after {
              content: ":";
              float: right;
            }
          }

          span,
          strong {
            cursor: pointer;
          }
        }
      }
    }

    .contactDetails {
      width: 100%;

      ul {
        width: 100%;
        margin: 0;
        padding: 0 0;
        list-style: none;
        font-size: 1.2rem;
        font-weight: 400;

        li {
          display: block;
          width: 49%;
          height: 21px;
          float: left;
          padding: 1px 4px;
          margin: 1px;

          .content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;

            a {
              color: $primaryTextDark;
            }

            .icon {
              width: 15px;
              height: 15px;
              margin: 0 5px 0 0;
              display: inline-block;
            }
          }

          &:nth-child(1),
          &:nth-child(2) {
            width: 100%;
          }

          &.bgShade {
            //background-color: $primary_Dark;
            background-color: rgba(0, 0, 0, 0.06);
            display: flex;
            align-items: center;
          }
        }
      }
    }

    @media screen and (min-width: 1400px) {
      padding: 4px 0;

      #triangle-left {
        margin: auto;
        border-top: 30px solid transparent;
        border-right: 35px solid $primary_Light;
        border-bottom: 30px solid transparent;
      }

      #triangle-right {
        margin: auto;
        border-top: 30px solid transparent;
        border-left: 35px solid $primary_Light;
        border-bottom: 30px solid transparent;
      }

      .sep {
        width: 3px;
        height: 100px;
        margin: 4px 0 0;
      }

      .orgBrife,
      .contactDetails {
        ul {
          padding: 0;
          margin: 0;
          width: 100%;

          li {
            padding: 0px 5px;
            margin: 1px;
            height: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .orgBrife {
        ul {
          width: 100%;
          overflow: hidden;

          li {
            width: 100%;
          }
        }
      }
    }
  }

  //ButtonsComponent
  ul.ButtonsComponent {
    width: 100%;
    padding: 1px 0 3px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      .ant-btn {
        background-color: $primary_Light;
        border: 1px solid $primary_Light !important;
        color: $primaryTextDark;
        font-size: 1.15rem !important;
        padding: 5px 5px !important;
        margin: 0 2px;
        height: 30px;
        text-align: center;

        .icon {
          width: 1.6rem;
          height: 1.6rem;
          // margin-right: 3px;
        }

        &.lightBG {
          background-color: $primarySeleteBG;
        }

        &.reminderNotifyIcon {
          position: relative;

          &:before {
            content: " ";
            display: block;
            width: 7px;
            height: 7px;
            background-color: var(--textDark);
            position: absolute;
            top: auto;
            bottom: 2px;
            left: auto;
            right: 2px;
            opacity: 1;
            z-index: 5;
          }
        }

        @media screen and (min-width: 1400px) {
          padding: 5px 6px !important;
        }

        @media screen and (min-width: 1600px) {
          padding: 5px 15px !important;
          // .icon {
          //   margin-right: 10px;
          // }
        }
      }

      &.Deadline {
        position: relative;

        .ant-picker {
          position: absolute;
          z-index: 5;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;

          input {
            cursor: pointer;
          }
        }
      }

      .StatusChange {
        .anticon {
          margin: 0;
          padding: 0 1px;
          font-size: 1.2rem;
          color: $primary_Dark;

          &.anticon-down-circle {
            font-size: 1.6rem;
          }

          &.anticon-left {
            padding-left: 4px;

            @media screen and (min-width: 1900px) {
              padding-left: 20px;
            }
          }
        }
      }

      flex: 1;

      &.search_input {
        flex: 30;
        margin: 0 2px;

        .searchBtn {
          .ant-input-group-addon {
            display: none;
          }

          .ant-input-affix-wrapper {
            //background-color: $primarySeleteBG;
            height: 30px;
            border: 1px solid $primary_Light !important;
            border-radius: 5px;

            .ant-input {
              background-color: transparent;
              padding: 0 0 0 1rem !important;
              margin: 0;
              font-size: 1.2rem;
              width: 100%;
            }

            .ant-input-suffix {
              margin: 0 0.5rem 0;

              .icon {
                width: 1.6rem;
                height: 1.6rem;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }

      &.btn_box {
        .ant-btn {
          @media screen and (min-width: 1900px) {
            .icon {
              margin: 0px auto;
            }
          }
        }
      }

      .ant-select {
        background-color: $primarySeleteBG;
        height: 30px;
        border: 1px solid $primary_Light !important;
        border-radius: 5px;

        .ant-select-selector {
          background-color: transparent;
          border: none;
          height: 100%;
        }

        .icon {
          width: 1.6rem !important;
          height: 1.6rem !important;
        }
      }

      &.QuickReminder {
        position: relative;

        .ant-picker {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
          cursor: pointer;
          opacity: 0;
        }
      }
    }

    @media screen and (min-width: 1400px) {
      padding: 4px 0;
    }
  }
}

.ant-select-item-option .ant-select-item-option-content {
  .icon {
    width: 1.6rem !important;
    height: 1.6rem !important;
  }
}

.Dealdesk_MainTab {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  // border-radius: 0 0 15px 15px;
  overflow: hidden;

  .ant-tabs-nav {
    margin: 0;
    font-size: 1.2rem;

    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        font-size: 1.2rem;
        font-family: "VerdanaBold", Verdana, sans-serif;
        font-weight: 600;
        margin: 0;
        padding: 4px auto 2px;
        width: 20%;
        background-color: $primary_Light;
        text-align: center;
        border-left: 2px solid $primary_Light;

        &:first-child {
          border: none;
        }

        .ant-tabs-tab-btn {
          display: inline-block;
          margin: auto;
          color: $primaryTextDark;
        }

        &.ant-tabs-tab-active,
        &:hover,
        &:focus {
          background-color: $primary_Dark;

          .ant-tabs-tab-btn {
            color: $white;
            text-shadow: none;
          }
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }
    }

    .ant-tabs-nav-operations {
      display: none;
    }
  }

  &.newTabUI {
    margin-top: 3px;

    .ant-tabs-nav {
      margin-top: 0px;
      background-color: #fff;
      border-bottom: 2px solid rgba(0, 0, 0, 0.2);

      .ant-tabs-nav-list {
        gap: 3px;
        align-items: flex-end;

        .ant-tabs-tab {
          padding: 5px;
          font-size: 1.1rem;
          line-height: 1.5;
          border-radius: 8px 8px 0 0;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;

          .ant-tabs-tab-btn {
            width: 85%;
            display: inline-block;
          }

          &:nth-child(4) {
            border-right: none;
            padding-left: unset;
            padding-right: unset;
          }
        }
      }

      .ant-tabs-nav-wrap {
        margin: 0px 4px;
      }
    }

    &.fiveColGap {
      .ant-tabs-nav {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            &:nth-child(5) {
              background-color: #fff;
              width: 8px;
              height: 30px;
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              border-radius: 0;
              padding: 0 !important;
              cursor: auto !important;
            }
          }
        }
      }
    }

    &.sixColGap {
      .ant-tabs-nav {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            &:nth-child(6) {
              background-color: #fff;
              width: 10px;
              height: 30px;
              box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              border-radius: 0;
              padding: 0 !important;
              cursor: auto !important;
            }
          }
        }
      }
    }
  }

  .ant-tabs-content-holder {
    padding: 0;

    .ant-tabs-content {
      padding: 6px 0 0;
      background-color: #ffffff;
      // border-radius: 0 0 15px 15px;
      // box-shadow: 0px 0px 5px rgba(102, 102, 102, 0.51) !important;
      font-size: 1.2rem;
      height: calc(100vh - 250px) !important;

      @media screen and (min-width: 1400px) {
        height: calc(100vh - 300px) !important;
      }

      overflow-y: auto;

      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }
}

.Dealdesk_SubTab {
  .ant-tabs-nav {
    margin: 0;
    font-size: 1.1rem;
    border-bottom: 0 !important;

    &::before {
      display: none;
    }

    .ant-tabs-nav-operations {
      display: none;
    }

    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        font-size: 1.1rem;
        font-family: "VerdanaWF", Verdana, sans-serif;
        font-weight: 400;
        margin: 0 1%;
        padding: 4px 10px;
        width: 23%;
        background-color: $white;
        text-align: center;
        border: 1px solid $primary_Light !important;
        border-radius: 20px 20px 0 0 !important;

        .ant-tabs-tab-btn {
          display: flex;
          justify-content: center;
          margin: auto;
          color: $primaryTextLight;
          width: 100%;
        }

        &.ant-tabs-tab-active,
        &:hover,
        &:focus {
          background-color: $primary_Dark;

          .ant-tabs-tab-btn {
            color: $white;
            text-shadow: none;
          }
        }

        &:first-child {
          border: 1px solid $primary_Light;
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }

  .ant-tabs-content-holder {
    padding: 0;

    .ant-tabs-content {
      padding: 6px 0px;
      background-color: #ffffff;
      font-size: 1.2rem;
      // height: calc(100vh - 270px) !important;
      height: calc(100vh - 340px) !important;
      overflow-y: auto;

      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }

  &.keyTab {
    .ant-tabs-tab {
      width: auto !important;
      padding: 4px 15px !important;
    }
  }
}

.hideContent {
  .ant-tabs-content-holder {
    display: none;
  }
}

.dealNoteEditor {
  padding: 7px 0 0;
}
#rc-tabs-0-panel-5 {
  position: relative;
  max-width: 100%;
  width: 100%;
}
.tabFooter {
  // box-shadow: 0 -3px 5px rgba(102, 102, 102, 0.51);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 3px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  position: fixed; /* Fix the footer at the bottom of the viewport */
  bottom: 16px; /* Align to the bottom of the screen */
  margin: 0 auto; /* Center horizontally */
  max-width: inherit; /* Inherit the parent width */
  width: 100%;
  z-index: 5;
  background-color: $white;
  border-radius: 0 0 15px 15px;

  .colorBox {
    display: inline-block;
  }

  .ant-btn {
    padding: 3px 6px !important;
    margin: auto 2px;

    &.ant-btn-sm {
      padding: 3px 3px !important;
      margin: auto 1px;
      width: 22px;
      height: 22px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px !important;

      .icon {
        width: 14px;
        height: 14px;
      }

      &.setReminderNoteFooter {
        position: relative;
        cursor: pointer;

        .ant-picker {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          opacity: 0;
          cursor: pointer;
        }
      }

      &.lightBG {
        background-color: $primarySeleteBG;
      }

      &.darkBG {
        background-color: #ffff00;
      }

      &.colorBtn {
        width: 22px;
        height: 22px;
        padding: 0 !important;
        border: 2px solid $primary_Light;
        margin: 2px 0px;
        border-radius: 0;
        text-indent: -1000px;
        overflow: hidden;

        &.x000000 {
          background-color: #000000;
        }

        &.x7f7f7f {
          background-color: #7f7f7f;
        }

        &.x880015 {
          background-color: #880015;
        }

        &.xed1c24 {
          background-color: #ed1c24;
        }

        &.xfc7f27 {
          background-color: #fc7f27;
        }

        &.xfff20a {
          background-color: #fff20a;
        }

        &.x22b04c {
          background-color: #22b04c;
        }

        &.x15a2e8 {
          background-color: #15a2e8;
        }

        &.x3f48cc {
          background-color: #3f48cc;
        }

        &.xa349a4 {
          background-color: #a349a4;
        }

        &.xffffff {
          background-color: #ffffff;
        }

        &.xc3c3c3 {
          background-color: #c3c3c3;
        }

        &.xb97a57 {
          background-color: #b97a57;
        }

        &.xfdaec9 {
          background-color: #fdaec9;
        }

        &.xfec90e {
          background-color: #fec90e;
        }

        &.xefe4b0 {
          background-color: #efe4b0;
        }

        &.xb5e61d {
          background-color: #b5e61d;
        }

        &.x99d9ea {
          background-color: #99d9ea;
        }

        &.x7092be {
          background-color: #7092be;
        }

        &.xc8bfe7 {
          background-color: #c8bfe7;
        }
      }
    }
  }

  &.displayTabs {
    .ant-btn {
      &.ant-btn-sm {
        &.colorBtn {
          margin: 2px 1px 0;
          border: 1px solid $primary_Dark;

          &.noColor {
            top: 1px;
            padding: 0;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .btnSectionBox {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .btnSectionBoxComment {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  @media screen and (min-width: 1400px) {
    .btnSectionBox {
      margin-right: 20px;
    }

    &.displayTabs {
      .btnSectionBox {
        margin-right: 0;
      }
    }

    .ant-btn {
      padding: 5px 15px !important;

      &.ant-btn-sm {
        width: 25px;
        height: 25px;

        .icon {
          width: 14px;
          height: 14px;
        }

        &.colorBtn {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media screen and (min-width: 1600px) {
    padding: 8px 8px;
    height: 45px;

    .btnSectionBox {
      margin-right: 50px;
    }

    .ant-btn {
      margin: auto 3px;

      &.ant-btn-sm {
        margin: auto 2px;

        &.colorBtn {
          margin: auto 3px;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.ExpandModal {
  overflow: hidden;

  .ant-modal-body {
    overflow-y: auto;
    padding: 10px 10px 18px;

    .Dealdesk_MainTab {
      box-shadow: none;
      border-radius: 0;

      .ant-tabs-content-holder {
        border: 1px solid $primary_Light;

        .displayBoxWithFooter {
          height: 100%;
        }
      }
    }

    .DisplayMsgList {
      //overflow-y: auto;
      //height: 80vh;
      position: relative;
      padding: 10px;
    }

    .tabFooter {
      border-radius: 0 0 1.5rem 1.5rem;
      bottom: 0;
    }
  }
}

.NotesDataTitle {
  font-size: 1.2rem;
  padding: 0 10px 5px;

  .NotesDataTime {
    position: relative;
    display: inline-flex;

    .ant-picker {
      position: absolute;
      opacity: 0;
      border: 1px solid #c00;
      height: 100%;

      .ant-picker-input {
        height: 100%;
      }
    }
  }
}

.inboundData {
  width: 98%;
  margin: 0 auto;
  padding: 0;
  list-style: none;

  li {
    font-size: 1.2rem;
    line-height: 1;
    padding: 0;
    margin: 0 0 -1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid $primary_Light;
    background-color: $white;

    &:nth-child(even) {
      background-color: $primarySeleteBG;
    }

    div {
      padding: 8px 10px;
      margin: -1px 0;
      float: left;

      &.id {
        width: 10%;
        border-right: 1px solid $primary_Light;
        text-align: center;
      }

      &.details {
        width: 80%;
        border-right: 1px solid $primary_Light;
        text-align: left;

        span {
          display: inline-block;
          width: 31%;
          margin-right: 1.5%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        div {
          display: inline-block;
          width: 100%;
          margin-right: 0;
          padding: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.action {
        width: 10%;
        text-align: center;

        img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }
  }
}

.ant-collapse.KeyNotes {
  width: 98%;
  margin: auto;

  .ant-collapse-item {
    background-color: $white;

    .ant-collapse-header {
      padding: 5px 35px 5px 25px;
      font-size: 1.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .anticon-right {
        left: 5px;
        top: 0;
        padding: 8px 0 0;
      }

      .ant-collapse-extra {
        position: absolute;
        right: 5px;
        top: 3px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .ant-collapse-content {
      border-top: none;

      .ant-collapse-content-box {
        padding: 5px 35px 5px 25px;
        font-size: 1.2rem;
      }
    }

    &:nth-child(even) {
      background-color: $primarySeleteBG;

      .ant-collapse-content {
        background-color: $primarySeleteBG;
      }
    }
  }
}

.RightScheduleList {
  width: 100%;
  // height: calc(93vh - 250px);
  overflow: hidden;
  // overflow-y: auto;
  margin: 0;

  .list {
    height: 100%;

    .header {
      border-bottom: 1px solid $primary_Dark;
      padding: 3px 5px 5px;
      height: 30px;
      font-size: 1.2rem;
      line-height: 1;

      img.icon {
        width: 20px;
        height: 20px;
        margin: 0 5px;
        cursor: pointer;

        &.circle {
          border-radius: 25px;
        }
      }

      .ant-checkbox-wrapper {
        padding: 0;
        margin: 0 0 0 10px;
      }

      .Calendar {
        width: 110px;
        height: 20px;
        display: inline-block;
        margin: 0 10px 0 0;
        position: relative;
        z-index: 2;

        .icon {
          margin-left: 10px;
        }

        .ant-picker {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          opacity: 0;
        }
      }

      .Status {
        span {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .content {
      overflow: hidden;
      overflow-y: auto;
      height: calc(100vh - 345px);
      // height: auto;
      padding: 3px 5px 5px;

      &.score_list {
        height: calc(100vh - 455px);
      }
    }
  }

  .ant-form-item {
    margin: 0;

    .note {
      margin: 0 10px;
      width: calc(100% - 20px);
      height: 80px;
    }

    &.ant-form-item-has-error {
      position: relative;

      .ant-form-item-explain-error {
        position: absolute;
        bottom: -5px;
        font-size: 12px;
        padding: 0px 15px;
        margin: 0;

        div[role="alert"] {
          background-color: #ff4d4f !important;
          color: #fff !important;
          padding: 0 5px;
          margin: 0 0 0 -5px;
        }
      }
    }
  }

  // textarea.note {
  //   margin: 5px 5px;
  //   padding: 10px !important;
  //   width: calc(100% - 10px);
  //   height: 18%;
  //   border-radius: 0;
  //   font-size: 1.2rem;
  //   resize: none;
  //   background-color: $white;
  //   @media screen and (min-width: 1400px) {
  //     height: 110px;
  //   }
  // }

  .footer {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    height: 45px !important;
    background-color: #ffffff;
    position: absolute;
    left: 3px;
    bottom: -47px;
    width: calc(100% - 6px);
    border-radius: 0 0 15px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-btn.ant-btn-round {
      padding: 6px 15px !important;
      // margin-top: 6px;
    }

    &.ScheduleComponentFooter {
      justify-content: space-between;
      padding: 0 10px;
      font-size: 1.2rem;

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        padding: 0;

        .ant-checkbox {
          top: 0;
        }

        span {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.box-RightScheduleList {
  width: 100%;
  // height: 50px;
  // background-color: $primarySeleteBG;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  margin-bottom: 3px;

  tr,
  td {
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
  }

  td {
    height: 32px;
  }

  .draggerTD {
    border-right: 5px solid #fff;
    background-color: #fff;
  }

  th {
    color: $primaryTextDark;
  }

  .ant-row.ant-form-item {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
  }

  .dayTD {
    background-color: $primary_Light;
    // width: 10%;
    color: $primaryTextDark;
  }

  .days {
    width: 100%;
    height: 100%;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    //border-right: 5px solid $white;
    border-radius: 0;
    background-color: $primary_Light;
    margin: 0;

    input {
      margin: 0 !important;
      padding: 0 5px !important;
      text-align: center;
      font-size: 1.2rem;
      line-height: 1.5;
      color: $primaryTextDark;
      // font-family: 'VerdanaBold', Verdana, sans-serif;
      // font-weight: 600;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active,
    &.ant-input-number-focused {
      outline: none !important;
      border: 0 0 !important;
      box-shadow: none !important;
    }
  }

  .numTD {
    background-color: $primary_Light;
    border-right: 4px solid #fff;
    // width: 15%;
    color: $primaryTextDark;
  }

  .num {
    width: 100%;
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center !important;
    font-size: 1.08rem;
    border-radius: 0;
    border: none;
    background-color: $primary_Light;

    &:hover,
    &:focus,
    &:focus-within,
    &:active,
    &.ant-input-focused {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .numTD,
  .dayTD {
    .ant-input-number {
      border: none !important;
    }
  }

  .data {
    &.dataTD {
      padding: 0 5px;
      color: $primaryTextDark;
    }

    background-color: $primarySeleteBG;
    // width: 70%;
    padding: 3px 0;

    .ant-row.ant-form-item {
      margin: 0 !important;
      padding: 0 !important;
      height: auto !important;
    }

    .text {
      width: 100%;
      height: 18px;
      min-height: 18px;
      resize: none;
      padding: 0 5px !important;
      margin: 0 0 0 !important;
      color: $primaryTextDark !important;
      font-size: 1.2rem;
      line-height: 1;
      border-radius: 0;
      border: none;
      background-color: transparent;

      &:hover,
      &:focus,
      &:focus-within,
      &:active,
      &.ant-input-focused {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }

    // input {
    //   margin: 0 !important;
    //   padding: 0 5px !important;
    //   width: 100%;
    //   text-align: center;
    //   color: $primaryTextDark;
    // }
  }

  .date {
    &.dateTD {
      color: $primaryTextDark;
    }

    background-color: $primarySeleteBG;
    // width: 15%;
    height: 15px;
    text-align: center;
    padding: 0 3px;
    font-size: 1.2rem;
    line-height: 15px;
    color: $primaryTextDark;

    .ant-picker.ant-picker-borderless {
      height: 0px !important;
      border: none !important;
    }

    .ant-row.ant-form-item {
      margin: 0 !important;
      padding: 0 !important;
      height: auto !important;

      .ant-col.ant-form-item-control {
        margin: 0 !important;
        padding: 0 !important;
        height: auto !important;
        width: auto !important;
        display: block !important;

        .ant-form-item-control-input {
          margin: 0 !important;
          padding: 0 !important;
          height: auto !important;
          width: auto !important;
          display: block !important;

          .ant-form-item-control-input-content {
            display: block !important;
            text-align: right !important;
          }
        }
      }
    }

    .ant-picker {
      height: 15px;
      text-align: right;
      padding: 0 !important;
      color: $primaryTextDark;

      .ant-picker-input {
        input {
          height: 15px;
          text-align: right;
          padding: 0 2px !important;
          font-size: 12px;
          line-height: 15px;
          color: $primaryTextDark;
        }

        .ant-picker-suffix {
          margin: 0;
          padding: 0;
          display: none;
        }
      }
    }
  }

  th.actionTD {
    color: $primaryTextDark;
  }

  .actionTD {
    // width: 10%;
    img {
      width: 18px;
      height: 18px;
    }
  }

  &.header {
    width: 100%;
    margin: 0 0 5px 0 !important;
    padding: 0 !important;
    height: 30px !important;
    overflow: hidden;
    border-bottom: none !important;

    .drag {
      width: 20px;
      margin: 0 5px 0 0 !important;
    }

    .days,
    .num,
    .text,
    .date {
      width: 35px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.1rem;
      font-weight: bold;
      color: $primaryTextDark;
    }

    .days {
      width: 38px;
    }

    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        width: calc(100% - 130px);
        text-align: left;
        justify-content: flex-start;
        font-size: 1.13rem;
      }

      .date {
        width: 100px;
        font-size: 1.13rem;
        padding-right: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }

  &.scoreLibrary {
    border: none !important;

    th.header {
      background-color: $primarySeleteBG;
      color: $primaryTextDark;
    }

    tr {
      border: none;
      border-bottom: 3px solid #fff;
    }

    td {
      background-color: $primarySeleteBG;

      .scoreLibrary {
        tr:last-child {
          td {
            border: none;
          }
        }
      }

      &.draggerTD {
        border: none;
        text-align: center;
      }

      &.dropDown {
        padding: 0 2px;

        // border: none;
        .days {
          width: 100%;
          height: 100%;
          text-align: center;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border: none !important;
          border-radius: 0;
          background-color: $primary_Light;
          margin: 0;

          &.ant-select {
            .ant-select-selector {
              .ant-select-selection-item {
                color: $primaryTextDark;
              }
            }

            .ant-select-arrow {
              svg {
                fill: $primaryTextDark;

                path {
                  fill: $primaryTextDark;
                }
              }
            }
          }
        }
      }

      &.data {
        padding: 0 2px;

        // border: none;
        .ant-input,
        .ant-input-number {
          width: 100%;
          height: 100%;
          border-radius: 0;
          background-color: $primary_Light;
          border: none !important;
          padding: 0 !important;
        }

        textarea {
          &.ant-input {
            border: none !important;
            border-radius: 0;
            background-color: transparent;
          }
        }
      }

      &.actionTD {
        padding-right: 5px;
      }

      &.childTable {
        .fields_draggable_container {
          .fields_draggable_wrapper {
            .box-RightScheduleList.scoreLibrary {
              .draggerTD {
                padding-left: 0px;
                text-align: right;

                .dragger {
                  width: 20px;
                  margin: auto 0 auto auto;
                  padding-right: 27px;
                }
              }

              tr {
                border: none;
                border-top: 3px solid #fff;
              }
            }

            &:first-child {
              .box-RightScheduleList.scoreLibrary {
                tr {
                  border: none;
                  // border-top: 3px solid #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.box-FootScoreBox {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.5;
  color: $primaryTextDark;
  border: 1px solid $primaryInputBorder;

  tr {
    background-color: $primarySeleteBG;
    border-bottom: 1px solid $primaryInputBorder;

    td {
      padding: 5px 0;

      &.label {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.5;
        color: $primaryTextDark;
      }

      &.data {
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.5;
        color: $primaryTextDark;
        text-align: right;
      }
    }
  }
}

.ScheduleDrawerTable {
  table {
    color: $primaryTextDark;
    font-size: 1.2rem;

    .ant-table-thead > tr > th {
      background-color: $primarySeleteBG;
      color: $primaryTextDark;
      font-family: "VerdanaBold", Verdana, sans-serif;
      font-weight: 600;
    }

    tr {
      &:nth-child(even) {
        background-color: $primarySeleteBG;
      }

      td {
        .icon {
          width: 18px;
          height: 18px;
          margin: auto 5px;
          cursor: pointer;
        }
      }
    }
  }
}

.ScheduleLibraryListing {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0 0 5px;
    padding: 0;
    list-style: none;
    height: 55px;
    background-color: $primarySeleteBG;
    font-size: 1.2rem;
    display: flex;

    .days,
    .task {
      background-color: $primary_Dark;
      color: $primaryTextDark;
      width: 40px;
      height: 55px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-family: "VerdanaBold", Verdana, sans-serif;
      font-weight: 600;
      font-size: 1.2rem;
      border-right: 5px solid #fff;
    }

    .task {
      width: 60px;
      font-family: "VerdanaWF", Verdana, sans-serif;
      font-weight: 400;
    }

    .details {
      width: calc(100% - 45px);
      height: 55px;
      overflow: hidden;
      padding: 3px 5px 0;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;

      .text {
        height: 100%;
        width: 100%;
        overflow: hidden;
        line-height: 1.3;

        strong {
          display: inline-block;
          min-width: 40px;
          background-color: $primary_Light;
          padding: 2px 5px;
          margin-right: 5px;
        }
      }

      .date {
        width: 100px;
        height: 100%;
        text-align: right;
        padding: 0px 0px 3px;
        font-weight: 600;
      }
    }
  }
}

.structureRight {
  .ant-form-item {
    margin: 0;

    .note {
      margin: 0 10px;
      width: calc(100% - 20px);

      height: 80px;
    }

    &.ant-form-item-has-error {
      position: relative;

      .ant-form-item-explain-error {
        position: absolute;
        bottom: -5px;
        font-size: 12px;
        padding: 0px 15px;
        margin: 0;

        div[role="alert"] {
          background-color: #ff4d4f !important;
          color: #fff !important;
          padding: 0 5px;
          margin: 0 0 0 -5px;
        }
      }
    }
  }

  .header {
    border-bottom: 1px solid $primary_Dark;
    padding: 3px 5px 5px;
    height: 30px;
    display: flex;
    align-items: center;

    img.icon {
      width: 20px;
      height: 20px;
      margin: 0 5px;
      cursor: pointer;

      &.circle {
        border-radius: 25px;
      }
    }

    .ant-checkbox-wrapper {
      padding: 0;
      margin: 0 0 0 10px;
    }
  }

  .list {
    // height: calc(100vh - 37rem);
    height: calc(100vh - 352px);
    overflow-y: auto;
    padding: 0 10px 0;
    margin: 0 0 10px;

    &.fullHeight {
      height: 100%;
    }

    @media screen and (max-width: 1400px) {
      height: calc(100vh - 37rem);
    }

    .fields_draggable_wrapper {
      // .actionIcons {
      //   width: 50px;
      //   flex: 0 0 50px;
      //   // flex-direction: column;
      //   display: flex;
      //   align-items: center;
      //   justify-content: flex-end;
      //   img.icon.circle.cursorPointer {
      //     margin: 10px 8px !important;
      //     box-shadow: none;
      //     &:last-child {
      //       margin: 10px 0 !important;
      //     }
      //   }
      // }
      .ant-row.ant-form-item {
        margin: 0 0 10px;

        .number {
          width: 45px;
          height: 82px;
          border-radius: 0;
          border: 0 !important;
          font-size: 1.6rem;
          display: flex !important;
          justify-content: center;
          align-items: center;
          background-color: $primary_Light;
          color: $primaryTextDark;
          text-align: center;
          margin: 0 5px 0 0;
          box-shadow: none;

          .ant-input-number-handler-wrap {
            display: none;
          }

          .ant-input-number-input {
            text-align: center;
          }
        }

        .detail {
          height: 62px;
          border-radius: 0;
          border: 0 !important;
          font-size: 1.2rem;
          background-color: $primarySeleteBG;
          color: $primaryTextDark;
          box-shadow: none;
        }
      }
    }
  }

  .footer {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    height: 45px !important;
    background-color: #ffffff;
    position: absolute;
    left: 3px;
    bottom: -47px;
    width: calc(100% - 6px);
    border-radius: 0 0 15px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ant-btn.ant-btn-round {
      padding: 6px 15px !important;
      // margin-top: 3px;
    }

    &.StructureComponentFooter {
      justify-content: space-between;
      padding: 0 10px;
      font-size: 1.2rem;

      .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        padding: 0;

        .ant-checkbox {
          top: 0;
        }

        span {
          font-size: 1.2rem;
        }
      }
    }
  }

  &.noStyle {
    .list .fields_draggable_wrapper {
      .ant-row.ant-form-item {
        .number {
          width: 40px;
          height: auto;
          font-size: 1.2rem !important;
          line-height: 1.4rem !important;
          background-color: transparent;
          text-align: left;
          display: block !important;
          justify-content: unset;
          align-items: unset;

          input {
            text-align: left;
            padding: 0 !important;
            height: auto;
            font-size: 1.2rem !important;
            line-height: 1.4rem !important;
          }
        }

        .detail {
          background-color: transparent;
          padding: 0 !important;
          height: auto;
          min-height: unset;
          line-height: 1.3;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.displayBoxWithFooter {
  position: relative;
  width: 100%;
  max-width: 100%;
  //overflow-y: auto;
}
#rc-tabs-0-panel-4 #displayBoxWithFooterId {
  position: relative;
  width: 100%;
  max-width: 100%;
}

.DisplayMsgList {
  display: block;
  //padding: 5px 10px;
  padding: 5px 10px 10px 10px;

  .deletedNote {
    padding: 5px 0;
    margin-top: -10px;
    opacity: 0.6;
    text-align: center;
    position: relative;
    cursor: default;

    .text {
      position: relative;
      z-index: 5;
      width: auto;
      margin: auto;
      white-space: nowrap;
      text-align: center;

      span {
        background-color: #fff;
        display: inline-block;
        padding: 0 8px;
        > span {
          padding: 0;
        }
      }
    }

    &::before {
      width: 100%;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 0;
      content: "";
      border-bottom: dashed 1px $primaryTextDark;
    }
  }

  .msgBox {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    background-color: $primarySeleteBG;
    padding: 10px;
    margin: 0 0 10px;
    font-size: 1.2rem;
    line-height: 1.3;
    cursor: auto;
    // overflow-wrap: break-word;
    position: relative;

    .approvedTagNoteDesc {
      padding: 5px 0;
      margin-top: 0px;
      text-align: center;
      position: relative;

      .centerPart {
        position: relative;
        z-index: 5;
        width: auto;
        margin: auto;
        white-space: nowrap;
        text-align: center;
      }

      span {
        background-color: $primaryTextDark;
        display: inline-block;
        padding: 0 8px;
        color: #fff;
      }

      &::before {
        width: 100%;
        height: 3px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 0;
        content: "";
        border-bottom: dashed 1px $primaryTextDark;
      }
    }

    .selectedFilesList {
      position: relative;
      bottom: auto;
      background-color: transparent;
      border: none;
      width: auto;
      max-width: unset;
    }

    ol.structure {
      margin: 0;
      padding: 0 0 0 15px;

      // list-style-type: none !important;
      li {
        margin: 0 0 5px;
        padding: 0;
        list-style-type: none !important;

        &.textInCol {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;

          .listNo {
            width: auto;
          }

          .data {
            width: auto;
            flex: 1 1 0;
            padding-left: 0px;
          }
        }
      }
    }

    ol.structure li > ol {
      margin: 5px 0 -4px;
      padding: 0 0 0 22px;
      width: 100%;

      li {
        margin: 0 0 5px;
        padding: 0;

        &.textInCol {
          .data {
            padding-left: 3px;
          }
        }
      }

      li > ol {
        padding: 0 0 0 30px;
      }
    }

    &.outgoing {
      border-bottom-right-radius: 0;
      margin-left: 30px;
    }

    &.incoming {
      border-bottom-left-radius: 0;
      margin-right: 30px;
      background-color: $primary_Light;
    }

    &.comments {
      margin: 0 auto 10px;
      width: 90%;
      background-color: $primaryComment;
    }

    &.conversation {
      margin: 0 auto 10px;
      width: 90%;
      background-color: $primaryConversation;
    }

    &.x000000 {
      background-color: #000000;
      color: #ffffff;
    }

    &.x7f7f7f {
      background-color: #7f7f7f;
    }

    &.x880015 {
      background-color: #880015;
      color: #ffffff;
    }

    &.xed1c24 {
      background-color: #ed1c24;
      color: #ffffff;

      span.highlight-red {
        background-color: #fff;
      }
    }

    &.xfc7f27 {
      background-color: #fc7f27;

      span.highlight-orange {
        background-color: #fff;
      }
    }

    &.xfff20a {
      background-color: #fff20a;
    }

    &.x22b04c {
      background-color: #22b04c;

      span.highlight-green {
        background-color: #fff;
      }
    }

    &.x15a2e8 {
      background-color: #15a2e8;

      span.highlight-blue {
        background-color: #fff;
      }
    }

    &.x3f48cc {
      background-color: #3f48cc;
      color: #ffffff;
    }

    &.xa349a4 {
      background-color: #a349a4;

      span.highlight-purple {
        background-color: #fff;
      }
    }

    &.xffffff {
      background-color: #ffffff;
    }

    &.xc3c3c3 {
      background-color: #c3c3c3;
    }

    &.xb97a57 {
      background-color: #b97a57;
    }

    &.xfdaec9 {
      background-color: #fdaec9;
    }

    &.xfec90e {
      background-color: #fec90e;
    }

    &.xefe4b0 {
      background-color: #efe4b0;
    }

    &.xb5e61d {
      background-color: #b5e61d;
    }

    &.x99d9ea {
      background-color: #99d9ea;
    }

    &.x7092be {
      background-color: #7092be;

      span.highlight-purple {
        background-color: #fff;
      }

      span.highlight-blue {
        background-color: #fff;
      }
    }

    &.xc8bfe7 {
      background-color: #c8bfe7;
    }

    &.hidden {
      display: none;
    }

    &.active {
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
      border-color: $primaryTextDark;
    }

    .header {
      width: 100%;
      height: auto;
      padding: 0;
      margin: 0 0 3px;
      border: none;
      position: relative;

      .details {
        display: inline-block;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // max-width: 28%;
        margin: 0 10px 0 0;

        &.detailsNoteCreated {
          font-size: 1rem;
        }
      }

      .icon {
        width: 34px;
        height: 24px;
        float: right;
        padding: 3px 8px;
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }

      .dealdeskNoteNo {
        //background-color: rgba(0, 0, 0, 0.2);
        color: $primaryTextDark;
        border-radius: 30px;
        padding: 4px 12px;
        font-size: 1.2rem;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        .ticketIcon {
          width: 14px;
          height: 14px;
          margin: -5px 6px 0 0;
          display: inline-block;
          cursor: pointer;
        }

        a {
          color: $primaryTextDark;
        }
      }
    }

    .msgContent {
      font-size: 1.2rem;
      line-height: 1.5;
      width: 100%;
      height: auto;
      // min-height: 30px;
      // max-height: 300px;
      // overflow-y: auto;
      overflow: visible;
      word-break: break-word;

      > * {
        float: none !important;
        word-break: break-word;
      }

      // * {
      //   word-break: break-word;
      // }

      img {
        max-width: 100%;
      }

      ol,
      ul {
        li {
          list-style-type: inherit;
        }
      }

      li {
        list-style-type: inherit;
      }

      table {
        max-width: 100%;

        * {
          word-break: break-word;
        }
      }

      div {
        // white-space: break-spaces;
        display: block;
        margin-bottom: 5px;
      }

      .content {
        &.conversation-quote {
          white-space: unset;
          word-break: break-word;
          padding: 0 20px;
          margin: 10px 0 0;

          p {
            &:last-child,
            &:last-of-type {
              margin: 0;
              padding: 0;
            }
          }
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: block;
        margin: 10px 0;
        padding: 0;
        font-weight: bold;
        line-height: 110% !important;
      }

      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: 25px;
      }

      h3 {
        font-size: 22px;
      }

      h4 {
        font-size: 20px;
      }

      h5 {
        font-size: 18px;
      }

      h6 {
        font-size: 16px;
      }

      pre {
        width: 100%;
        height: auto;
        overflow: visible;
        font-size: 16px;
        line-height: 20px;
        white-space: pre-wrap;
        margin: 10px 0;
        padding: 0;
      }
    }

    .footer {
      width: 100%;
      text-align: right;
      margin: 5px 0 0;
      font-size: 1.2rem;

      .icon {
        width: 18px;
        height: 18px;
        margin: auto 3px;
      }

      .tags {
        background-color: $white;
        border: 1px solid $primaryTextDark;
        border-radius: 15px;
        padding: 5px 10px 5px 5px;
        display: inline-flex;
        align-items: center;
        margin: 10px 5px 2px 5px;
        color: $primaryTextDark;
        font-size: 1.2rem;
        cursor: pointer;
      }

      .lock {
        background-color: $white;
        border: 1px solid $primaryTextDark;
        border-radius: 15px;
        padding: 5px 8px;
        display: inline-flex;
        align-items: center;
      }
    }
  }
}

//@import url('https://fonts.googleapis.com/css2?family=Montagu+Slab&display=swap');

.quoteIcon {
  position: relative;
  padding: 20px 10px 20px 20px;

  &::before {
    position: absolute;
    top: 18px;
    left: -5px;
    content: "“";
    font-family: "MontaguSlab", "Montagu Slab", "Times New Roman", Times, serif;
    font-size: 40px;
    letter-spacing: 0.03em;
    line-height: 0;
  }

  &::after {
    position: absolute;
    bottom: -15px;
    right: -5px;
    content: "”";
    font-family: "MontaguSlab", "Montagu Slab", "Times New Roman", Times, serif;
    font-size: 40px;
    letter-spacing: 0.03em;
    line-height: 0;
  }
}

.tox .tox-statusbar {
  height: 35px !important;

  .tox-statusbar__branding {
    display: none;
  }

  a,
  .tox-statusbar__path-item,
  .tox-statusbar__wordcount {
    padding: 3px !important;
  }
}

form.innerForm.DealDeskDeal {
  padding: 0 20px;
  font-size: 1.2rem;
  max-height: calc(100vh - 300px);
  overflow-y: auto;

  input,
  .ant-input,
  .ant-select,
  textarea {
    width: 100% !important;
  }

  .lableBold {
    label {
      font-family: "VerdanaBold", Verdana, sans-serif;
      font-weight: 600;
    }
  }

  .ant-form-item-label {
    label.ant-checkbox-wrapper {
      margin-top: 20px;

      .ant-checkbox + span {
        padding-top: 5px;
      }
    }

    &.ant-form-item-required {
      label.ant-checkbox-wrapper {
        &:after {
          display: inline-block;
          margin: 0 4px 0 -5px;
          color: #ff1010;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: "*";
          width: 10px;
        }
      }
    }
  }

  .ant-select-selector {
    span.ant-select-selection-item {
      display: block;
      width: 100px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .pdfView {
    width: 100%;
    height: calc(100vh - 375px);
  }

  .selectType {
    width: 100%;
    display: flex;
    flex: 1 0;
    margin: 0 0 10px;

    label.ant-checkbox-wrapper.ant-checkbox-group-item {
      flex: 1 0;
      margin-right: 15px;
      padding: 6px 10px;
      font-size: 1.2rem;
      background-color: $primarySeleteBG;
      color: $primaryTextDark;
      border: 1px solid $primaryInputBorder;
      border-radius: 5px;

      &:last-child {
        margin-right: 0;
      }

      &.ant-checkbox-wrapper-checked {
        background-color: $primary_Light;
      }
    }
  }
}

.DealView {
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 355px);

  .ViewPDF {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;

    table.mainTable {
      width: 100%;
      max-width: 600px;
      min-width: 300px;
    }

    table {
      width: 100%;
      border: 0;
      font-size: 13px;
      color: $black;
      margin: 2px auto 3px;

      &.grayBox {
        font-size: 12px;
        margin-bottom: 10px;

        td,
        th {
          border: 8px solid #999999;
          padding: 3px 5px;

          ol {
            margin: 10px 0;
            padding: 0 0 0 30px;

            li {
              list-style-type: auto;
              margin: 0 0 30px;
              font-size: 15px;
              line-height: 1.8;
            }
          }
        }

        th {
          background-color: #999999;
          color: #fff;
          text-align: center;
          font-size: 16px;
        }
      }

      &.font11 {
        td {
          font-size: 11px;
          line-height: 1.5;
        }

        &.pa3 {
          td {
            padding: 3px;
          }
        }

        &.pa5 {
          td {
            padding: 5px;
          }
        }
      }

      td {
        border: 0;
        font-size: 13px;
        color: $black;
        padding: 0;

        &.address,
        &.font11 {
          font-size: 11px;
          line-height: 1.5;

          h2 {
            font-size: 13px;
          }
        }

        &.borderBox {
          border: 2px solid #000;
          padding: 10px;
          font-size: 14px;
        }

        h2 {
          font-size: 14px;
        }

        h3 {
          font-size: 14px;
        }

        .footer {
          font-size: 12px;
          color: $black-400;
        }
      }

      th {
        font-family: "VerdanaWF", Verdana, sans-serif;
        font-weight: normal;
      }
    }
  }
}

.tox {
  img.toolicon {
    width: 24px !important;
    height: 24px !important;
  }

  code.keyNote {
    background-color: $primary_Light !important;
  }
}

table.structure-table {
  border: 1px solid $primaryInputBorder;

  th,
  td {
    border: 2px solid $primaryInputBorder;
    padding: 8px 5px;

    &.day {
      background-color: $primary_Dark;
      color: #fff;
    }

    &.task {
      background-color: $primary_Dark;
      color: #fff;
    }

    &.text {
      text-align: left;
    }

    &.date {
      text-align: center;
    }
  }
}

.autoSaveNoteNotify {
  content: "";
  padding: 3px 6px;
  z-index: -1;
  border-radius: 4rem;
  //font-family: 'VerdanaBold', Verdana, sans-serif;
  //font-weight: 600;
  //background-color: var(--primary);
  //border-color: var(--primary);
  color: var(--primary);
  animation: autoSaveNoteBlink 2s 4 ease-in;
}

@keyframes autoSaveNoteBlink {
  0% {
    opacity: 0;
    content: "";
  }

  50% {
    opacity: 1;
    content: "1";
  }

  100% {
    opacity: 0.1;
    content: "1";
  }
}

// .ant-list-item {
//   transition: all 0.3s ease-in-out;
//   -o-transition: all 0.3s ease-in-out;
//   -webkit-transition: all 0.3s ease-in-out;
//   -moz-transition: all 0.3s ease-in-out;
//   cursor: pointer;
// }
// .ant-list-item:nth-child(even) {
//   background-color: $primary_Light;
// }
// .ant-list-item:nth-child(even):hover {
//   background-color: $primary_Dark;
// }

.keyNotesList {
  margin: 0;
  padding: 0;
  border: 1px solid $primaryInputBorder;

  &.ant-list-split {
    .ant-list-items {
      margin: 0;
      padding: 0;

      .ant-list-item {
        margin: 0;
        padding: 8px 0;

        p {
          padding: 0;
          margin: 0;
          color: $primaryTextDark;
        }

        &:nth-child(even) {
          background-color: $primarySeleteBG;
        }

        &:hover {
          background-color: $primary_Light;
        }
      }
    }
  }
}

.content {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;

  .qStart,
  .qEnd {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    width: 13px;
    height: 10px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      margin: 0;
      width: 13px;
      height: auto;
    }
  }

  .qEnd {
    transform: rotate(180deg);
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
}

.topSelection-maxwidth {
  max-width: 200px;
}

.fields_draggable_wrapper {
  position: relative;
  z-index: 5;

  .actionIcons {
    // flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;

    img.icon.circle.cursorPointer {
      margin: 0 0 8px !important;
      box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.15);
      width: 2rem;
      height: 2rem;

      &:last-child {
        margin: 0 !important;
      }
    }
  }

  .ant-row.ant-form-item {
    margin: 0 0 10px;

    .number {
      width: 45px;
      height: 82px;
      border-radius: 0;
      border: 0 !important;
      font-size: 1.6rem;
      display: flex !important;
      justify-content: center;
      align-items: center;
      background-color: $primary_Light;
      color: $primaryTextDark;
      text-align: center;
      margin: 0 5px 0 0;
      box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.15);

      .ant-input-number-handler-wrap {
        display: none;
      }

      .ant-input-number-input {
        text-align: center;
      }
    }

    .detail {
      height: 82px;
      border-radius: 0;
      border: 0 !important;
      font-size: 1.2rem;
      background-color: $primarySeleteBG;
      color: $primaryTextDark;
      box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.15);
    }
  }
}

.fields_draggable_wrapper {
  &.extraZindex {
    z-index: 1010;
  }
}

.right-side-ticketDetails {
  ul {
    margin: 0;
    padding: 3px 0;
    list-style: none;
    width: 100%;
    font-size: 1.2rem;

    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 1px 0 2px;
      color: $primaryTextDark;
      height: 19px;
      width: 100%;

      &.ticket-description {
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
        max-height: 80px;
        overflow: hidden;
        text-overflow: unset;
        white-space: unset;
      }

      span.title {
        display: inline-block;
        width: 105px;
        color: $primaryTextLight;
        font-style: normal;
        margin-right: 4px;

        &::after {
          content: ":";
          float: right;
        }
      }

      span,
      strong {
        cursor: pointer;
      }
    }
  }
}

.StructureContentTable {
  border: none;
  border-spacing: 0 !important;
  padding: 0;
  margin: 0;

  tr,
  td {
    padding: 0;
    margin: 0;

    .ant-row.ant-form-item {
      margin: 0 !important;
    }
  }

  td.dragger {
    border-right: 5px solid #fff;
  }

  td.number {
    margin-right: 5px;
    background-color: $primary_Light;
    border-right: 5px solid #fff;
    width: 50px;

    .ant-input-number {
      width: 50px;
      background-color: transparent;
      border: none;
      text-align: center;

      .ant-input-number-handler-wrap {
        display: none;
      }

      input {
        text-align: center;
        font-size: 1.2rem;
        padding: 5px 2px !important;
      }

      &:hover,
      &:focus,
      &:focus-within {
        outline: none !important;
        box-shadow: none !important;

        input {
          outline: none;
        }
      }
    }
  }

  td.textAreaSection {
    background-color: $primarySeleteBG;
    width: calc(100% - 50px);
    height: 1.8rem;
    padding: 3px 0;

    .ant-row.ant-form-item {
      margin: 0 !important;
    }

    .ant-form-item-control-input {
      min-height: 1.8rem;
    }

    textarea.ant-input.detail {
      min-height: unset;
      height: 1.8rem;
      margin: 0;
      background-color: transparent;
      border: none;
      padding: 0 5px !important;
      line-height: 1.8rem;
      font-size: 1.4rem;

      &:focus,
      &:focus-within {
        outline: none;
        box-shadow: none;
      }
    }
  }

  td.actionIcons {
    background-color: #fff;
    padding: 5px 0 5px 5px;
    display: table-cell !important;
    // align-items: center;
    // justify-content: flex-end;

    width: 50px;
    // flex: 0 0 50px;
    height: 2.8rem;
    // flex-direction: column;
    align-items: unset;
    justify-content: unset;

    img.icon.circle.cursorPointer {
      margin: 0 !important;
      padding: 0 !important;
      box-shadow: none;
      width: 18px;
      height: 18px;
      display: inline-block;

      &:last-child {
        margin-left: 5px !important;
      }
    }
  }
}

.fields_draggable_container {
  .StructureContentTable {
    tr {
      border-top: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }
  }
}

.fields_draggable_container {
  .box-RightScheduleList {
    // tr {
    border-top: 4px solid #fff;
    margin: 0;
    // }
  }
}

.fields_draggable_wrapper {
  &.blink-BGanimation {
    .StructureContentTable {
      .textAreaSection,
      .number,
      .detail {
        background-color: transparent !important;
      }

      .dragger {
        background-color: #fff;
      }
    }
  }
}

.fields_draggable_wrapper {
  &.blink-BGanimation {
    .box-RightScheduleList {
      background-color: transparent !important;

      .data,
      .dayTD,
      .num,
      .text,
      .numTD,
      .days,
      .date {
        background-color: transparent !important;

        .days {
          background-color: transparent !important;
        }
      }
    }
  }
}

.blink-BGanimation {
  background-color: #22b04c;
  animation: blinkredblack 1s 1;
}

@keyframes blinkredblack {
  0% {
    background-color: #22b04c;
  }

  50% {
    background-color: #63fa90;
  }

  100% {
    background-color: #22b04c;
  }
}

.Dealdesk_MainTab {
  .ant-tabs-nav {
    .ant-tabs-nav-list {
      .ant-tabs-tab:nth-child(4) {
        border-right: 3px solid #000;
        padding-left: 2px;
        padding-right: 2px;
      }

      .ant-tabs-tab:nth-child(4) {
        border-left: 0 !important;
      }
    }
  }

  .Dealdesk_SubTab {
    .ant-tabs-nav {
      .ant-tabs-nav-list {
        .ant-tabs-tab:nth-child(4) {
          border-right: 1px solid $primary_Light;
        }

        .ant-tabs-tab:nth-child(4) {
          border-left: 1px solid $primary_Light !important;
        }
      }
    }
  }
}

.DealLibrary-ScheduleFormFooter {
  justify-content: space-between;
  padding: 0 10px;
  display: flex;
  font-size: 1.2rem;

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    padding: 0;

    .ant-checkbox {
      top: 0;
    }

    span {
      font-size: 1.2rem;
    }
  }
}

.float-button-addNode {
  z-index: 999;
  padding: 0 !important;
  position: absolute;
  bottom: -38px;
  left: 50%;
  width: 26px !important;
  height: 26px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);

  img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }

  &.libraryBtn {
    bottom: 14px;
  }
}

form.innerForm .ant-btn {
  &.libraryBtn {
    padding: 0 !important;
  }
}
