.ant-radio-group {
  &.FormulaComponent {
    label.ant-radio-button-wrapper {
      font-size: 22px;
    }
    label.ant-radio-button-wrapper:first-child,
    label.ant-radio-button-wrapper:last-child {
      border-radius: 0 !important;
    }
  }
  &.MainOption {
    label.ant-radio-button-wrapper:first-child,
    label.ant-radio-button-wrapper:last-child {
      border-radius: 0 !important;
    }
  }
  &.ConditionalOption {
    label.ant-radio-button-wrapper:first-child,
    label.ant-radio-button-wrapper:last-child {
      border-radius: 0 !important;
    }
  }
}

.formulize-field-wrapper {
  overflow-y: auto;
  //height: 300px;
  max-height: 30vh;
  .formulaList {
    display: block;
    .formulize-custom {
      color: $primary_Dark !important;
      border: 1px solid $primary_Dark;
      background-color: #fff;
      font-size: 12px;
      margin: 0 0 5px;
      border-radius: 30px;
      padding: 0 10px !important;
      opacity: 0.7;
      line-height: 18px;
      -webkit-text-emphasis: overflow;
      text-emphasis: overflow;
      display: block !important;
    }
  }
}

.ant-tabs.tabs.DealLibrary {
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
}
