.rowHeader {
  border: 1px solid $primary_Dark !important;
  .ant-row {
    padding: 0 !important;
    margin: 0 !important;
    .ant-col {
      padding: 0.8rem !important;
      margin: 0 !important;
      .ant-col {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
  .ant-checkbox-wrapper {
    padding: 0.5rem 0 0;
    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $primary_Dark;
          border-color: $primary_Dark;
          width: 1.8rem;
          height: 1.8rem;
          &:after {
            border: 1px solid $primaryTextDark;
            border-top: 0;
            border-left: 0;
          }
        }
        &:after {
          border-color: $primaryTextDark;
        }
      }
      &:hover,
      &:focus {
        .ant-checkbox-inner {
          border-color: $primaryTextDark;
        }
      }
    }
  }
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primaryTextDark;
}
.border-top {
  border-top: 1px solid $primary_Dark;
}
.border-right {
  border-right: 1px solid $primary_Dark;
}
.border-bottom {
  border-bottom: 1px solid $primary_Dark;
}
.border-left {
  border-left: 1px solid $primary_Dark;
}
.permissionPopup {
  .ant-modal-body {
    max-height: 78vh;
    overflow-y: scroll;
  }
}
.evenRow:nth-child(even) {
  background-color: $Offwhite;
}
.border-right.subOptions .ant-checkbox-wrapper {
  padding-left: 15px !important;
}
