.e-kanban-header > div {
  width: 100% !important;
  padding: 0 !important;
}
.e-card-header-title.e-tooltip-text .icon {
  display: block;
  position: absolute;
  right: 10px;
  top: 5px;
}
.e-kanban .e-kanban-table .e-header-cells {
  background-color: #d1d1d1;
  border-radius: 0;
  padding: 0px 5px 0px 30px;
  font-size: 14px;
  text-align: center;
}
.e-kanban .e-kanban-table .e-header-cells:before {
  content: '';
  display: block;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  position: absolute;
  top: 1px;
  left: -13px;
}
.e-kanban .e-kanban-table .e-header-cells:first-child:before {
  display: none;
}
.e-kanban .e-kanban-table .e-header-cells:first-child {
  border-radius: 40px 0 0 40px;
}
.e-kanban .e-kanban-table .e-header-cells:last-child {
  border-radius: 0 40px 40px 0;
}

.kanban-overview.e-kanban .header-template-wrap {
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  color: #454545;
}

.kanban-overview.e-kanban .header-template-wrap .header-icon {
  font-family: 'Kanban priority icons';
  margin-top: 3px;
  width: 10%;
  display: none;
}

.kanban-overview.e-kanban .header-template-wrap .header-text {
  margin-left: 0;
}

.kanban-overview.e-kanban.e-rtl .header-template-wrap .header-text {
  margin-right: 15px;
}

.kanban-overview.e-kanban .Open::before {
  content: '\e700';
  color: #0251cc;
  font-size: 16px;
}

.kanban-overview.e-kanban .InProgress::before {
  content: '\e703';
  color: #c00;
  font-size: 16px;
}

.kanban-overview.e-kanban .e-image img {
  background: #ececec;
  border: 1px solid #c8c8c8;
  border-radius: 50%;
}

.kanban-overview.e-kanban .Review::before {
  content: '\e701';
  color: #8e4399;
  font-size: 16px;
}

.kanban-overview.e-kanban .Close::before {
  content: '\e702';
  color: #63ba3c;
  font-size: 16px;
}

.kanban-overview.e-kanban .e-card .e-card-tag-field {
  background: #ececec;
  color: #6b6b6b;
  margin-right: 5px;
  line-height: 1.1;
  font-size: 13px;
  border-radius: 3px;
  padding: 4px;
}

.kanban-overview.e-kanban .e-card-custom-footer {
  display: flex;
  padding: 0px 12px 12px;
  line-height: 1;
  height: 35px;
}

.e-kanban .e-kanban-table {
  border-spacing: 0;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells {
  border-radius: 0;
  border-left: 1px solid #d3d3d3;
}
.e-kanban
  .e-kanban-content
  .e-content-row:not(.e-swimlane-row)
  .e-content-cells:first-child {
  border: none;
}
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-wrapper,
.e-kanban
  .e-kanban-content
  .e-content-row
  .e-content-cells
  .e-card-container {
  padding: 3px;
}
.e-kanban
  .e-kanban-header
  .e-header-cells
  .e-header-wrap
  .e-header-title
  div {
  padding: 0;
  margin: 0;
  height: auto;
  position: relative;
  width: 100%;
  text-align: center;
}
.e-kanban .e-kanban-header .e-header-cells {
  height: 30px;
}
.e-kanban .e-kanban-header .e-header-cells .e-header-wrap .e-header-title {
  align-items: center;
}
.e-kanban .e-kanban-content {
  background-color: #fff;
  border: 1px solid #e3e3e3;
}
.e-kanban
  .e-kanban-table.e-content-table
  .e-content-row:not(.e-swimlane-row)
  td {
  background-color: transparent;
  height: calc(100vh - 100px);
  overflow-y: auto;
}
.e-card {
  border: 1px solid #e3e3e3;
}
