.reminderModal {
  .ant-modal-footer {
    .rightAction {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 5px;
      img {
        width: 20px;
        height: 20px;
        margin: 0;
        cursor: pointer;
      }
      span {
        display: inline-block;
        position: relative;
        .ant-picker {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          width: 100%;
          height: 100%;
          cursor: pointer;
          input {
            cursor: pointer;
          }
        }
      }
    }
  }
}
