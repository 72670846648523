.TickerTape-Checkbox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .ant-checkbox-wrapper {
    width: auto;
    margin-right: 2rem;
    span {
      font-size: 1.2rem;
      font-weight: 400;
      color: $primaryTextLight;
    }
  }
}
.border-box {
  border: 1px solid $primary_Light;
  padding: 1rem 0.5rem 1.5rem;
  margin-bottom: -1px;
}

.innerForm.tickerTab {
  font-size: 1.2rem !important;
  table {
    td {
      vertical-align: middle;
      min-height: 2rem;
      padding-bottom: 1rem;
      label.ant-form-item-required.title {
        font-size: 1.1rem;
        margin: 0 0;
        display: block;
        &::after {
          display: inline-block;
          margin-left: 3px;
          color: #ff1010;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 0.5;
          content: '*';
          position: relative;
          top: 2px;
        }
      }
      label.ant-checkbox-wrapper {
        font-size: 1.2rem !important;
        padding: 0;
        margin: 0;
      }
      .ant-form-item {
        margin: 0;
        .ant-select {
          width: calc(100% - 4rem);
          .ant-select-selector {
            overflow: hidden;
            padding: 0 !important;
            font-size: 1.2rem !important;
            height: 3rem !important;
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }
}
