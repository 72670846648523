.noteRender {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin: 0;

  .menuicon {
    display: inline-block;
    margin: -3px 8px 0;
    padding: 0;
    width: 17px;
    height: 17px;
    cursor: pointer;
  }
}

.promotionAction {
  .menuicon {
    display: inline-block;
    margin: 0 8px;
    padding: 0;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .count {
    display: inline-block;
    font-size: 13px;
    font-family: 'VerdanaBold', Verdana, sans-serif;
    font-weight: 600;
    letter-spacing: 0rem;
  }

  .Calendar {
    position: relative;
    cursor: pointer;

    .ant-picker {
      position: absolute;
      z-index: 5;
      opacity: 0;
      padding: 0;
      margin: -0.3rem;
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
      overflow: hidden;
    }
  }
}

.QuicknoteList {
  padding: 0 0;
  background-color: var(--seleteBG);
  border-radius: 5px;
  overflow: hidden;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 60px;
    max-height: 150px;
    border-radius: 5px;
    overflow-y: auto;

    li {
      padding: 4px 8px;
      font-size: 1.2rem;
      border-bottom: 1px solid $primary_Light;
      text-align: center;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: $primaryTextDark;
        color: $white;
      }
    }
  }
}

.NoteText {
  padding: 8px;
  background-color: $primarySeleteBG;
  border-radius: 5px;
  overflow: hidden;
  width: 380px;

  .ant-form-item {
    margin: 0;
  }

  .ant-btn {
    padding: 10px 20px !important;
  }
}

.tooltipTitle {
  padding: 4px;
  background-color: $primarySeleteBG;
  border-radius: 5px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.6),
    0 6px 10px 4px rgba(0, 0, 0, 0.1);

  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: $primarySeleteBG !important;
      color: $primaryTextDark;
      box-shadow: none;
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      font-size: 12px;
    }
  }
}

.StatusCell {
  padding: 0 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .ag-react-container {
    padding: 0 !important;
  }

  .StatusRenderer {
    .ant-select {
      max-width: 110px !important;
      height: 30px;

      .ant-select-selector {
        border: none;
        padding: 0;
        font-size: 1.2rem;
        background-color: transparent;
        width: 100%;
        height: 30px;

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          padding-right: 0px !important;
        }

        .ant-select-selection-search {
          right: 0 !important;
        }
      }
    }
  }
}

.headerIcon {
  width: 17px;
  height: 17px;
  cursor: pointer;
  margin: 0 3px;
}

.TypeRenderer {
  .headerIcon {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-top: -4px;
  }

  .idText {
    display: inline-block;
    min-width: 18px;
  }
}

.chartBox {
  width: 100%;
  height: 240px;
  margin: 0 0 10px;
  padding: 30px 10px 00px;
  box-sizing: border-box;
  position: relative;
  background-color: $white;
  border: 1px solid $primary_Light;

  .chartTitle {
    position: absolute;
    z-index: 999;
    top: 0px;
    left: 20px;
    width: 100%;

    font-size: 15px;
    padding: 5px 5px;

    strong {
      display: inline-block;
      font-size: 12px;
      margin-right: 10px;
    }

    span {
      font-size: 12px;
      opacity: 0.8;
    }
  }

  canvas {
    height: 200px !important;
  }
}

#TopSelection,
#blockEditAssignTopfilter {
  background-color: $white;
  border: 1px solid $primary_Light;
  padding: 5px;

  .ant-form-item {
    margin: 0;
  }
}

.spinCenter {
  margin: 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

.action-column {
  &.TelephoneRenderer {
    .menuicon {
      width: 16px;
      height: 16px;
      margin: auto 5px auto 10px;
    }

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10px;
      margin: -1px 0 0;
      color: $primaryTextLight;

      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }

      &:hover,
      &:focus,
      &:active {
        color: $primaryTextDark;
      }
    }
  }

  &.promotionAction {
    display: flex;
    //justify-content: center;
    align-items: center;
    padding: 5px;

    img {
      width: 16px;
      height: 16px;
    }
  }
  &.productivityAction {
    padding: 0;
    margin: 0 0 0;
    .ant-checkbox-wrapper {
      margin: 0;
      .ant-checkbox.ant-checkbox-disabled {
        padding: 0 !important;
        border: 1px solid rgba(0, 0, 0, 0.2) !important;
        border-radius: 2px;
        .ant-checkbox-inner {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

// .TelephoneRenderer {
//   a {
//     color: $primaryTextLight;
//     .menuicon {
//       width: 18px;
//       height: 18px;
//       margin-right: 5px;
//     }
//     &:hover,
//     &:focus,
//     &:active {
//       color: $primaryTextDark;
//     }
//   }
// }

.headerAction {
  margin: -5px 0 0;
  padding: 0;
  display: flex;
  align-items: center;

  label.ant-checkbox-wrapper.headerCheckbox {
    margin: 0 0 0 0;
    top: -1px;
    position: relative;

    .ant-checkbox-inner {
      border: 2px solid $primary_Dark;
      width: 16px;
      height: 16px;
    }
  }

  img {
    margin: 0 0 0 10px;
    width: 17px;
    height: 17px;
  }
}

.ag-theme-alpine {
  .ag-selection-checkbox {
    margin: 0 !important;
  }
}

.topSelection {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-row.ant-form-item {
    width: 13% !important;
    margin: 2px !important;
  }

  .dateRangeSelection {
    width: 20% !important;
    margin: 2px !important;

    .ant-row.ant-form-item {
      width: 100% !important;
      margin: 0 !important;

      .ant-picker.ant-picker-range {
        .ant-picker-input {
          padding: 0 !important;
        }

        .ant-picker-range-separator {
          margin: 0;
          padding: 0 !important;
        }

        .ant-picker-suffix {
          margin-right: 10px;
        }

        .ant-picker-active-bar {
          display: none;
        }
      }
    }
  }

  form#applyfilter.innerForm {
    width: 13% !important;
    margin: 2px !important;

    .ant-row.ant-form-item {
      width: 100% !important;
      margin: 0px !important;
    }
  }

  .ant-picker,
  .ant-select {
    height: 35px !important;
  }

  .smallBtn {
    width: 50px;
    margin: 0 2px;

    button.ant-btn.ant-btn-round {
      width: 100%;
      padding: 0 !important;
      margin: 0 !important;
      height: 35px !important;
    }

    &.height-35 {
      button.ant-btn.ant-btn-round {
        height: 35px !important;
      }
    }
  }

  .RecordPerPage {
    min-width: 100px !important;
  }
}

.KanbanView {
  .topSelection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .ant-form-item {
      flex: 1;
      margin: 0 10px;
    }

    &.soldStatus {
      justify-content: flex-end;

      .ant-form-item {
        flex: unset;
      }
    }
  }
}

.ag-theme-alpine
  .ag-cell.actionColumn
  .action-column.promotionAction
  button.ant-btn.ant-btn-text {
  padding: 0 !important;
}

form#applyfilter {
  &.fixDropdown {
    .ant-select {
      width: auto !important;
      min-width: 250px !important;
    }
  }
}

.productivityListing {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100px;

    li {
      width: 100%;
      border-bottom: 1px solid var(--lite);
      background-color: var(--seleteBG);
      color: var(--textDark);
      font-size: 1rem;
      font-weight: 400;
      font-family: 'VerdanaWF', Verdana, sans-serif;
      padding: 3px 6px;
      cursor: pointer;

      &:hover,
      &:focus,
      &.active {
        background-color: var(--dark);
        color: #ffffff;
      }
    }
  }
}

.ant-btn {
  &.collapseIcon-management {
    display: flex;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;

    .anticon {
      margin-top: 2px;

      svg {
        width: 12px;
        height: 10px;
      }
    }
  }
}
